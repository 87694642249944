import {
  REDEEM_USER_ITEM_SUCCEEDED,
  REDEEM_USER_ITEM_FAILED,
} from '../../actions/experiences/userItem'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case REDEEM_USER_ITEM_SUCCEEDED:
      // Returns a 204. redemptionSuccess is storing the a faux redeemedAt
      // so we don't have to make a fetch order call after this.
      return {
        ...state,
        redemptionSuccess: { [action.id]: Date.now() },
      }

    case REDEEM_USER_ITEM_FAILED:
      return {
        ...state,
        redemptionFailure: { [action.id]: action.payload }
      }

    default:
      return state
  }
}

export default reducer
