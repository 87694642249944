import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import Events from '../components/Events'
import { withTranslation } from 'react-i18next'

import { setEventFilter } from '../actions/filter'

import { makeGetExperienceEvents } from '../selectors/event'
import { makeGetItem } from '../selectors/item'

import eventsMapper from '../mappers/events'

export class ExperienceEvents extends Component {
  render() {

    var pBackButtonText = this.props.backButtonText ? this.props.backButtonText : this.props.t('BACK_LOWERCASE')

    return <Events { ...this.props } backButtonText={pBackButtonText} />
  }
}

function mapStateToProps(state, ownProps) {
  const standId = get(ownProps, 'match.params.standId', '')
  const menuId = get(ownProps, 'match.params.menuId', '')
  const itemId = get(ownProps, 'match.params.itemId', '')
  const getItem = makeGetItem()
  const item = getItem(state, itemId)

  const backButtonText = get(item, 'name')
  const backButtonRoute = `/${standId}/experiences/${menuId}/item/${itemId}`
  const selectedEvent = get(state, 'filter.eventFilter', undefined)

  const getExperienceEvents = makeGetExperienceEvents()
  const events = getExperienceEvents(state, itemId)

  const serializedEvents = eventsMapper(events)

  return {
    backButtonText,
    backButtonRoute,
    events: serializedEvents,
    selectedEvent,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const standId = get(ownProps, 'match.params.standId', '')
  const menuId = get(ownProps, 'match.params.menuId', '')
  const itemId = get(ownProps, 'match.params.itemId', '')

  return {
    didSelectEvent: (selectedEvent) => {
      dispatch(setEventFilter(selectedEvent))
      ownProps.history.replace(`/${standId}/experiences/${menuId}/item/${itemId}`)
    },
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExperienceEvents))
