import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'

import ModifierGroupValidator from '../validators/ModifierGroupValidator'
import { withTranslation } from 'react-i18next'

import './ModifierGroup.scss'

export class ModifierGroup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedItems: new Set(props.defaults),
      showHint: false,
    }

    this.validator = new ModifierGroupValidator(props.min, props.max)
  }

  componentDidMount() {
    const { min, max } = this.props
    this.validator = new ModifierGroupValidator(min, max)
  }

  componentDidUpdate(prevProps) {
    const { defaults, min, max } = this.props

    if (prevProps.defaults !== defaults) { this.setState({ selectedItems: new Set(defaults) }) }

    if (prevProps.min === min && prevProps.max === max) return
    this.validator = new ModifierGroupValidator(min, max)
  }

  isValid = () => {
    const isValid = this.validator.isValid(this.state.selectedItems.size)

    this.setState({ showHint: isValid === false })

    return isValid
  }

  values = () => {
    const { selectedItems } = this.state

    const mapped = [...selectedItems].map((item) => ({
      menuItemUuid: item,
      quantity: 1,
    }))

    return mapped
  }

  onChange = (event) => {
    const { max } = this.props
    const { selectedItems } = this.state
    const value = event.target.value
    const checked = selectedItems.has(value)

    const isRequired = this.validator.validationType === ModifierGroupValidator.types.required
    const hasMax = max !== null

    this.setState(({ selectedItems }) => {
      if (!isRequired && !checked && hasMax && max < selectedItems.size + 1) return

      const action = !checked || isRequired ? 'add' : 'delete'
      const set = isRequired ? new Set() : new Set(selectedItems)

      set[action](value)

      return { selectedItems: set, showHint: false }
    })
  }

  secondaryText = () => {
    const { min, max, t } = this.props
    const labels = {
      required: `(${t('REQUIRED_LOWERCASE')})`,
      specificRequired: `(${min} ${t('REQUIRED_LOWERCASE')})`,
      minRequired: `${t('PICK_AT_LEAST')} ${min} (${t('REQUIRED_LOWERCASE')})`,
      minMaxRequired: `${t('PICK_UP_TO')} ${max} (${min} ${t('REQUIRED_LOWERCASE')})`,
      maxOptional: `${t('PICK_UP_TO')} ${max} (${t('OPTIONAL')})`,
      optional: '',
      default: '',
    }

    const text = labels[this.validator.validationType] || labels.default

    return isEmpty(text) ? null : text
  }

  render() {
    const { selectedItems, showHint } = this.state
    const { name, items, loading } = this.props

    if (!loading && isEmpty(items)) return null

    let secondaryTextComponent = null
    const secondaryText = this.secondaryText()

    if (secondaryText !== null) {
      secondaryTextComponent = (
        <span className={classNames('secondary-text', { invalid: showHint })}>
          {secondaryText}
        </span>
      )
    }

    return (
      <div className={classNames('modifier-group', { loading })}>
        {loading && <h3><Skeleton width='60%' /></h3>}
        {!loading && <h3>{name}{secondaryTextComponent}</h3>}
        <div>
          {loading && <div className='loading-group'>
            <Skeleton width='80px' height='35px' />
            <Skeleton width='80px' height='35px' />
            <Skeleton width='80px' height='35px' />
          </div>}
          {!loading && items.map((item) => {
            const isChecked = selectedItems.has(item.id)
            let labelText = item.name

            if (item.price) labelText += ` +${item.price}`

            return (
              <div className="modifier-property" key={item.id}>
                <input type="checkbox" name={name} value={item.id} onChange={this.onChange} checked={isChecked} />
                <label>{labelText}</label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

ModifierGroup.propTypes = {
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
  })),
  min: PropTypes.number,
  max: PropTypes.number,
  loading: PropTypes.bool,
  defaults: PropTypes.arrayOf(PropTypes.string),
}

ModifierGroup.defaultProps = {
  name: '',
  items: [],
  min: null,
  max: null,
  loading: false,
  defaults: [],
}

export default withTranslation('', { withRef: true })(ModifierGroup)
