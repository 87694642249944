import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { get, keys, values, head } from 'lodash'
import Skeleton from 'react-loading-skeleton'

import DataMapper from '../utils/dataMapper'
import Title from '../utils/titleGenerator'
import Remote from '../remote'
import { loadStands } from '../actions/stand'
import { clearCart } from '../actions/cart'
import { updateUserLocationConfirmed, updateUserAgeVerification } from '../actions/user'
import { setProductTypeFilter } from  '../actions/filter'

import { getStands, makeGetStandMenus } from '../selectors/stand'
import { getAvailableProductTypes } from '../selectors/filter'
import { getVenue } from '../selectors/venue'
import { clearLatestOrder, clearOrderError } from '../actions/order'
import { makeGetRemote } from '../selectors/remote'

import MenuCard from '../components/MenuCard'
import PrivacyPolicy from '../components/PrivacyPolicy'
import SegmentedControl from '../components/SegmentedControl'
import './Stands.scss'

import { withTranslation } from 'react-i18next'

export class Stands extends Component {
  componentDidMount() {
    const { loadStands, clearCart, clearLatestOrder, clearOrderError, updateUserLocationConfirmed, updateUserAgeVerification } = this.props
    loadStands()
    clearCart()
    clearLatestOrder()
    clearOrderError()
    updateUserLocationConfirmed()
    updateUserAgeVerification()
  }

  renderStands = () => {
    const { stands } = this.props

    return keys(stands).map((id) => {
      const stand = stands[id]
      const productType = get(stand, 'productType', undefined)
      const productTypePath = productType === 'Experience' ? 'experiences' : 'menu'
      const serviceTypes = get(stand, 'serviceTypes', [])

      if (serviceTypes.length === 1 && serviceTypes[0].name === "QuickPay") return null

      const pickupMenuId = keys(stand.menus).find((menuId) => stand.menus[menuId].usageType === 'pickup')
      const deliveryMenuId = keys(stand.menus).find((menuId) => stand.menus[menuId].usageType === 'delivery')
      const pickupMenu = stand.menus[pickupMenuId]
      const deliveryMenu = stand.menus[deliveryMenuId]
      const isAvailable = get(pickupMenu, 'isAvailable') || get(deliveryMenu, 'isAvailable')
      let targetMenu = pickupMenuId !== undefined ? pickupMenu : deliveryMenu

      if (productType === 'Experience') targetMenu = head(values(stand.menus))

      if (!targetMenu) return null

      return (
        <Link to={`/${stand.uuid}/${productTypePath}/${targetMenu.uuid}`} key={targetMenu.uuid}>
          <MenuCard
            pickupShortDescription={get(pickupMenu, 'shortDescription')}
            deliveryShortDescription={get(deliveryMenu, 'shortDescription')}
            name={get(stand, 'name')}
            image={get(stand, 'images.detail', get(targetMenu, 'images.detail'))}
            key={id}
            available={isAvailable}
          />
        </Link>
      )
    })
  }

  segmentDidChange = (segment) => {
    const { setProductTypeFilter, productTypeFilter } = this.props
    if (productTypeFilter === segment.id) {
      setProductTypeFilter(undefined)
      return
    }

    setProductTypeFilter(segment.id)
  }

  render() {
    const { stadiumName, productTypeFilter, availableProductTypes, loading, t } = this.props

    let segmentedControl = <Skeleton width="100%" height="35px" />

    if (!loading) {
      segmentedControl = (
        <SegmentedControl
          segments={availableProductTypes}
          selected={productTypeFilter}
          onChange={this.segmentDidChange}
        />
      )
    }

    return (
      <div className="stands">
        <Helmet>
          <title>{Title.generate(t('MOBILE_ORDERING_MENUS'))}</title>
        </Helmet>
        <div className="header">
          <h1>{loading ? <Skeleton width='70%' /> : stadiumName}</h1>
          {segmentedControl}
        </div>
        <div className="menus-list">
          {loading && <MenuCard loading />}
          {!loading && this.renderStands()}
        </div>
        <PrivacyPolicy />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const getRemote = makeGetRemote()
  const remote = getRemote(state, Remote.endpoints.getStands)
  const productTypeFilter = get(state, 'filter.productTypeFilter', undefined)

  const stands = getStands(state)
  const availableProductTypes = getAvailableProductTypes(state, ownProps.t)
  const venue = getVenue(state)

  const standsAndMenus = keys(stands).reduce((accumulator, id) => {
    const getStandMenus = makeGetStandMenus()
    const menus = getStandMenus(state, id)

    return {
      ...accumulator,
      [id]: {
        ...stands[id],
        menus: DataMapper.objectsById(menus),
      }
    }
  }, {})

  let stadiumName = ""
  if (stands && venue) stadiumName = venue.displayName

  return {
    stadiumName: stadiumName,
    stands: standsAndMenus,
    loading: remote.loading,
    productTypeFilter,
    availableProductTypes,
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    loadStands: () => dispatch(loadStands()),
    clearCart: () => dispatch(clearCart()),
    clearLatestOrder: () => dispatch(clearLatestOrder()),
    clearOrderError: () => dispatch(clearOrderError()),
    updateUserLocationConfirmed: () => dispatch(updateUserLocationConfirmed(false)),
    updateUserAgeVerification: () => dispatch(updateUserAgeVerification(false)),
    setProductTypeFilter: (serviceType) => dispatch(setProductTypeFilter(serviceType)),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Stands))
