import { createSelector } from 'reselect'
import { getMenus } from './menu'
import { filter, get, includes, keys, partition, some } from 'lodash'
import DataMapper from '../utils/dataMapper'

import { HIDE_MERCH_STANDS } from '../constants.js'

// this list is used for display and navigation logic. Example: quickpay menus
// aren't included, b/c they shouldn't be seen on the RvC list page,
// nor listed as a service type option when toggling b/w menus for an RvC.
const SERVICE_TYPES = ['Delivery', 'Concessions', 'Experience']

const standSorter = (a, b) => {
  if (a.venueSortOrder > b.venueSortOrder) return 1
  if (a.venueSortOrder < b.venueSortOrder) return -1
  return 0
}

export const getStands = (state) => {
  let rvcs = get(state, 'stand.rvcs', [])
  const menus = get(state, 'menu.rvcMenus', [])

  rvcs = filter(rvcs, (rvc) => {
    if (HIDE_MERCH_STANDS && rvc.productType === "Merchandise") return false

    const rvcMenus = filter(menus, { standUuid: rvc.uuid })

    return some(rvcMenus, (menu) => includes(SERVICE_TYPES, menu.serviceType))
  }).sort(standSorter)

  const [experienceRvcs, foodAndMerchRvcs] = partition(rvcs, { productType: 'Experience' })
  let result = [ ...foodAndMerchRvcs, ...experienceRvcs ]

  const filtered = get(state, 'filter.productTypeFilter', undefined)
  if (filtered === undefined) return DataMapper.objectsById(result)

  const matchesFilter = (stand) => stand.productType === filtered
  const filteredResult = filter(result, matchesFilter)

  return DataMapper.objectsById(filteredResult)
}

const getStand = (state, id) => {
  const rvcs = get(state, 'stand.rvcs', [])
  return rvcs.find((rvc) => rvc.uuid === id) || {}
}

export const makeGetStand = () => (
  createSelector([getStand], (stand) => stand)
)

const getStandMenus = createSelector(
  [getStand, getMenus],
  (stand, menus) => {
    return filter(menus, (menu) => {
      return menu.standUuid === get(stand, 'uuid') && includes(SERVICE_TYPES, menu.serviceType)
    })
  }
)

export const makeGetStandMenus = () => {
  return createSelector([getStandMenus], (standMenus) => standMenus)
}

const getStandServices = createSelector(
  [getStandMenus],
  (standMenus) => {
    const services = keys(standMenus).map((menuId) => get(standMenus[menuId], 'usageType'))
    const filteredServices = services.filter((service) => !!service).sort().reverse()

    return filteredServices
  }
)

export const makeGetStandServices = () => {
  return createSelector([getStandServices], (standServices) => standServices)
}
