var GBData = {
  translation: {
    CURRENCY: "GBP",
    CURRENCY_SYMBOL: "£",
    CUSTOM_TIP_AMOUNT: "Please enter a custom tip amount.",
    OK: "OK",
    AMOUNT: "Amount",
    creditCard: {
      number: {
        displayText: "Card Number",
      },
      expirationDate: {
        displayText: "Expiry Date",
      },
      cvv: {
        displayText: "Security Number",
      },
      postalCode: {
        displayText: "Postal Code",
      },
    },
    LOG_IN: 'Log in',
    REPORT_AN_ISSUE: 'Report an Issue',
    TERMS_OF_SERVICE: 'Terms of Service',
    PRIVACY_POLICY: 'Privacy Policy',
    HOME: 'Home',
    MY_ACCOUNT: 'My Account',
    ORDERS: 'Orders',
    SIGN_OUT: 'Sign Out',
    PASSES: 'Passes',
    PAYMENTS: 'Payments',  
    MULTIPLIER: 'multiplier',
    LOADING_PASS: 'Loading pass',
    ORDER_NEXT: 'Order Next',
    APPLE_PAY_FAILED_TO_LOAD: 'Apple Pay failed to load. Please try using a credit card.',
    COULD_NOT_COMPLETE_PURCHASE_CARD_ON_FILE: "We couldn't complete your purchase with the card on file in your Apple Pay account. Please try using a credit card.",
    IS_REQUIRED: 'is required',
    INVALID_VALUE_FOR: 'Invalid value for',
    REQUIRED: 'Required',
    PAYMENT_PROCESSOR_CONNECTION_ERROR: "We're having trouble connecting to our payment processor.",
    RECIPIENT_NAME: 'Recipient Name',
    NAME_REQUIRED: 'Name is required',
    PLACE_ORDER: 'Place Order',
    TIP: 'Tip',
    SERVICE_FEE: 'Service Fee',
    SERVICE_CHARGES: 'Service Charges',
    DISCOUNT: 'Discount',
    TAXES: 'Taxes',
    TOTAL: 'Total',
    DISMISS: 'Dismiss',
    ARE_YOU_SURE: 'Are you sure?',
    VIEW_CART: 'view cart',
    EMAIL_RECEIPT: 'Email Receipt',
    EMAIL_IS_REQUIRED: 'Email is required',
    CREATING_YOUR_ORDER: 'creating your order',
    GETTING_ORDER_INFO: 'getting your order information',
    CALCULATING_ORDER_TOTAL: 'calculating your order total',
    THERE_WAS_AN_ERROR: 'There was an error',
    GET_ORDER_ERROR: "If refreshing this page doesn't help, you can use the Order Number in your email receipt to pick up your order.",
    GET_ORDER_TOTAL_ERROR: 'This might be temporary. You can try refreshing the page before placing your order.',
    BRAINTREE_LOAD_ERROR: "We're having trouble connecting to the payment processor. If the problem persists after refreshing the page, we won't be able to process your order. We've been notified of this issue, and we apologize for the inconvenience.",
    DATE: 'date',
    EVENTS_IN: 'events in',
    BACK: 'Back',
    BACK_LOWERCASE: 'back',
    SELECT_AN_EVENT: 'select an event',
    ALL_EVENTS: 'all events',
    REDEEM_SOON: 'REDEEM SOON',
    REDEEM_NOW: 'REDEEM NOW',
    REDEEMED: 'REDEEMED',
    EXPIRED: 'EXPIRED',
    REDEEMING: 'REDEEMING',
    INFO: 'info',
    EACH: 'each',
    ONLY: 'only',
    LEFT: 'left!',
    REMOVE: 'remove',
    REQUIRED_LOWERCASE: 'required',
    PICK_AT_LEAST: 'pick at least',
    PICK_UP_TO: 'pick up to',
    OPTIONAL: 'optional',
    QR_CODE: 'QR Code',
    DELIVERY_TO: 'Delivery to',
    PICKUP_AT: 'Pickup at',
    SECTION: 'Section',
    ROW: 'Row',
    SEAT: 'Seat',
    SUITE: 'Suite',
    REMOVE_ALL_ITEMS: 'This will remove all items from your current order.',
    THIS_WILL_REMOVE: 'This will remove',
    FROM_YOUR_CART: 'from your cart',
    EVENT_DATE: 'Event Date',
    EVENT: 'Event',
    ITEM: 'Item',
    ITEMS: 'Items',
    CART: 'Cart',
    EXPERIENCE: 'experience',
    MENU: 'menu',
    CLEAR_CART: 'clear cart',
    ADD_ITEMS: 'add items',
    NOT_NEEDED_FOR_SUITES: 'Not needed for suites',
    LOADING: 'loading',
    SELECT_YOUR_SECTION_OR_SUITE: 'Select your section or suite',
    SELECT_YOUR_ROW: 'Select your row',
    SELECT_YOUR_SEAT: 'Select your seat',
    SECTION_LOWERCASE: 'section',
    OR: 'or',
    SUITE_LOWERCASE: 'suite',
    CONFIRM_SEAT: 'Confirm Seat',
    SAVE_SEAT: 'Save Seat',
    VALIDATING_SEAT: 'Validating Seat',
    YOUR_SEAT: 'Your Seat',
    SEAT_NOT_ELIGIBLE_FOR_DELIVERY_FROM_THIS_STAND: 'Seat not eligible for delivery from this stand',
    MODIFY: 'Modify',
    YOU_CAN_EDIT_THESE_UNTIL_YOU_REDEEM: 'You can edit these until you redeem',
    PLEASE_FILL_IN_THE_ABOVE: 'Please fill in the above.',
    PLEASE_ADD_YOUR_SEAT: 'Please add your seat.',
    EDIT_THESE_AFTER_PLACING_ORDER: 'You can edit these after placing your order',
    ENTER_YOUR: 'Enter your',
    DONE: 'DONE',
    NEXT: 'NEXT',
    WHEN_YOU_ARE_READY_TO_REDEEM: "When you're ready to redeem, tap the redeem now button and then present the",
    CONFIRMATION_SCREEN: 'confirmation screen',
    SECOND_CONFIRMATION_SCREEN: 'second confirmation',
    HOW_DO_I_REDEEM: 'How do I redeem',
    HOW_DO_I_REDEEM_CAPS: 'HOW DO I REDEEM',
    EDIT: 'edit',
    YOU_CAN_ONLY_ORDER_FOR_ONE_EVENT: 'You can only order for one event at a time',
    MAX_QUANTITY_REACHED: 'max quantity reached',
    ORDER_NOW: 'order now',
    EMAIL_MY_RECEIPT: 'Email My Receipt',
    ORDER: 'Order',
    YOU_ARE_ABOUT_TO_REDEEM: "You're about to redeem your experience and will have to present the confirmation. This can’t be undone so make sure you're ready.",
    AT: 'at',
    ON: 'on',
    BETWEEN: 'between',
    AND: 'and',
    WHAT_HAPPENS_NEXT: 'WHAT HAPPENS NEXT',
    KEEP_A_LOOK_OUT_FOR_FURTHER_INSTRUCTIONS: 'Keep a look out for further instructions regarding your purchase.',
    THIS_EXPERIENCE_WILL_BE_AVAILABLE_TO_REDEEM: 'This experience will be available to redeem',
    THIS_EXPERIENCE_EXPIRED_ON: 'This experience expired on',
    THIS_EXPERIENCE_WAS_REDEEMED_ON: 'This experience was redeemed on',
    ORDER_NUMBER: 'Order Number',
    ORDER_DETAILS: 'Order Details',
    PLACED_ON: 'Placed on',
    RECEIPT: 'Receipt',
    SUBTOTAL: 'Subtotal',
    NOTHING_FOR_SALE: "There's nothing on sale for this date",
    PLEASE_TRY_ANOTHER_EVENT: 'Please try another event, or check back soon to see if anything becomes available for this one',
    ONLY_CAPS_START: 'Only',
    OF_THIS_ITEM_PER_ORDER_ALLOWED: 'of this item per order allowed',
    SELECT_ONE: 'select one',
    CONFIRM: 'CONFIRM',
    ADD_TO_CART: 'add to cart',
    MUST_BE_21: 'Must be 21+ with vaild ID to order alcohol',
    NO_ITEMS_AVAILABLE_IN_THIS_CATEGORY: 'No Items Available in this Category',
    LOCATION_NOT_CURRENTLY_ACCEPTING_ORDERS: 'This location is not currently accepting orders',
    SENDING: 'SENDING',
    EMAIL_SENT: 'EMAIL SENT',
    FAILED_TRY_AGAIN: 'FAILED: PLEASE TRY AGAIN LATER',
    KIOSK_ORDER: 'Kiosk Order',
    SCAN_THIS_CODE: 'Scan this code when you pick up your order at',
    PRESENT_THIS_PAGE: 'Present this page when you pick up your order at',
    SHOW_THIS_SCREEN_TO_RECEIVE_YOUR_ORDER: 'Show this screen to receive your order.',
    HANG_TIGHT: "Hang tight - we're checking on your order until we're sure it has reached the kitchen",
    ONCE_IT_DOES: "Once it does, you'll see a QR Code here that you can use to pick up your order",
    WE_HOPE_YOU_ENJOYED_YOUR_ORDER: 'We hope you enjoyed your order, thanks again for trying OrderNext!',
    SEND: 'SEND',
    MOBILE_ORDERING_MENUS: 'Mobile Ordering Menus',
    ALMOST_DONE: 'almost done',
    WE_NEED_SOME_MORE_INFO: 'we need some more information',
    ONE_LAST_THING: 'one last thing',
    EXPERIENCES: 'Experiences',
    FOOD_AND_DRINKS: 'Food & Drinks',
    MERCHANDISE: 'Merchandise-GB',
    OPEN_ORDER: 'Open Order',
    PREPARING_YOUR_ORDER: 'Preparing your order',
    FREE: 'FREE',
  }
}

export default GBData
