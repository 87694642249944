import React from "react"

import './ErrorMessage.scss'
import { withTranslation } from 'react-i18next'

const ERROR_TYPES = {
  default: 'default',
  braintreeLoad: 'braintreeLoad',
  createOrder: 'createOrder',
  getOrder: 'getOrder',
  getOrderTotal: 'getOrderTotal',
}

export class ErrorMessage extends React.Component {
  state = {
    errorDismissed: false,
  }

  static defaultProps = {
    type: ERROR_TYPES.default,
  }

  errorTypeActions = {
    [ERROR_TYPES.default]: null,
    [ERROR_TYPES.createOrder]: this.props.t('CREATING_YOUR_ORDER'),
    [ERROR_TYPES.getOrder]: this.props.t('GETTING_ORDER_INFO'),
    [ERROR_TYPES.getOrderTotal]: this.props.t('CALCULATING_ORDER_TOTAL'),
  }

  errorTypeHelpText = {
    [ERROR_TYPES.default]: null,
    [ERROR_TYPES.braintreeLoad]: this.props.t('BRAINTREE_LOAD_ERROR'),
    [ERROR_TYPES.getOrder]: this.props.t('GET_ORDER_ERROR'),
    [ERROR_TYPES.getOrderTotal]: this.props.t('GET_ORDER_TOTAL_ERROR'),
  }

  onDismiss = () => this.setState({errorDismissed: true})

  renderTypeAction = () => {
    const { type, t } = this.props
    const action = this.errorTypeActions[type]
    if (action) {
      return (
        <span className='error-action'>{t('THERE_WAS_AN_ERROR')} {action}:<br/></span>
      )
    }
  }

  renderTypeHelpText = () => {
    const { type } = this.props
    const text = this.errorTypeHelpText[type]
    if (text) {
      return (
        <span className='error-help-text'><br/><br/>{text}</span>
      )
    }
  }

  render() {
    if (this.state.errorDismissed) return null

    const { error, style } = this.props

    return (
      <div className='error-container' style={style}>
        <div className='dismiss-error' onClick={this.onDismiss}>x</div>
        {this.renderTypeAction()}
        <span>{error}</span>
        {this.renderTypeHelpText()}
      </div>
    )
  }
}

export default withTranslation()(ErrorMessage)
