import React, { Component } from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import humps from 'humps'
import { isEmpty } from 'lodash'

import GenericCounter from './GenericCounter'
import Button from './Button'
import { withTranslation } from 'react-i18next'

import './ExperienceCard.scss'

export class ExperienceCard extends Component {
  state = {
    active: true,
    countdownFinished: false,
  }

  static defaultProps = {
    header: {},
    body: {},
    footer: {},
    didClickRedeem: () => {},
    didClickInfo: () => {},
    className: '',
    redemptionState: 'notReady',
    startTime: '',
    countdownDuration: 0,
    showCountdown: false,
    metadata: [],
  }

  redeemButtonClickHandler = (event) => {
    const { didClickRedeem } = this.props

    event.stopPropagation()
    didClickRedeem()
  }

  infoButtonClickHandler = (event) => {
    const { didClickInfo } = this.props

    event.stopPropagation()
    didClickInfo()
  }

  countdownDidFinish = () => {
    this.setState({ countdownFinished: true })
  }

  renderHeader() {
    const { header, metadata, redemptionState, t } = this.props
    let button = null

    if (['ready', 'notReady'].includes(redemptionState) && !isEmpty(metadata)) {
      button = <Button className="info-button" onClick={this.infoButtonClickHandler}>{t('INFO')}</Button>
    }

    return (
      <div className="experience-card-header">
        <h2 className="title">{header.title} {button}</h2>
        <p className="content">{header.content}</p>
      </div>
    )
  }

  renderBody() {
    const { body, redemptionState, startTime, countdownDuration, showCountdown } = this.props
    const { countdownFinished } = this.state

    if (redemptionState === 'redeemed' && showCountdown && !countdownFinished) {
      return (
        <div className="experience-card-body">
          <GenericCounter
            startTime={startTime}
            seconds={countdownDuration}
            counterDidFinish={this.countdownDidFinish}
            renderCallback={(timestamp) => (
              <span className="counter">{timestamp}</span>
            )}
          />
        </div>
      )
    }

    return (
      <div className="experience-card-body">
        <h3 className="title">{body.title}</h3>
        <p className="content">{body.content}</p>
      </div>
    )
  }

  renderFooter() {
    const { footer, redemptionState, showCountdown, t } = this.props
    const { countdownFinished } = this.state

    const BUTTON_TEXT = {
      notReady: t('REDEEM_SOON'),
      ready: t('REDEEM_NOW'),
      redeemed: t('REDEEMED'),
      expired: t('EXPIRED'),
      redeeming: `${t('REDEEMING')}...`,
    }

    if (redemptionState === 'redeemed' && showCountdown && !countdownFinished) {
      return (
        <div className="experience-card-footer">
          <div className="ordernext-logo"></div>
        </div>
      )
    }

    let button = null

    if (redemptionState !== 'notRedeemable') {
      const buttonText = BUTTON_TEXT[redemptionState]
      button = <button onClick={this.redeemButtonClickHandler} className="redeem-button">{buttonText}</button>
    }

    return (
      <div className="experience-card-footer">
        {button}
        <h3 className="title">{footer.title}</h3>
        <p className="content">{footer.content}</p>
      </div>
    )
  }

  toggleBackground = () => {
    this.setState({ active: !this.state.active })
  }

  render() {
    const { redemptionState, showCountdown } = this.props
    const { active, countdownFinished } = this.state
    const classNames = className(
      'experience-card',
      this.props.className,
      humps.decamelize(redemptionState, { separator: '-' }),
      { countdown: redemptionState === 'redeemed' && showCountdown && !countdownFinished },
      { active: redemptionState === 'redeemed' && showCountdown && active }
      )

    return (
      <div className="experience-card-container">
        <div className={classNames} onClick={this.toggleBackground}>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    )
  }
}

ExperienceCard.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  body: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  footer: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  didClickRedeem: PropTypes.func,
  didClickInfo: PropTypes.func,
  className: PropTypes.string,
  redemptionState: PropTypes.oneOf(['notReady', 'ready', 'redeemed', 'expired', 'notRedeemable', 'redeeming']),
  startTime: PropTypes.string,
  countdownDuration: PropTypes.number,
  showCountdown: PropTypes.bool,
  metadata: PropTypes.array,
}

export default withTranslation()(ExperienceCard)
