export const allFieldOptions = {
  number: {
    maskInput: {
      showLastFour: true
    },
    selector: '#number',
  },
  expirationDate: {
    selector: '#expiration_date',
  },
  cvv: {
    selector: '#cvv',
  },
  postalCode: {
    selector: '#postal_code',
  }
}

export const braintreeStyles = {
  input: 'braintree-inputs',
}

export const fieldDisplayLocale = (fieldName, fields) => {
  return fields[fieldName]
}

export const optionalFieldNames = ['postalCode', 'cvv']

export const requiredFieldNames = ['number', 'expirationDate']
