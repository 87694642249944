import React from 'react'
import classNames from 'classnames'
import './MenuCard.scss'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import Skeleton from 'react-loading-skeleton'

function descriptionBuilder(pickupShortDescription, deliveryShortDescription) {
  const descriptions = [pickupShortDescription, deliveryShortDescription]
  return descriptions.filter(string => isString(string) && !isEmpty(string)).join(' • ')
}

const MenuCard = ({ pickupShortDescription, deliveryShortDescription, name, image, available = true, loading }) => {
  const className = classNames('menu', { unavailable: !available })
  const serviceTypesText = descriptionBuilder(pickupShortDescription, deliveryShortDescription)

  return (
    <div className={className}>
      <h2>{loading ? <Skeleton width='75%' /> : name}</h2>
      {loading && <div className="image"><Skeleton height='100%' /></div>}
      {!loading && image && <div className="image" style={{ backgroundImage: `url(${image})` }} alt={name} />}
      <p>{loading ? <Skeleton width='60%' /> : serviceTypesText}</p>
    </div>
  )
}

export default MenuCard
