export const REDEEM_USER_ITEM_REQUESTED = 'REDEEM_USER_ITEM_REQUESTED'
export const REDEEM_USER_ITEM_SUCCEEDED = 'REDEEM_USER_ITEM_SUCCEEDED'
export const REDEEM_USER_ITEM_FAILED = 'REDEEM_USER_ITEM_FAILED'

export function redeemUserItem(itemId, orderId) {
  return {
    type: REDEEM_USER_ITEM_REQUESTED,
    itemId,
    orderId,
  }
}

export function redeemUserItemSucceeded(payload, id, orderId) {
  return {
    type: REDEEM_USER_ITEM_SUCCEEDED,
    payload,
    id,
    orderId,
  }
}

export function redeemUserItemFailed(payload, id) {
  return {
    type: REDEEM_USER_ITEM_FAILED,
    payload,
    id,
  }
}
