import React from "react"

import { get, includes, keys } from 'lodash'

import Money from '../../utils/money'
import ErrorMessage from '../ErrorMessage'
import OrderSummaryItem from './OrderSummaryItem'
import Section from '../../components/Section'
import { withTranslation } from 'react-i18next'

export class OrderTotalSection extends React.Component {

  displayValue = (centsOrDollars) => {
    if (includes([0, '0.00', '0', undefined, null], centsOrDollars)) return '-'

    return Number.isInteger(centsOrDollars)
      ? Money.centsToDollars(centsOrDollars)
      : Money.formatDollars(centsOrDollars)
  }

  inCents = (centsOrDollars) => {
    if (!centsOrDollars) return 0

    return Number.isInteger(centsOrDollars)
      ? centsOrDollars
      : Money.dollarsToCents(centsOrDollars)
  }

  showError = () => keys(this.props.error).length > 0

  subtotal = () => {
    const { deliveryFee, finalTotal, serviceCharges, tax, tip } = this.props

    return this.inCents(finalTotal) - this.inCents(tax) - this.inCents(deliveryFee) - this.inCents(serviceCharges) - this.inCents(tip)
  }

  render() {
    const { deliveryFee, error, finalTotal, lineItems, serviceCharges, tax, loading, tip, isExperience, t } = this.props

    const displayTipAmount = () => {
      if (!isExperience) {
        return(<OrderSummaryItem label={t('TIP')} value={this.displayValue(tip)} loading={loading} />)
      }
    }

    return (
      <Section className="total">
        {this.showError() && (
          <ErrorMessage type="getOrderTotal" error={error} style={{marginBottom:'24px'}}/>
        )}

        {lineItems && keys(lineItems).map((id) => {
          const lineItem = lineItems[id]
          if (!!get(lineItem, 'attributes.specialType')) return null

          return (
            <OrderSummaryItem
              key={id}
              label={`${get(lineItem, 'attributes.quantity')} - ${get(lineItem, 'attributes.name')}`}
              value={this.displayValue(get(lineItem, 'attributes.totalAmountInCents'))}
              loading={loading}
            />
          )
        })}

        <OrderSummaryItem
          label={this.props.itemQuantityLabel}
          value={this.displayValue(this.subtotal())}
          loading={loading}
        />
        {this.inCents(deliveryFee) > 0 &&
          <OrderSummaryItem label={t('SERVICE_FEE')} value={this.displayValue(deliveryFee)} loading={loading} />
        }
        {this.inCents(serviceCharges) > 0 &&
          <OrderSummaryItem label={t('SERVICE_CHARGES')} value={this.displayValue(serviceCharges)} loading={loading} />
        }
        <OrderSummaryItem label={t('TAXES')} value={this.displayValue(tax)} loading={loading} />
        {displayTipAmount()}
        <OrderSummaryItem label={t('TOTAL')} value={this.displayValue(finalTotal)} loading={loading} />
      </Section>
    )
  }
}

export default withTranslation()(OrderTotalSection)