import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import Events from '../components/Events'

import { setEventFilter } from '../actions/filter'

import { getEvents } from '../selectors/event'

import eventsMapper from '../mappers/events'
import { withTranslation } from 'react-i18next'

export class EventsFilter extends Component {
  render() {
    return <Events { ...this.props } backButtonText={this.props.t('MENU')} allEventsToggle />
  }
}

function mapStateToProps(state, ownProps) {
  const standId = get(ownProps, 'match.params.standId', '')
  const menuId = get(ownProps, 'match.params.menuId', '')
  const backButtonRoute = `/${standId}/experiences/${menuId}`
  const eventFilter = get(state, 'filter.eventFilter', undefined)
  const events = getEvents(state)

  const serializedEvents = eventsMapper(events)

  return {
    backButtonRoute,
    events: serializedEvents,
    selectedEvent: eventFilter,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const standId = get(ownProps, 'match.params.standId', '')
  const menuId = get(ownProps, 'match.params.menuId', '')

  return {
    didSelectEvent: (selectedEvent) => {
      dispatch(setEventFilter(selectedEvent))
      ownProps.history.replace(`/${standId}/experiences/${menuId}`)
    },
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EventsFilter))
