import humps from  'humps'
import { experienceOrderTotalMapper } from './orderTotal'

export function experienceOrderMapper({ items, email, paymentMethodNonce, payments }) {
  const orderObject = {
    ...experienceOrderTotalMapper(items, email),
    paymentMethodNonce,
    payments,
    webOrder: true,
  }

  return humps.decamelizeKeys(orderObject)
}

export default experienceOrderMapper
