import React from "react"
import classNames from 'classnames'

import '../../../sharedCSS/FormFields.scss'

export default class CreditCardFormField extends React.Component {

  render() {
    const { displayText, errorText, helpText, focused, isInvalid, fieldID, notEmpty } = this.props

    return (
      <div className="form-row">
        <div
          id={fieldID}
          className={classNames('hosted-field', { 'form-error': isInvalid }, { 'focused': focused }, { 'not-empty': notEmpty })}
        />

        <label className={classNames({ 'form-error': isInvalid })} htmlFor={fieldID}>
          {displayText}
        </label>

        {!isInvalid && <div className='help-text'>{helpText}</div>}

        {isInvalid && (
          <div className='error-help-text'>{errorText}</div>
        )}
      </div>
    )
  }
}
