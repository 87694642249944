import React from "react"
import Section from '../../components/Section'
import './OrderGratuitySection.scss'

const OrderGratuitySection = ({ suggestions, showCustomTipDialog, tipButtonId, updateGratuity }) => {

  const createUnselectedButton = (title, amount, custom, key) => {
    return (<span key={key} onClick={ custom ? () => showCustomTipDialog(key) : () => updateGratuity(amount, key) }>{title}</span>)
  }

  const createSelectedButton = (title, amount, custom, key) => {
    return(<span key={key} className="selected" onClick={ custom ? () => showCustomTipDialog(key) : null }>{title}</span>)
  }

  const createButton = (title, selected, amount, custom, key) => {
    if (tipButtonId !== undefined) { // page loaded and using it
      if (key === tipButtonId || (tipButtonId === "CUSTOM" && custom) ) {
        return(createSelectedButton(title, amount, custom, key))
      } else {
        return(createUnselectedButton(title, amount, custom, key))
      }
    } else { // on first load, no state rehydration
      if (selected) {
        return(createSelectedButton(title, amount, custom, key))
      } else {
        return(createUnselectedButton(title, amount, custom, key))
      }
    }
  }

  const displaySuggestions = () => {
    return suggestions.map((s, index) => {
      return createButton(s.tipDisplay, s.tipSelected, s.tipAmountInCents, s.tipCustom, index)
    })
  }

  return (
    <Section>
      <div className="order-gratuity-section btn-group">{displaySuggestions()}</div>
    </Section>
  )
}

export default OrderGratuitySection
