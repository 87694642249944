import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import USData from './translations/en-US'

const resources = {
  'en-US': USData,
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en-US',
    returnObjects: true,
  })

export default i18n
