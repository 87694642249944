import {
  CLEAR_ORDER_TOTAL,
  EXPERIENCE_ORDER_TOTAL_SUCCEEDED,
  EXPERIENCE_ORDER_TOTAL_FAILED,
  ORDER_TOTAL_SUCCEEDED,
  ORDER_TOTAL_FAILED,
} from '../actions/orderTotal'

import humps from 'humps'
import values from 'lodash/values'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ORDER_TOTAL:
      return {
        ...state,
        latest: {},
        error: {},
      }

    case EXPERIENCE_ORDER_TOTAL_SUCCEEDED:
      return {
        ...state,
        latest: humps.camelizeKeys(action.payload),
        error: {},
      }

    case EXPERIENCE_ORDER_TOTAL_FAILED:
      return {
        ...state,
        latest: {},
        error: action.payload,
      }

    case ORDER_TOTAL_SUCCEEDED:
      // we only ever need the latest response, so we're going to store it under 'latest'
      const totalObject = values(action.orderTotal)[0]

      return {
        ...state,
        latest: totalObject,
        error: {},
      }

    case ORDER_TOTAL_FAILED:
      return {
        ...state,
        latest: {},
        error: action.payload,
      }

    default:
      return state
  }
}

export default reducer
