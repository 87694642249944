import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next';

import './Button.scss'

export const Button = ({ children, small = false, ...props }) => {
  const className = classNames('button', props.className, { small })

  return (
    <button {...props} className={className}>
      {children}
    </button>
  )
}

const ViewCart = ({ quantity, total, ...props }) => {
  const className = classNames('button brand view-cart', props.className)

  const { t  } = useTranslation();

  return (
    <div {...props} className={className}>
      <span className="quantity">
        <span className="badge">{quantity}</span>
      </span>
      <span className="text">{t('VIEW_CART')}</span>
      <span className="total">{total}</span>
    </div>
  )
}

Button.Brand = (props) => {
  const className = classNames('brand', props.className)

  return (
    <Button {...props} className={className} />
  )
}

Button.ViewCart = ViewCart

export default Button
