import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isArray } from 'lodash'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Modal from '../components/Modal'
import NavigationBar from '../components/NavigationBar'
import Button from '../components/Button'

import { updateUserLocation, updateUserLocationConfirmed } from '../actions/user'
import { loadSections, loadRows, loadSeats, loadMenusForSeat } from '../actions/seat'

import { makeGetMenu } from '../selectors/menu'
import { getAvailableSections, getAvailableRows, getAvailableSeats } from '../selectors/seat'
import { getUserLocation } from '../selectors/user'
import { withTranslation } from 'react-i18next'

import './ItemModal.scss'
import './DeliveryModal.scss'
import '../sharedCSS/FormFields.scss'

export class DeliveryModal extends Component {
  constructor(props) {
    super(props)

    this.modalRef = React.createRef()
    this.fieldRefs = {}

    this.state = {
      section: get(this.props, 'userLocation.section', ''),
      sectionIsSuite: get(this.props, 'userLocation.sectionIsSuite', false),
      row: get(this.props, 'userLocation.row', ''),
      seat: get(this.props, 'userLocation.seat', ''),
      orderLocationUuid: get(this.props, 'orderLocationUuid', ''),
      menuId: this.props.menuId,
      shouldShowError: false,
      shouldSetSeatUuid: false,
      disableLoading: !!get(this.props, 'userLocation.section', ''),
    }
  }

  componentDidMount() {
    const { orderLocationUuid, userHasLocation } = this.props

    if (userHasLocation) this.props.loadMenusForSeat(orderLocationUuid)
    this.props.loadSections()

    this.fetchOrderLocationUuid()
  }

  componentDidUpdate(prevProps) {
    const { shouldSetSeatUuid, orderLocationUuid } = this.state
    const { userLocation: { section, row, seat }, seatValid, seatsLoading } = this.props

    if (prevProps.seatValid !== seatValid && !seatValid) {
      this.setState({ section: '', row: '', seat: '', shouldShowError: true })

      return
    }

    if (prevProps.seatValid !== seatValid && seatValid) {
      const testSection = this.props.availableSections.find(s => s['name'] === section) || {}
      const aisleIds = testSection.aisle_ids || []

      this.props.loadSections()
      if (!isEmpty(row)) this.props.loadRows(section, aisleIds)
      if (!isEmpty(seat)) this.props.loadSeats(section, row, aisleIds)
      this.setState({ section, row, seat })

      return
    }

    if (shouldSetSeatUuid && !seatsLoading) {
      this.updateField('seat', seat)

      if (orderLocationUuid) {
        this.props.loadMenusForSeat(orderLocationUuid)
        this.setState({ shouldSetSeatUuid: false })
      }
    }
  }

  fetchOrderLocationUuid(prevProps) {
    const { userLocation: { section, row, seat }, userHasLocation } = this.props

    if (section && row && seat && !userHasLocation) {
      const testSection = this.props.availableSections.find(s => s['name'] === section) || {}
      const aisleIds = testSection.aisle_ids || []

      this.props.loadSections()
      this.props.loadRows(section, aisleIds)
      this.props.loadSeats(section, row, aisleIds)
      this.setState({ shouldSetSeatUuid: true })
    }
  }

  updateField = (field, value) => {
    // value will be a section.name for sections, a string for rows, and a uuid for seats
    let newState = {
      [field]: value,
      shouldShowError: false,
    }
    let section = null

    if (field === 'section') {
      section = this.props.availableSections.find(s => s['name'] === value)

      if (section.is_suite) {
        newState = { ...newState, orderLocationUuid: section.location_uuid, sectionIsSuite: true }
      } else {
        newState = { ...newState, orderLocationUuid: '', sectionIsSuite: false }
      }
    }

    if (field === 'seat') {
      const seatUuid = this.props.availableSeats.find(seat => seat['seat'] === value)['uuid']
      newState.orderLocationUuid = seatUuid
    }

    if (field === 'section') newState.row = ''
    if (field === 'section' || field === 'row') newState.seat = ''

    this.setState(newState, () => {
      const { loadRows, loadSeats } = this.props
      const { section, sectionIsSuite, row } = this.state
      const testSection = this.props.availableSections.find(s => s['name'] === section) || {}
      const aisleIds = testSection.aisle_ids || []

      if (field === 'section' && !sectionIsSuite) loadRows(section, aisleIds)
      if (field === 'row') loadSeats(section, row, aisleIds)

      this.fieldRefs[field].blur()
    })
  }

  formIsValid = () => {
    const { section, sectionIsSuite, row, seat } = this.state
    const { sectionsLoading, rowsLoading, seatsLoading } = this.props
    const fieldsEntered = sectionIsSuite || (!isEmpty(section) && !isEmpty(row) && !isEmpty(seat))
    const notLoading = !sectionsLoading && !rowsLoading && !seatsLoading

    return fieldsEntered && notLoading
  }

  callToActionField = () => {
    const { section, sectionIsSuite, row, seat } = this.state

    if (!section) return 'section'
    if (!row && !sectionIsSuite) return 'row'
    if (!seat && !sectionIsSuite) return 'seat'
  }

  closeModal = () => {
    const closeModal = get(this, 'modalRef.current.closeModal', () => {})

    closeModal()
  }

  onSave = () => {
    const { redirectTo, updateUserLocation, updateUserLocationConfirmed } = this.props

    updateUserLocation({ ...this.state })
    updateUserLocationConfirmed()
    this.props.history.replace(redirectTo)
  }

  getFormData = () => {
    const { section, seat, sectionIsSuite, row, disableLoading } = this.state
    const { availableSections, availableRows, availableSeats, sectionsLoading, rowsLoading, seatsLoading, t } = this.props
    const suiteText = t('NOT_NEEDED_FOR_SUITES')

    let sectionEmptyLabel = sectionsLoading ? `${t('LOADING')}...` : t('SELECT_YOUR_SECTION_OR_SUITE')
    if (disableLoading && section) sectionEmptyLabel = section

    let rowEmptyLabel = rowsLoading ? `${t('LOADING')}...` : t('SELECT_YOUR_ROW')
    if (disableLoading && row) rowEmptyLabel = row

    let seatEmptyLabel = seatsLoading ? `${t('LOADING')}...` : t('SELECT_YOUR_SEAT')
    if (disableLoading && seat) seatEmptyLabel = seat

    return [
      {
        label: `${t('SECTION_LOWERCASE')} <span class="lowercase">${t('OR')}</span> ${t('SUITE_LOWERCASE')}`,
        emptyLabel: sectionEmptyLabel,
        field: 'section',
        options: availableSections,
        disabled: sectionsLoading,
      },
      {
        label: t('ROW'),
        emptyLabel: sectionIsSuite ? suiteText : rowEmptyLabel,
        field: 'row',
        options: availableRows,
        disabled: isEmpty(section) || rowsLoading || sectionIsSuite,
      },
      {
        label: t('SEAT'),
        emptyLabel: sectionIsSuite ? suiteText : seatEmptyLabel,
        field: 'seat',
        options: availableSeats,
        disabled: isEmpty(row) || seatsLoading || sectionIsSuite,
      },
    ]
  }

  optionRow = (option) => {
    // section: {uuid: xxx, name: 2} // seat: {uuid: xxx, seat: 2} // rows are strings
    const value = option.name || option.seat || option
    const key = option.uuid || option.name || option

    return <option key={key} value={value}>{value}</option>
  }

  renderFormRow = ({ label, emptyLabel = '', field, options = [], disabled }) => {
    const fieldValue = this.state[field]
    const callToActionField = this.callToActionField()
    let safeOptions = options || []

    if (!isArray(options)) safeOptions = []

    return (
      <div className="form-row" key={field}>
        <select
          onChange={(event) => { this.updateField(field, event.target.value) }}
          value={fieldValue}
          className={classNames({ selected: !isEmpty(fieldValue), ready: callToActionField === field })}
          disabled={disabled}
          ref={node => this.fieldRefs[field] = node}
        >
          <option disabled value="">{`${emptyLabel}`}</option>
          {safeOptions.map(this.optionRow)}
        </select>
        <label dangerouslySetInnerHTML={{ __html: label }} className={classNames({ selected: !isEmpty(fieldValue) })}></label>
      </div>
    )
  }

  render() {
    const { shouldShowError } = this.state
    const { menu, onCloseModal, userHasLocation, seatValid, t } = this.props
    const formData = this.getFormData()
    let buttonText = userHasLocation && seatValid !== false ? t('CONFIRM_SEAT') : t('SAVE_SEAT')

    if (seatValid === 'waiting') buttonText = `${t('VALIDATING_SEAT')}...`

    return (
      <Modal
        ref={this.modalRef}
        onCloseModal={onCloseModal}
      >
        <NavigationBar.Close text="" onClick={this.closeModal} />
        <div className="delivery-location">
          <div className="details">
            <h1 className="name">{t('YOUR_SEAT')}</h1>
            <span className="description">{get(menu, 'attributes.shortDescription', '')}</span>
          </div>
        </div>
        <form className="delivery-location-form form-container">
          {formData.map(this.renderFormRow)}
        </form>
        <Button.Brand
          disabled={!this.formIsValid() || seatValid === 'waiting'}
          onClick={this.onSave}
        >
          {buttonText}
        </Button.Brand>
        {!seatValid && shouldShowError && <p className="seat-unavailable">{t('SEAT_NOT_ELIGIBLE_FOR_DELIVERY_FROM_THIS_STAND')}</p>}
        {!shouldShowError && <p className="page-bottom-spacer"/>}
      </Modal>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const menuId = get(ownProps, 'match.params.menuId', get(ownProps, 'menuId', ''))
  const redirectTo = get(ownProps, 'location.state.redirect', get(ownProps, 'location.pathname', '/'))

  const getMenu = makeGetMenu()
  const menu = getMenu(state, menuId)
  const userLocation = getUserLocation(state)
  const availableSections = getAvailableSections(state)
  const availableRows = getAvailableRows(state)
  const availableSeats = getAvailableSeats(state)
  const sectionsLoading = get(state, 'seat.availableSectionsLoading', false)
  const rowsLoading = get(state, 'seat.availableRowsLoading', false)
  const seatsLoading = get(state, 'seat.availableSeatsLoading', false)
  const seatValid = get(state, 'user.location.valid', true)
  const orderLocationUuid = userLocation.orderLocationUuid
  const userHasLocation = !!orderLocationUuid

  return {
    menu,
    menuId,
    userLocation,
    seatValid,
    userHasLocation,
    redirectTo,
    availableSections,
    availableRows,
    availableSeats,
    sectionsLoading,
    rowsLoading,
    seatsLoading,
    orderLocationUuid,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const standId = get(ownProps, 'match.params.standId', get(ownProps, 'standId', ''))
  const onCloseModal = get(ownProps, 'onCloseModal', ownProps.history.goBack)

  return {
    updateUserLocation: ({ section, sectionIsSuite, row, seat, orderLocationUuid, menuId }) => {
      dispatch(updateUserLocation({ section, sectionIsSuite, row, seat, orderLocationUuid, menuId }))
    },
    updateUserLocationConfirmed: () => dispatch(updateUserLocationConfirmed(true)),
    loadSections: () => dispatch(loadSections({ standId })),
    loadRows: (section, aisleIds) => dispatch(loadRows({ standId, section, aisleIds })),
    loadSeats: (section, row, aisleIds) => dispatch(loadSeats({ standId, section, row, aisleIds })),
    loadMenusForSeat: (orderLocationUuid) => dispatch(loadMenusForSeat({ standId, orderLocationUuid })),
    onCloseModal: () => onCloseModal()
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DeliveryModal))