import { call, put, select, takeLatest, all } from 'redux-saga/effects'

import Remote from '../remote'
import { setLoading, setFailed, setSucceeded } from '../actions/remote'
import { makeGetCartMenuItems } from '../selectors/cart'
import { orderMapper } from '../mappers/order'
import { experienceOrderMapper } from '../mappers/experiences/order'

import {
  createExperienceOrderSucceeded,
  createExperienceOrderFailed,
  CREATE_EXPERIENCE_ORDER_REQUESTED,
  createOrderSucceeded,
  createOrderFailed,
  CREATE_ORDER_REQUESTED,
  updateOrderSucceeded,
  updateOrderFailed,
  UPDATE_ORDER_REQUESTED,
  getExperienceOrderSucceeded,
  getExperienceOrderFailed,
  GET_EXPERIENCE_ORDER_REQUESTED,
  getOrderSucceeded,
  getOrderFailed,
  GET_ORDER_REQUESTED,
  updateOrderMetadataSucceeded,
  updateOrderMetadataFailed,
  UPDATE_ORDER_METADATA_REQUESTED,
} from '../actions/order'

export function* createExperienceOrder(params) {
  const getCartMenuItems = makeGetCartMenuItems()
  const items = yield select(getCartMenuItems)
  const orderParams = experienceOrderMapper({ items, ...params.orderParams })

  try {
    const result = yield call(Remote.createExperienceOrder, orderParams)
    yield put(createExperienceOrderSucceeded(result))
  } catch (err) {
    yield put(createExperienceOrderFailed(err))
  }
}

export function* createOrder(params) {
  const getCartMenuItems = makeGetCartMenuItems()
  const items = yield select(getCartMenuItems)
  const menuId = yield select(state => state.cart.menuId)
  const orderParams = orderMapper({ items, menuId, ...params.orderParams })

  try {
    const result = yield call(Remote.createOrder, orderParams)
    yield put(createOrderSucceeded(result))
  } catch (err) {
    yield put(createOrderFailed(err))
  }
}

export function* updateOrder(params) {
  const endpoint = Remote.endpoints.updateOrder
  yield put(setLoading(endpoint))

  try {
    const result = yield call(Remote.updateOrder, params)

    yield all([
      put(updateOrderSucceeded(result)),
      put(setSucceeded(endpoint, true)),
    ])
  } catch (err) {
    yield all([
      put(updateOrderFailed(params, err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* getExperienceOrder(orderId) {
  try {
    const result = yield call(Remote.getExperienceOrder, orderId)
    yield put(getExperienceOrderSucceeded(result))
  } catch (err) {
    yield put(getExperienceOrderFailed(orderId, err))
  }
}

export function* getOrder(orderId) {
  try {
    const result = yield call(Remote.getOrder, orderId)
    yield put(getOrderSucceeded(result))
  } catch (err) {
    yield put(getOrderFailed(orderId, err))
  }
}

export function* updateOrderMetadata(params) {
  const endpoint = Remote.endpoints.updateOrderMetadata
  yield put(setLoading(endpoint))

  try {
    const result = yield call(Remote.updateOrderMetadata, params)

    yield all([
      put(updateOrderMetadataSucceeded(result)),
      put(setSucceeded(endpoint)),
    ])
  } catch (err) {
    yield all([
      put(updateOrderMetadataFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchCreateExperienceOrder() {
  yield takeLatest(CREATE_EXPERIENCE_ORDER_REQUESTED, createExperienceOrder)
}

export function* watchCreateOrder() {
  yield takeLatest(CREATE_ORDER_REQUESTED, createOrder)
}

export function* watchGetOrder() {
  yield takeLatest(GET_ORDER_REQUESTED, getOrder)
}

export function* watchGetExperienceOrder() {
  yield takeLatest(GET_EXPERIENCE_ORDER_REQUESTED, getExperienceOrder)
}

export function* watchUpdateOrder() {
  yield takeLatest(UPDATE_ORDER_REQUESTED, updateOrder)
}

export function* watchUpdateOrderMetadata() {
  yield takeLatest(UPDATE_ORDER_METADATA_REQUESTED, updateOrderMetadata)
}
