export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART'
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAR_ORDER_NOW = 'CLEAR_ORDER_NOW'
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART'
export const UPDATE_METADATA = 'UPDATE_METADATA'
export const UPDATE_TIP_AMOUNT = 'UPDATE_TIP_AMOUNT'

export function addItemToCart(id, quantity, modifiers = [], orderNow = false, productType, variant) {
  return {
    type: ADD_ITEM_TO_CART,
    payload: {
      id,
      quantity,
      modifiers,
      orderNow,
      productType,
      variant,
    },
  }
}

export function setActiveMenu(menuId, standId) {
  return {
    type: SET_ACTIVE_MENU,
    payload: {
      menuId,
      standId,
    },
  }
}

export function clearCart() {
  return {
    type: CLEAR_CART
  }
}

export function clearOrderNow() {
  return {
    type: CLEAR_ORDER_NOW
  }
}

export function removeItemFromCart(id, modifiersIndex, variantId) {
  return {
    type: REMOVE_ITEM_FROM_CART,
    payload: {
      id,
      modifiersIndex,
      variantId,
    }
  }
}

export function updateMetadata(itemId, metadata={}) {
  return {
    type: UPDATE_METADATA,
    payload: {
      itemId,
      metadata
    }
  }
}

export function updateGratuity(tipAmount, tipButtonId) {
  return {
    type: UPDATE_TIP_AMOUNT,
    payload: {
      tipAmount,
      tipButtonId
    }
  }
}
