import React from "react"
import braintree from "braintree-web"
import classNames from 'classnames'
import includes from 'lodash/includes'

import Analytics from "../../reporting/analytics"
import Button from "../Button"
import ErrorMessage from "../ErrorMessage"
import Section from "../Section"
import EmailFormField from "../EmailFormField"

import { BRAINTREE_TOKEN, SHOW_APPLE_PAY } from '../../constants'

// payment options
import ApplePay from './payments/ApplePay'
import CreditCard from './payments/CreditCard'

import './OrderForm.scss'
import './payments/CreditCard.scss'
import '../../sharedCSS/FormFields.scss'
import { withTranslation } from 'react-i18next'

export class OrderForm extends React.Component {
  constructor(props) {
    super(props)

    this.applePayClient = undefined
    this.braintreeClient = undefined
    this.focusRef = React.createRef()
  }

  state = {
    braintreeLoadError: null,
    braintreeResponse: null,
    creditCardReady: false,
    email: "",
    emailValid: false,
    formIsSubmitting: false,
    formIsValid: true,
    hasBeenSubmitted: false,
    name: '',
    nameValid: false,
    orderIsSubmitting: false,
    paymentMethods: ['creditCard'],
    selectedPayment: 'creditCard',
    submittingEmail: false,
  }

  dismissKeyboard() {
    this.focusRef && this.focusRef.current && this.focusRef.current.focus()
    this.focusRef && this.focusRef.current && this.focusRef.current.blur()
  }

  componentDidMount() {
    if (this.props.isFree) return

    braintree.client.create({
      authorization: BRAINTREE_TOKEN
    }, this.braintreeClientCreated)
  }

  componentWillUnmount() {
    this.dismissKeyboard()
    this.applePayClient && this.applePayClient.teardown()
    this.braintreeClient && this.braintreeClient.teardown()
  }

  applePayClientCreated = (err, instance) => {
    // TODO send to rollbar so we can see what's up
    if (err) { return }

    this.applePayClient = instance
    this.setState(state => {
      const paymentMethods = state.paymentMethods.concat("applePay")
      return { paymentMethods, selectedPayment: null }
    })
  }

  braintreeClientCreated = (err, client) => {
    if (err) {
      this.setState({ braintreeLoadError: err.message })
      return
    }

    this.braintreeClient = client
    this.setState({ creditCardReady: true })

    if (SHOW_APPLE_PAY) {
      const test = window.ApplePaySession

      if (test && test.supportsVersion(3) && test.canMakePayments()) {
        braintree.applePay.create({client: this.braintreeClient}, this.applePayClientCreated)
      }
    }
  }

  disableSubmitButton = () => {
    const { braintreeResponse, formIsValid, hasBeenSubmitted, selectedPayment } = this.state
    const { isFree } = this.props

    if (isFree) { return false }
    if (!selectedPayment) { return true }
    if (selectedPayment !== "creditCard" && braintreeResponse) { return false }
    if (selectedPayment !== "creditCard") { return true }
    if (hasBeenSubmitted && !formIsValid) { return true }
    return false
  }

  handleNameChange = (event) => {
    const name = event.target.value
    const nameValid = name.length > 0

    this.setState({ name, nameValid, formIsValid: nameValid })
  }

  onBraintreeTokenized = (payload) => {
    this.setState({ braintreeResponse: payload })
    Analytics.paymentMethodEntered(payload)
    this.onPlaceOrder()
  }

  onBraintreeTokenizedFailed = (errorMessage) => {
    this.setState({ tokenizationError: errorMessage })

    const selected = this.state.selectedPayment
    if (selected !== "creditCard") {
      this.setState(state => {
        const paymentMethods = state.paymentMethods.filter((item) => item !== selected)
        const newSelected = paymentMethods.length === 1 ? "creditCard" : null
        return { selectedPayment: newSelected, paymentMethods }
      })
    }
  }

  onEmailValid = (email, emailValid) => {
    this.setState({ email: email, emailValid: emailValid }, () => {
      if (this.validateForm()) this.setState({ formIsValid: true })
    })
  }

  onPaymentSelected = (paymentMethod) => {
    this.setState({ selectedPayment: paymentMethod })
  }

  onPaymentValid = () => {
    const { formIsValid } = this.state

    if (this.validateForm() && !formIsValid) this.setState({ formIsValid: true })
  }

  onPlaceOrder = () => {
    const { isFree, t } = this.props
    const { braintreeResponse, formIsSubmitting, orderIsSubmitting } = this.state

    setTimeout(() => { this.dismissKeyboard() }, 100)

    if (this.braintreeClient === undefined && !isFree) {
      this.setState({
        braintreeLoadError: t('PAYMENT_PROCESSOR_CONNECTION_ERROR')
      })
      return null
    }

    if (orderIsSubmitting) return null
    if (!formIsSubmitting) this.setState({ formIsSubmitting: true })

    let response = braintreeResponse
    if (!response && isFree) response = { nonce: '', paymentType: '' }

    if (response) {
      if (!this.validateForm()) return null
      this.submitOrder(response)
    }
  }

  onValidationFailed = () => {
    this.validateForm()
    this.setState({ formIsSubmitting: false })
  }

  renderEmailField = () => {
    const { isExperience, t } = this.props
    if (!isExperience) return

    const { email, emailValid, hasBeenSubmitted } = this.state
    const emailInvalid = !emailValid && hasBeenSubmitted
    const emailClass = classNames({ 'form-error': emailInvalid, 'not-empty': email.length > 0 })
    const helpText = !emailValid ? `*${t('REQUIRED')}` : " "

    return (
      <EmailFormField
        onEmailValid={this.onEmailValid}
        email={email}
        emailClass={emailClass}
        emailInvalid={emailInvalid}
        focusRef={this.focusRef}
        helpText={helpText}
      />
    )
  }

  renderNameField = () => {
    const { isExperience, menu, t } = this.props
    const serviceType = (menu || {}).serviceType

    if (isExperience || serviceType === "QuickPay") return

    const { nameValid, hasBeenSubmitted } = this.state
    const nameInvalid = !nameValid && hasBeenSubmitted
    const nameClass = classNames({ 'form-error': nameInvalid, 'not-empty': nameValid })
    const helpText = !nameValid ? `*${t('REQUIRED')}` : ""

    return (
      <form className="form-container">
        <div className='name-form form-row'>
          <input
            autoComplete='name'
            className={nameClass}
            name='user[first_name]'
            id='name'
            onChange={this.handleNameChange}
            placeholder='Jane'
            type='name'
            value={this.state.name}
          />
          <label className={nameClass} htmlFor='user[first_name]'>{t('RECIPIENT_NAME')}</label>
          {nameInvalid && <div className='error-help-text'>{t('NAME_REQUIRED')}</div>}
          {!nameInvalid && <div className='help-text'>{helpText}</div>}
        </div>
      </form>
    )
  }

  showApplePay = () => {
    const { braintreeResponse, paymentMethods, selectedPayment } = this.state
    const { isFree } = this.props

    if (isFree) return false
    if (!this.applePayClient) return false
    if (!includes(paymentMethods, "applePay")) return false
    if (selectedPayment === "applePay" && braintreeResponse) return false
    return true
  }

  showCreditCard = () => {
    const { braintreeResponse, selectedPayment } = this.state
    const { isFree } = this.props

    if (isFree) return false
    if (braintreeResponse && selectedPayment !== "creditCard") return false
    return true
  }

  submitOrder = (braintreeResponse) => {
    this.setState({ orderIsSubmitting: true })
    this.props.onSuccess(braintreeResponse, { name: this.state.name, email: this.state.email })
  }

  validateForm = () => {
    const { hasBeenSubmitted, nameValid, emailValid } = this.state
    const { isExperience, menu } = this.props
    const serviceType = (menu || {}).serviceType

    if (!hasBeenSubmitted) this.setState({ hasBeenSubmitted: true })

    if ((isExperience && emailValid) || serviceType === "QuickPay" || nameValid) {
      this.setState({ formIsValid: true })
      return true
    }

    this.setState({ formIsSubmitting: false, formIsValid: false })
    return false
  }

  

  render() {
    const { braintreeLoadError, formIsSubmitting, selectedPayment, tokenizationError } = this.state
    
    return (
      <Section>
        <input type="text" style={{ padding: 0, margin: 0, border: 'none', height: 0 }} ref={this.focusRef} />
        {this.state.orderIsSubmitting && <Section className='loading-svg' />}

        {this.renderNameField()}
        {this.renderEmailField()}

        {braintreeLoadError && <ErrorMessage type="braintreeLoad" error={braintreeLoadError} style={{margin: '24px 0'}}/>}
        {tokenizationError && <ErrorMessage error={tokenizationError} style={{margin: '24px 0'}}/>}

        {this.showApplePay() && (
          <ApplePay
            applePayClient={this.applePayClient}
            onBraintreeTokenized={this.onBraintreeTokenized}
            onBraintreeTokenizedFailed={this.onBraintreeTokenizedFailed}
            onPaymentSelected={this.onPaymentSelected}
            orderTotalInCents={this.props.orderTotalInCents}
          />
        )}

        {this.showCreditCard() && (
          <CreditCard
            braintreeClient={this.state.creditCardReady && this.braintreeClient}
            isSelected={selectedPayment === "creditCard"}
            onBraintreeTokenized={this.onBraintreeTokenized}
            onBraintreeTokenizedFailed={this.onBraintreeTokenizedFailed}
            onPaymentSelected={this.onPaymentSelected}
            onPaymentValid={this.onPaymentValid}
            onValidationFailed={this.onValidationFailed}
            timeToTokenize={selectedPayment === "creditCard" && formIsSubmitting}
          />
        )}

        <Button.Brand disabled={this.disableSubmitButton()} onClick={this.onPlaceOrder}>
          {this.props.t('PLACE_ORDER')}
        </Button.Brand>

        <p className="page-bottom-spacer" />
      </Section>
    )
  }
}

export default withTranslation()(OrderForm)