import { combineReducers } from 'redux'
import cart from './cart'
import internationalization from './internationalization'
import menu from './menu'
import menuItem from './menuItem'
import modifierGroup from './modifierGroup'
import modifierProperties from './modifierProperties'
import order from './order'
import orderTotal from './orderTotal'
import stand from './stand'
import user from './user'
import userItem from './experiences/userItem'
import seat from './seat'
import meta from './meta'
import remote from './remote'
import filter from './filter'
import event from './event'

export default combineReducers({
  cart,
  internationalization,
  menu,
  menuItem,
  modifierGroup,
  modifierProperties,
  order,
  orderTotal,
  stand,
  user,
  userItem,
  seat,
  meta,
  remote,
  filter,
  event,
})
