var ESData = {
  translation: {
    CURRENCY: "EUR",
    CURRENCY_SYMBOL: "€",
    CUSTOM_TIP_AMOUNT: "Introduzca la propina deseada.",
    OK: "OK",
    AMOUNT: "Cantidad",
    creditCard: {
      number: {
        displayText: "Número de Tarjeta",
      },
      expirationDate: {
        displayText: "Fecha de Caducidad",
      },
      cvv: {
        displayText: "Numero de Seguridad",
      },
      postalCode: {
        displayText: "Código Postal",
      },
    },
    LOG_IN: 'Acceder',
    REPORT_AN_ISSUE: 'Reportar un Problema',
    TERMS_OF_SERVICE: 'Términos de Servicio',
    PRIVACY_POLICY: 'Política de Privacidad',
    HOME: 'Inicio',
    MY_ACCOUNT: 'Mi cuenta',
    ORDERS: 'Pedidos',
    SIGN_OUT: 'Cerrar Sesión',
    PASSES: 'Entradas',
    PAYMENTS: 'Pagos',
    MULTIPLIER: 'multiplicador',
    LOADING_PASS: 'Cargando entrada',
    ORDER_NEXT: 'OrderNext',
    APPLE_PAY_FAILED_TO_LOAD: 'Apple Pay no pudo cargarse. Intente usar una tarjeta de crédito.',
    COULD_NOT_COMPLETE_PURCHASE_CARD_ON_FILE: "No pudimos completar su compra con la tarjeta registrada en su cuenta de Apple Pay. Intente usar una tarjeta de crédito.",
    IS_REQUIRED: 'es obligatorio',
    INVALID_VALUE_FOR: 'Valor no válido para',
    REQUIRED: 'Obligatorio',
    PAYMENT_PROCESSOR_CONNECTION_ERROR: "Tenemos problemas para conectarnos a nuestro procesador de pagos.",
    RECIPIENT_NAME: 'Nombre del destinatario',
    NAME_REQUIRED: 'Se requiere el nombre',
    PLACE_ORDER: 'Realizar pedido',
    TIP: 'Propina',
    SERVICE_FEE: 'Tarifa de servicio',
    SERVICE_CHARGES: 'Cargos de servicio',
    DISCOUNT: 'Descuento',
    TAXES: 'Impuestos',
    TOTAL: 'Total',
    DISMISS: 'Descartar',
    ARE_YOU_SURE: 'Está seguro',
    VIEW_CART: 'ver carrito',
    EMAIL_RECEIPT: 'Recibo en su correo clectrónico',
    EMAIL_IS_REQUIRED: 'Email obligatorio',
    CREATING_YOUR_ORDER: 'creando su pedido',
    GETTING_ORDER_INFO: 'obteniendo la información de su pedido',
    CALCULATING_ORDER_TOTAL: 'calculando el total de su pedido',
    THERE_WAS_AN_ERROR: 'Hubo un error',
    GET_ORDER_ERROR: "Si actualizar esta página no ayuda, puede usar el número de pedido en su recibo enviado al correo electrónico para recoger su pedido.",
    GET_ORDER_TOTAL_ERROR: 'Esto puede ser temporal. Puede intentar actualizar la página antes de realizar su pedido.',
    BRAINTREE_LOAD_ERROR: "Tenemos problemas para conectarnos al procesador de pagos. Si el problema persiste después de actualizar la página, no podremos procesar su pedido. Hemos sido notificados de este problema y le pedimos disculpas por las molestias.",
    DATE: 'fecha',
    EVENTS_IN: 'eventos en',
    BACK: 'Atrás',
    BACK_LOWERCASE: 'atrás',
    SELECT_AN_EVENT: 'seleccione un evento',
    ALL_EVENTS: 'todos los eventos',
    REDEEM_SOON: 'CANJEAR PRONTO',
    REDEEM_NOW: 'CANJEAR AHORA',
    REDEEMED: 'CANJEADO',
    EXPIRED: 'CADUCADO',
    REDEEMING: 'CANJEANDO',
    INFO: 'información',
    EACH: 'unidad',
    ONLY: 'solamente',
    LEFT: '¡quedan',
    REMOVE: 'eliminar',
    REQUIRED_LOWERCASE: 'obligatorio',
    PICK_AT_LEAST: 'elegir al menos',
    PICK_UP_TO: 'recoger hasta',
    OPTIONAL: 'Opcional',
    QR_CODE: 'Código QR',
    DELIVERY_TO: 'Entregar a',
    PICKUP_AT: 'Recoger en',
    SECTION: 'Sección',
    ROW: 'Fila',
    SEAT: 'Asiento',
    SUITE: 'Palco',
    REMOVE_ALL_ITEMS: 'Esto eliminará todos los artículos de su pedido actual.',
    THIS_WILL_REMOVE: 'Esto eliminará',
    FROM_YOUR_CART: 'de su carrito',
    EVENT_DATE: 'Fecha del evento',
    EVENT: 'Evento',
    ITEM: 'Articulo',
    ITEMS: 'Artículos',
    CART: 'Carro',
    EXPERIENCE: 'experiencia',
    MENU: 'menú',
    CLEAR_CART: 'vaciar carrito',
    ADD_ITEMS: 'agregar artículos',
    NOT_NEEDED_FOR_SUITES: 'No es necesario para palcos',
    LOADING: 'cargando',
    SELECT_YOUR_SECTION_OR_SUITE: 'Seleccione su sección o palco',
    SELECT_YOUR_ROW: 'Seleccione su fila',
    SELECT_YOUR_SEAT: 'Seleccione su asiento',
    SECTION_LOWERCASE: 'sección',
    OR: 'o',
    SUITE_LOWERCASE: 'palco',
    CONFIRM_SEAT: 'Confirmar asiento',
    SAVE_SEAT: 'Guardar asiento',
    VALIDATING_SEAT: 'Validando asiento',
    YOUR_SEAT: 'Su asiento',
    SEAT_NOT_ELIGIBLE_FOR_DELIVERY_FROM_THIS_STAND: 'Asiento no elegible para entrega desde esta ubicación',
    MODIFY: 'Modificar',
    YOU_CAN_EDIT_THESE_UNTIL_YOU_REDEEM: 'Puede editarlos hasta que canjee',
    PLEASE_FILL_IN_THE_ABOVE: 'Por favor complete lo anterior.',
    PLEASE_ADD_YOUR_SEAT: 'Por favor agregue su asiento.',
    EDIT_THESE_AFTER_PLACING_ORDER: 'Puede editarlos después de realizar su pedido.',
    ENTER_YOUR: 'Introduzca su',
    DONE: 'TERMINADO',
    NEXT: 'SIGUIENTE',
    WHEN_YOU_ARE_READY_TO_REDEEM: "Cuando esté listo para canjear, pulso el botón canjear ahora y luego presente el",
    CONFIRMATION_SCREEN: 'pantalla de confirmación',
    SECOND_CONFIRMATION_SCREEN: 'segunda confirmación',
    HOW_DO_I_REDEEM: '¿Cómo canjeo?',
    HOW_DO_I_REDEEM_CAPS: '¿Cómo canjeo?',
    EDIT: 'editar',
    YOU_CAN_ONLY_ORDER_FOR_ONE_EVENT: 'Solo puede reservar un evento a la vez',
    MAX_QUANTITY_REACHED: 'cantidad máxima alcanzada',
    ORDER_NOW: 'pedir ahora',
    EMAIL_MY_RECEIPT: 'Enviar mi recibo por correo electrónico',
    ORDER: 'Compra',
    YOU_ARE_ABOUT_TO_REDEEM: "Está a punto de canjear su experiencia y tendrá que presentar la confirmación. Esto no se puede deshacer, así que asegúrese de estar listo.",
    AT: 'a',
    ON: 'en',
    BETWEEN: 'entre',
    AND: 'y',
    WHAT_HAPPENS_NEXT: 'QUÉ SUCEDE DESPUÉS',
    KEEP_A_LOOK_OUT_FOR_FURTHER_INSTRUCTIONS: 'Esté atento a más instrucciones sobre su compra.',
    THIS_EXPERIENCE_WILL_BE_AVAILABLE_TO_REDEEM: 'Esta experiencia estará disponible para canjear',
    THIS_EXPERIENCE_EXPIRED_ON: 'Esta experiencia expiró el',
    THIS_EXPERIENCE_WAS_REDEEMED_ON: 'Esta experiencia fue canjeada el',
    ORDER_NUMBER: 'Número de pedido',
    ORDER_DETAILS: 'Detalles del pedido',
    PLACED_ON: 'Colocado en',
    RECEIPT: 'Recibo',
    SUBTOTAL: 'Total parcial',
    NOTHING_FOR_SALE: "No hay nada para esta fecha",
    PLEASE_TRY_ANOTHER_EVENT: 'Intente con otro evento o vuelva a consultar pronto para ver si hay algo disponible para este.',
    ONLY_CAPS_START: 'Solamente',
    OF_THIS_ITEM_PER_ORDER_ALLOWED: 'de este artículo permitido por pedido',
    SELECT_ONE: 'seleccione uno',
    CONFIRM: 'CONFIRMAR',
    ADD_TO_CART: 'añadir al carrito',
    MUST_BE_21: 'Debe ser mayor de 18 años con una identificación válida para pedir alcohol',
    NO_ITEMS_AVAILABLE_IN_THIS_CATEGORY: 'No hay artículos disponibles en esta categoría',
    LOCATION_NOT_CURRENTLY_ACCEPTING_ORDERS: 'Esta ubicación no acepta pedidos actualmente',
    SENDING: 'ENVIANDO',
    EMAIL_SENT: 'EMAIL ENVIADO',
    FAILED_TRY_AGAIN: 'FALLIDO: POR FAVOR INTÉNTELO OTRA VEZ',
    KIOSK_ORDER: 'Pedido de quiosco',
    SCAN_THIS_CODE: 'Escanee este código cuando recoja su pedido en',
    PRESENT_THIS_PAGE: 'Presente esta página cuando recoja su pedido en',
    SHOW_THIS_SCREEN_TO_RECEIVE_YOUR_ORDER: 'Muestra esta pantalla para recibir tu pedido.',
    HANG_TIGHT: "Espere - estamos revisando su pedido hasta que estemos seguros de que ha llegado a la cocina",
    ONCE_IT_DOES: "Una vez que lo haga, verá un código QR aquí que puede usar para recoger su pedido",
    WE_HOPE_YOU_ENJOYED_YOUR_ORDER: 'Esperamos que haya disfrutado su pedido, ¡Gracias de nuevo por probar OrderNext!',
    SEND: 'ENVIAR',
    MOBILE_ORDERING_MENUS: 'Menús para pedidos con el móvil',
    ALMOST_DONE: 'casi terminado',
    WE_NEED_SOME_MORE_INFO: 'necesitamos más información',
    ONE_LAST_THING: 'una última cosa',
    EXPERIENCES: 'Experiencias',
    FOOD_AND_DRINKS: 'Comida y bebidas',
    MERCHANDISE: 'Mercancías',
    OPEN_ORDER: 'Pedido abierto',
    PREPARING_YOUR_ORDER: 'Preparando su pedido',
    FREE: 'GRATIS',
  }
}

export default ESData
