import { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { updateUserLocation } from '../actions/user'
import { setProductTypeFilter } from '../actions/filter'

export class QueryParamsHandler extends Component {
  componentDidMount() {
    this.interceptUserLocation()
    this.interceptStandsFilter()
  }

  getQueryParams = () => queryString.parse(this.props.location.search)

  interceptUserLocation() {
    const { updateUserLocation } = this.props
    const { section, row, seat, sectionIsSuite, orderLocationUuid } = this.getQueryParams()

    if (!section && !row && !seat) return

    updateUserLocation({ section, row, seat, sectionIsSuite, orderLocationUuid })
  }

  interceptStandsFilter() {
    const { setProductTypeFilter } = this.props
    const { productType } = this.getQueryParams()
    const productTypes = ['Experience', 'Food', 'Merchandise']

    if (productTypes.includes(productType)) setProductTypeFilter(productType)
  }

  render = () => null
}

function mapDispatchToProps(dispatch) {
  return {
    updateUserLocation: (args) => dispatch(updateUserLocation(args)),
    setProductTypeFilter: (productTypeFilter) => dispatch(setProductTypeFilter(productTypeFilter)),
  }
}

export default connect(undefined, mapDispatchToProps)(QueryParamsHandler)
