import { call, put, takeLatest, all } from 'redux-saga/effects'

import Remote from '../remote'
import { setLoading, setFailed, setSucceeded } from '../actions/remote'
import { loadMenuSucceeded, loadMenuFailed, MENU_REQUESTED } from '../actions/menu'

export function* fetchMenu(params) {
  const endpoint = Remote.endpoints.getMenu
  yield put(setLoading(endpoint))

  try {
    const result = yield call(Remote.getMenu, params)

    yield all([
      put(loadMenuSucceeded(result)),
      put(setSucceeded(endpoint, true)),
    ])
  } catch (err) {
    yield all([
      put(loadMenuFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchFetchMenu() {
  yield takeLatest(MENU_REQUESTED, fetchMenu)
}
