import humps from  'humps'
import { get, keys } from 'lodash'

export function orderTotalMapper(items, menuId) {
  const itemsArray = keys(items).map((key) => {
    const uuid = items[key].id
    const qty = items[key].quantity

    const mods = items[key].modifiers.map((modGroup) => {
      return modGroup.map((mod) => {
        return {
          menu_item_uuid: mod.id,
          quantity: get(mod, 'quantity', 1) // no quantities yet, always 1 for now
        }
      })
    })

    return { menu_item_uuid: uuid, quantity: qty, modifiers: mods }
  })

  const orderTotalObject = {
    orderMenuItems: itemsArray,
    standMenuUuid: menuId,
  }

  return humps.decamelizeKeys(orderTotalObject)
}

export default orderTotalMapper
