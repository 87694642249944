import { call, put, all, select, takeLatest } from 'redux-saga/effects'

import Remote from '../remote'
import { REMOVE_ITEM_FROM_CART } from '../actions/cart'
import { setLoading, setSucceeded, setFailed } from '../actions/remote'
import { makeGetCartMenuItems } from '../selectors/cart'
import { orderTotalMapper } from '../mappers/orderTotal'
import { experienceOrderTotalMapper } from '../mappers/experiences/orderTotal'

import {
  experienceOrderTotalSucceeded,
  experienceOrderTotalFailed,
  EXPERIENCE_ORDER_TOTAL_REQUESTED,
  orderTotalSucceeded,
  orderTotalFailed,
  ORDER_TOTAL_REQUESTED,
} from '../actions/orderTotal'

export function* orderTotal() {
  const getCartMenuItems = makeGetCartMenuItems()
  const items = yield select(getCartMenuItems)
  const menuId = yield select(state => state.cart.menuId)
  const orderTotalParams = orderTotalMapper(items, menuId)
  const endpoint = Remote.endpoints.orderTotal
  yield put(setLoading(endpoint))

  try {
    const result = yield call(Remote.orderTotal, orderTotalParams)
    yield all([
      put(orderTotalSucceeded(result)),
      put(setSucceeded(endpoint)),
    ])
  } catch (err) {
    yield all([
      put(orderTotalFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* experienceOrderTotal() {
  const getCartMenuItems = makeGetCartMenuItems()
  const items = yield select(getCartMenuItems)
  const params = experienceOrderTotalMapper(items)

  const endpoint = Remote.endpoints.experienceOrderTotal
  yield put(setLoading(endpoint))

  try {
    const result = yield call(Remote.experienceOrderTotal, params)

    yield all([
      put(experienceOrderTotalSucceeded(result)),
      put(setSucceeded(endpoint)),
    ])
  } catch (err) {
    yield all([
      put(experienceOrderTotalFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchExperienceOrderTotal() {
  yield takeLatest([EXPERIENCE_ORDER_TOTAL_REQUESTED], experienceOrderTotal)
}

export function* watchOrderTotal() {
  yield takeLatest([REMOVE_ITEM_FROM_CART, ORDER_TOTAL_REQUESTED], orderTotal)
}
