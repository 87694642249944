import includes from 'lodash/includes'

export default class OrderStates {
  static finalOrderStates = [
    'completed',
    'cancelled',
    'refunded',
    'partially_refunded',
    'completion_pending',
    'delivered_without_alcohol',
    'delivery_failed',
    'not_collected',
    'problem_reported',
  ]

  static isCompleted = (state) => includes(OrderStates.finalOrderStates, state)
}
