import React from 'react'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'

import SegmentedControl from './SegmentedControl'

import './MenuHeader.scss'

const MenuHeader = ({ section, name, loading, image, description, services, selectedService, onServiceChange }) => (
  <div className={classNames('menu-header', { loading })}>
    <div className="header">
      <h2>{loading ? <Skeleton width='50%' /> : name}</h2>
      <SegmentedControl
        segments={services}
        selected={selectedService}
        onChange={onServiceChange}
      />
    </div>
    {loading && <div className="image"><Skeleton height='95%' /></div>}
    {!loading && image && <div className="image" style={{ backgroundImage: `url(${image})` }} alt={name} />}
    {loading ? <Skeleton width='75%' /> : description && <p className="description">{description}</p>}
  </div>
)

export default MenuHeader
