import { values, groupBy, head, keys, sortBy } from 'lodash'

function eventsMapper(events) {
  const eventsData = []
  let eventsArray = values(events)
  eventsArray = sortBy(eventsArray, (e) => new Date(`${e.date} ${e.time}`))
  const grouped = groupBy(eventsArray, (event) => head(/[0-9]{4}-[0-9]{2}/.exec(event.date)))

  keys(grouped).forEach((eventMonth, eventMonthIndex) => {
    const groupedEvents = values(groupBy(grouped[eventMonth], 'date'))
    eventsData.push({ id: eventMonthIndex, date: head(grouped[eventMonth]).date, days: [] })

    groupedEvents.forEach((eventDay, eventDayIndex) => {
      eventsData[eventMonthIndex].days.push({ id: eventDayIndex, date: head(eventDay).date, events: eventDay })
    })
  })

  const sortedEvents = eventsData.sort((a, b) => new Date(a.date) - new Date(b.date))

  return sortedEvents
}

export default eventsMapper
