import React from 'react'
import { isArray, isEmpty } from 'lodash'
import classNames from 'classnames'

import './SegmentedControl.scss'

const SegmentedControl = ({ segments, selected, onChange = () => {} }) => {
  if (isEmpty(segments) || !isArray(segments)) return null

  return (
    <div className="segmented-control">
      {segments.map((segment) => (
        <span
          key={segment.id}
          className={classNames('segment', { selected: selected === segment.id })}
          onClick={() => onChange(segment)}
        >
          {segment.label}
        </span>
      ))}
    </div>
  )
}

export default SegmentedControl
