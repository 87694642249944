export const SET_PRODUCT_TYPE_FILTER = 'SET_PRODUCT_TYPE_FILTER'
export const SET_EVENT_FILTER = 'SET_EVENT_FILTER'

export const setProductTypeFilter = (productTypeFilter) => ({
  type: SET_PRODUCT_TYPE_FILTER,
  productTypeFilter,
})

export const setEventFilter = (eventFilter) => ({
  type: SET_EVENT_FILTER,
  eventFilter,
})
