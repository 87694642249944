import { createSelector } from 'reselect'
import { filter, get, pick, some, values } from 'lodash'

import { getItems } from './item'

export const getMenus = (state) => get(state, 'menu.rvcMenus', [])

const getMenu = (state, id) => state.menu[id] || {}

export const makeGetMenu = () => (
  createSelector([getMenu], (menu) => menu)
)

const getMenuItems = createSelector(
  [getMenu, getItems],
  (menu, items) => {
    const itemIds = get(menu, 'menuItemIds', [])

    let result = pick(items, itemIds)
    if (menu.serviceType !== 'Experience') return result

    return filter(result, (item) => {
      return some(item.variants, (variant) => variant.saleId)
    })
  }
)

export const makeGetMenuItems = () => {
  return createSelector([getMenuItems], (menuItems) => menuItems)
}

const getFilteredExperiences = createSelector(
  [(state) => get(state, 'filter.eventFilter', undefined), getMenuItems],
  (eventFilter, menuItems) => {
    if (eventFilter === undefined) {
      const result = values(menuItems).reduce((accumulator, experience) => {
        const variant = get(experience, 'variants[0]', {})

        return {
          ...accumulator,
          [experience.uuid]: {
            ...experience,
            price: variant.price,
            onHandQuantity: variant.onHandQuantity,
            eventUuid: variant.eventUuid,
            purchaseLimit: variant.purchaseLimit,
          },
        }
      }, {})

      return result
    }

    const filteredExperiences = values(menuItems).filter((menuItem) => {
      const menuItemEvents = get(menuItem, 'variants', [])

      return some(menuItemEvents, (item) => {
        return item.saleId && item.eventUuid === eventFilter
      })
    })

    const result = filteredExperiences.reduce((accumulator, experience) => {
      const variant = experience.variants.find(variant => variant.eventUuid === eventFilter)

      return {
        ...accumulator,
        [experience.uuid]: {
          ...experience,
          price: variant.price,
          onHandQuantity: variant.onHandQuantity,
          eventUuid: variant.eventUuid,
          purchaseLimit: variant.purchaseLimit,
        },
      }
    }, {})

    return result
  }
)

export const makeGetFilteredExperiences = () => {
  return createSelector([getFilteredExperiences], (menuItems) => menuItems)
}

const getServiceFeeItems = createSelector(
  [getMenu],
  (menu) => {
    return get(menu, 'serviceFeeItems', [])
  }
)

export const makeGetServiceFeeItems = () => {
  return createSelector([getServiceFeeItems], (menuItems) => menuItems)
}

const getMenuCategories = createSelector(
  [getMenu],
  (menu) => get(menu, 'categories', [])
)

export const makeGetMenuCategories = () => {
  return createSelector([getMenuCategories], (categories) => categories)
}
