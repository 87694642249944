import { SET_PRODUCT_TYPE_FILTER, SET_EVENT_FILTER } from '../actions/filter'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PRODUCT_TYPE_FILTER:
      return {
        ...state,
        productTypeFilter: action.productTypeFilter,
      }

    case SET_EVENT_FILTER:
      return {
        ...state,
        eventFilter: action.eventFilter,
      }

    default:
      return state
  }
}

export default reducer
