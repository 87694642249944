import React, { Component } from 'react'
import PropTypes from 'prop-types'

import NavigationBar from '../components/NavigationBar'
import Section from  '../components/Section'
import EventList from '../components/EventList'
import { withTranslation } from 'react-i18next'

import './Events.scss'

export class Events extends Component {
  static defaultProps = {
    backButtonText: null,
    backButtonRoute: '/',
    events: [],
    selectedEvent: undefined,
    allEventsToggle: false,
    didSelectEvent: () => {},
  }

  render() {
    const { backButtonText, backButtonRoute, events, selectedEvent, didSelectEvent, allEventsToggle, t } = this.props

    const pBackButtonText = backButtonText ? backButtonText : t('BACK')

    return (
      <div className="events">
        <NavigationBar.Back text={pBackButtonText} linkTo={backButtonRoute} />
        <Section>
          <h1>{t('SELECT_AN_EVENT')}</h1>
          {allEventsToggle && <div className="all-events-toggle">
            <label htmlFor="all">{t('ALL_EVENTS')}</label>
            <input
              type="checkbox"
              checked={selectedEvent === undefined}
              onChange={() => didSelectEvent(undefined)}
            />
            <span className="checkbox"></span>
          </div>}
        </Section>
        <EventList
          data={events}
          onEventSelected={didSelectEvent}
          selected={selectedEvent}
        />
      </div>
    )
  }
}

Events.propTypes = {
  backButtonText: PropTypes.string,
  backButtonRoute: PropTypes.string,
  events: PropTypes.array,
  selectedEvent: PropTypes.string,
  didSelectEvent: PropTypes.func,
  allEventsToggle: PropTypes.bool,

}

export default withTranslation()(Events)
