import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import watchAll from '../sagas'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import analyticsMiddleware from '../middleware/analyticsMiddleware'

const org = process.env.REACT_APP_ORG || 'default'
const persistConfig = {
  key: `${org}:root`,
  storage,
  blacklist: ['remote', 'filter'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore (initialState) {
  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

  const middleware = composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      analyticsMiddleware,
    )
  )

  const store = createStore(
    persistedReducer,
    initialState,
    middleware,
  )

  const persistor = persistStore(store)

  sagaMiddleware.run(watchAll)

  return { store, persistor }
}
