import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import last from 'lodash/last'
import toString from 'lodash/toString'
import isEmpty from 'lodash/isEmpty'

import scrollTo from '../utils/scrollTo'

import memoize from 'memoize-one';

const scrollableType = {
  component: 'component',
  window: 'window',
}

const directionType = {
  horizontal: 'horizontal',
  vertical: 'vertical',
}

const domQueryType = {
  identifier: 'identifier',
  index: 'index',
}

class Scrollable extends Component {
  signature = Date.now()
  memoizedDataOffsets = memoize((data, keyIdentifier, direction) => {
    return this.generateDataOffsets({ data, keyIdentifier, direction });
  });

  state = {
    currentItem: {},
    delayCallbacks: false,
  }

  static defaultProps = {
    data: [],
    renderItem: () => {},
    keyIdentifier: undefined,
    type: scrollableType.window,
    direction: directionType.vertical,
    onCurrentItemDidChange: () => {},
    globalOffset: 0,
    animateDuration: 800,
    containerProps: {},
  }

  onScroll = this.onScroll.bind(this)

  get frame() {
    const { type } = this.props

    if (scrollableType.window === type) {
      return window
    } else {
      return ReactDOM.findDOMNode(this)
    }
  }

  get frameOffsetAttribute() {
    const { direction, type } = this.props

    if (scrollableType.window === type) {
      const attribute = direction === directionType.vertical ? 'scrollY' : 'scrollX'
      return attribute
    } else {
      const attribute = direction === directionType.vertical ? 'scrollTop' : 'scrollLeft'
      return attribute
    }
  }

  get frameOffset() {
    return this.frame[this.frameOffsetAttribute]
  }

  domQuery(type, val) {
    const query = `[data-scroll-${type}="${this.signature}-${val}"]`
    const element = document.querySelector(query)

    if (element === null) {
      console.error('The element could not be found in the DOM')
      return
    }

    return element
  }

  get frameEnd() {
    const { direction, type } = this.props
    const context = scrollableType.window === type ? document.body : ReactDOM.findDOMNode(this)
    const attribute = direction === directionType.vertical ? 'scrollHeight' : 'scrollWidth'

    return context[attribute]
  }

  get frameCenter() {
    const { direction, type } = this.props

    const context = this.frame
    let attribute = direction === directionType.vertical ? 'innerHeight' : 'innerWidth'

    if (type === scrollableType.component) {
      attribute = direction === directionType.vertical ? 'clientHeight' : 'clientWidth'
    }

    const targetCenter = this.frameEnd / 2
    const frameCenter = context[attribute] / 2

    return targetCenter - frameCenter
  }

  scrollToStart() {
    this.scrollTo(0, false)
  }

  scrollToEnd() {
    this.scrollTo(this.frameEnd, false)
  }

  scrollToCenter() {
    this.scrollTo(this.frameCenter, false)
  }

  findTargetTop(element, offset = 0) {
    const { globalOffset } = this.props

    const elementStyles = window.getComputedStyle(element)
    const elementMargin = parseFloat(elementStyles.marginTop)
    const elementPadding = parseFloat(elementStyles.paddingTop)
    const targetPosition = this.frameOffset + element.getBoundingClientRect().top + offset + elementMargin + elementPadding - globalOffset

    return targetPosition
  }

  findTargetLeft(element, offset = 0) {
    const { globalOffset } = this.props

    const elementStyles = window.getComputedStyle(element)
    const elementMargin = parseFloat(elementStyles.marginLeft)
    const elementPadding = parseFloat(elementStyles.paddingLeft)
    const targetPosition = this.frameOffset + element.getBoundingClientRect().left + offset + elementMargin + elementPadding - globalOffset

    return targetPosition
  }

  findCurrentItem() {
    const { globalOffset } = this.props
    const newCurrentItem = last(this.dataOffsets().filter((item) => {
      return this.frameOffset + globalOffset >= item.offset
    }))

    return newCurrentItem || this.dataOffsets()[0] || { id: 'Foo', index: 99, offset: 0 }
  }

  scrollTo(position, callbackDelayEnable = true) {
    const { direction, animateDuration } = this.props

    if (this.props.type === scrollableType.window) {
      scrollTo(position, animateDuration, undefined, direction)
    } else {
      const self = ReactDOM.findDOMNode(this)
      scrollTo(position, animateDuration, self, direction)
    }

    if (callbackDelayEnable) {
      this.setState({
        delayCallbacks: true,
      })
    }
  }

  scrollToIndex({ index, offset = 0 }) {
    if (index === undefined) {
      console.error('Index value not provided.')
      return
    }

    const { direction } = this.props
    const element = this.domQuery(domQueryType.index, index)
    let targetPosition = this.findTargetTop(element, offset)

    if (direction === directionType.horizontal) {
      targetPosition = this.findTargetLeft(element, offset)
    }

    this.scrollTo(targetPosition)
  }

  scrollToItem({ item, offset = 0 }) {
    if (item === undefined) {
      console.error('Item object not provided.')
      return
    }

    const { keyIdentifier, direction } = this.props

    if (keyIdentifier === undefined) {
      console.error('keyIdentifier is a required prop when using scrollToItem.')
      return
    }

    if (item[keyIdentifier] === undefined) {
      console.error('The item could not be found on object\n\n', item, `\n\nusing the key identifier "${keyIdentifier}"`)
    }

    const element = this.domQuery(domQueryType.identifier, item[keyIdentifier])
    let targetPosition = this.findTargetTop(element, offset)

    if (direction === directionType.horizontal) {
      targetPosition = this.findTargetLeft(element, offset)
    }

    this.scrollTo(targetPosition)
  }

  onScroll(event) {
    const { data, onCurrentItemDidChange, keyIdentifier } = this.props
    const { delayCallbacks } = this.state
    let currentItem = this.state.currentItem || {}

    const newCurrentItem = this.findCurrentItem()
    let newMatchesCurrent = false
    if (keyIdentifier) {
      newMatchesCurrent = toString(newCurrentItem.id) === toString(currentItem.id)
    } else {
      newMatchesCurrent = newCurrentItem.id === currentItem.id
    }

    if (isFunction(onCurrentItemDidChange) && !newMatchesCurrent) {
      window.clearTimeout(this.scrollTimeout)

      this.scrollTimeout = setTimeout(() => {
        this.setState({
          delayCallbacks: false,
          currentItem: newCurrentItem,
        }, () => {
          if (!delayCallbacks) {
            onCurrentItemDidChange(data.find(item => {
              if (keyIdentifier) {
                return toString(newCurrentItem.id) === toString(item.id)
              } else {
                return newCurrentItem.id === item
              }
            }), newCurrentItem.index)
          }
        })
      }, delayCallbacks ? 100 : 0)
    }
  }

  componentDidMount() {
    const { data, onCurrentItemDidChange, keyIdentifier } = this.props
    this.frame.addEventListener('scroll', this.onScroll, false)

    this.setState({
      currentItem: this.dataOffsets()[0],
    }, () => {
      if (isEmpty(data)) return

      const newCurrentItem = this.findCurrentItem()

      onCurrentItemDidChange(data.find(item => {
        if (keyIdentifier) {
          return toString(newCurrentItem.id) === toString(item.id)
        } else {
          return newCurrentItem.id === item
        }
      }), newCurrentItem.index)
    })
  }

  dataOffsets = () => {
    return this.memoizedDataOffsets(this.props.data, this.props.keyIdentifier, this.props.direction);
  }

  generateDataOffsets = ({ data, keyIdentifier, direction }) => {
    return data.map((item, index) => {
      const queryType = keyIdentifier === undefined ? domQueryType.index : domQueryType.identifier
      const id = keyIdentifier === undefined ? item : item.id;
      const identifier = keyIdentifier === undefined ? index : item[keyIdentifier]
      const element = this.domQuery(queryType, identifier)

      const elementOffset = direction === directionType.vertical ? 'offsetTop' : 'offsetLeft'

      return { id, index, offset: element[elementOffset] }
    })
  }

  componentWillUnmount() {
    this.frame.removeEventListener('scroll', this.onScroll, false);
  }

  render () {
    const { renderItem, data, keyIdentifier, type, containerProps } = this.props
    const mergedContainerProps = {
      ...containerProps,
      style: {
        overflow: type === scrollableType.window ? 'auto' : 'scroll',
        ...containerProps.style,
      }
    }

    const ContainerType = this.props.containerType || 'div'

    return (
      <ContainerType {...mergedContainerProps}>
        {data.map((item, index) => {
          const itemWithProps = React.cloneElement(renderItem(item, index), {
            'data-scroll-identifier': `${this.signature}-${item[keyIdentifier] || index}`,
            'data-scroll-index': `${this.signature}-${index}`,
          })

          return itemWithProps
        })}
      </ContainerType>
    )
  }
}

Scrollable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  renderItem: PropTypes.func.isRequired,
  keyIdentifier: PropTypes.string,
  type: PropTypes.oneOf([scrollableType.window, scrollableType.component]),
  direction: PropTypes.oneOf([directionType.horizontal, directionType.vertical]),
  onCurrentItemDidChange: PropTypes.func,
  globalOffset: PropTypes.number,
  animateDuration: PropTypes.number,
  containerProps: PropTypes.object
}

export default Scrollable
