import { STANDS_SUCCEEDED } from '../actions/stand'
import { MENU_SUCCEEDED, MENU_FAILED } from '../actions/menu'

import humps from 'humps'
import { keys } from 'lodash'
import DataMapper from '../utils/dataMapper'

const defaultState = { rvcMenus: [] }

function reducer(state = defaultState, action) {
  switch (action.type) {
    case STANDS_SUCCEEDED:
      return {
        ...state,
        rvcMenus: humps.camelizeKeys(action.payload.menus),
      }

    case MENU_SUCCEEDED:
      const { categories, items, menu, modifier_categories, service_fee_items } = action.payload

      const combinedMenu = {
        categories,
        ...menu,
        menuItemIds: (items || []).map(x => x.uuid),
        modifier_categories,
      }

      // don't want to camelize the uuid keys here
      let serviceFees = DataMapper.objectsById(service_fee_items)
      keys(serviceFees).forEach((key) => {
        serviceFees[key] = humps.camelizeKeys(serviceFees[key])
      })

      return {
        ...state,
        [menu.uuid]: {
          ...humps.camelizeKeys(combinedMenu),
          serviceFeeItems: serviceFees,
        }
      }

    case MENU_FAILED:
      return {
        ...state
      }

    default:
      return state
  }
}

export default reducer
