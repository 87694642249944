import { createSelector } from 'reselect'
import get from 'lodash/get'

import { HIDE_MERCH_STANDS } from '../constants.js'

export const getAvailableProductTypes = (state, t) => createSelector(
  (state) => get(state, 'stand.rvcs', []),
  (stands) => {

    const labels = {
      'Experience': t('EXPERIENCES'),
      'Food': t('FOOD_AND_DRINKS'),
      'Merchandise': t('MERCHANDISE'),
    }

    const productTypes = stands.map((stand) => stand.productType)
    const filteredProductTypes = [...new Set(productTypes)]

    if (filteredProductTypes.length <= 1) return undefined

    const availableProductTypes = filteredProductTypes.map((productType) => {
      const label = labels[productType]

      if (!label) return undefined

      if (HIDE_MERCH_STANDS && label === 'Merchandise') return undefined

      return { id: productType, label }
    })

    return availableProductTypes.filter((productType) => productType !== undefined)
  }
)(state)
