import React, { Component } from 'react'
import classNames from 'classnames'
import moment from 'moment-timezone'

export default class ReceiptCounter extends Component {
  state = {
    hourCounter: null,
  }

  componentDidMount() {
    this.startCounting()
  }

  componentWillUnmount() {
    this.stopCounting()
  }

  startCounting = () => {
    clearInterval(this.state.minuteCounter)

    this.setState({
      minuteCounter: setInterval(this.updateCounter, 1000),
    })
  }

  stopCounting = () => {
    clearInterval(this.state.minuteCounter)
    this.setState({ minuteCounter: null })
  }

  updateCounter = () => {
    const { startTime } = this.props
    const now = moment()
    const diff = now.diff(startTime)
    const hours = moment.duration(diff).asHours()
    let hourCounter = moment.utc(diff).format('mm:ss')

    if (hours >= 1) {
      clearInterval(this.state.minuteCounter)
      hourCounter = moment.duration(-diff).humanize(true)

      this.setState({ hourCounter, minuteCounter: null })
      return
    }

    this.setState({ hourCounter })
  }

  render() {
    const { active, bannerChangeHandler, className } = this.props
    const { hourCounter } = this.state

    return(
      <div className={classNames(className, { active })} onClick={bannerChangeHandler}>
        <div className="banner">
          <div className="timer">
            <p>{hourCounter}</p>
          </div>
          <div className="icon">
            <div className="inactive"></div>
            <div className="active"></div>
          </div>
          <div className="filler"></div>
        </div>
      </div>
    )
  }
}
