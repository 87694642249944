import get from 'lodash/get'
import isObject from 'lodash/isObject'
import isFunction from 'lodash/isFunction'

function injectPredicate(targetClass, predicate) {
  if (!isObject(targetClass)) throw Error(`Expected targetClass to be of type Object, but received ${get(targetClass, 'constructor.name', targetClass)}`)
  if (!isFunction(predicate)) throw Error(`Expected predicate to be of type Function, but received ${get(predicate, 'constructor.name', predicate)}`)

  const clone = {}

  for (const key of Object.getOwnPropertyNames(targetClass)) {
    const classMethod = targetClass[key]

    clone[key] = (...args) => {
      if (!predicate()) return
      classMethod.call(this, ...args)
    }
  }

  return clone
}

export default injectPredicate
