import get from 'lodash/get'

import { SET_LOADING, SET_FAILED, SET_SUCCEEDED } from '../actions/remote'

function reducer(state = {}, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: get(state, `${action.key}.cached`, false) ? false : true,
          failed: false,
          errors: undefined,
        }
      }

    case SET_FAILED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false,
          failed: true,
          cached: false,
          errors: action.errors,
        }
      }

    case SET_SUCCEEDED:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: false,
          failed: false,
          cached: action.cache,
          errors: undefined,
        }
      }

    default:
      return state
  }
}

export default reducer
