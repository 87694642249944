import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import isEmpty from 'lodash/isEmpty'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

// Need this to import the specific locales you want to load
// eslint-disable-next-line no-unused-vars
import 'moment/locale/es'

import Title from '../utils/titleGenerator'

import Stands from './Stands'
import Menu from './Menu'
import Experiences from './Experiences'
import ExperienceModal from './ExperienceModal'
import ExperienceCheckout from  './ExperienceCheckout'
import EventsFilter from './EventsFilter'
import ExperienceEvents from './ExperienceEvents'
import ExperienceOrderSummary from './ExperienceOrderSummary'
import Cart from './Cart'
import OrderSummary from './OrderSummary'
import EditMetadata from './EditMetadata'

import QueryParamsHandler from './QueryParamsHandler'
import { getVenue } from '../selectors/venue'
import { getLocale } from '../selectors/internationalization'
import { get } from 'lodash'

export class App extends Component {
  componentDidMount() {
    this.changeLanguageAndAddResource()
  }

  componentDidUpdate(prevProps) {
    const { displayName } = this.props.venue

    // the check needs to happen here to see if locale changed so we only call this function when necessary
    if (prevProps.locale !== this.props.locale) {
      this.changeLanguageAndAddResource()
    }

    if (prevProps.venue.displayName !== displayName && isEmpty(Title.siteName)) {
      Title.siteName = this.props.venue.displayName
    }
  }

  changeLanguageAndAddResource = () => {
    const { locale, i18n } = this.props
    let resource = {}

    i18n.changeLanguage(locale)
    resource = require(`../translations/${i18n.language}`)
    resource = get(resource, 'default.translation')
    i18n.addResourceBundle(i18n.language, 'translation', resource, true, true)

    // set the moment to use a global locale
    switch (locale) {
      case 'en-GB':
      case 'en-IE':
      case 'en-US':
        moment.locale('en')
        break
      case 'es-ES':
        moment.locale('es')
        break
      default:
        moment.locale('en')
        break
    }
  }

  render() {
    return (
      <Router>
        <div>
          <Route path="/" component={QueryParamsHandler} />
          <Route path="/" exact component={Stands} />
          <Route path="/:standId/menu/:menuId" component={Menu} />
          <Route path="/:standId/experiences/:menuId" exact component={Experiences} />
          <Route path="/:standId/experiences/:menuId/item/:itemId" exact component={ExperienceModal} />
          <Route path="/:standId/experiences/:menuId/item/:itemId/available-events" exact component={ExperienceEvents} />
          <Route path="/:standId/:menuId/events" exact component={EventsFilter} />
          <Route path="/experiences/checkout/:itemId" exact component={ExperienceCheckout} />
          <Route path="/cart" component={Cart} />
          <Route path="/experience_orders/:orderId" component={ExperienceOrderSummary} />
          <Route path="/edit-metadata/:orderId/:userItemId/:metadataId" exact component={EditMetadata} />
          <Route path="/orders/:orderId" component={OrderSummary} />
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  const venue = getVenue(state)
  const locale = getLocale(state)

  return { venue, locale }
}

export default compose(withTranslation(), connect(mapStateToProps, undefined))(App)
