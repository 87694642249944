import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { get, head, isEmpty } from 'lodash'
import { Helmet } from 'react-helmet'
import humps from 'humps'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'

import Money from '../utils/money'
import TimeZone from '../utils/timezone'
import Title from '../utils/titleGenerator'

import { makeGetRemote } from '../selectors/remote'
import { withTranslation } from 'react-i18next'

import ExperienceMetadata from './ExperienceMetadata'
import Item from '../components/Item'
import NavigationBar from '../components/NavigationBar'
import OrderTotalSection from '../components/cart/OrderTotalSection'
import Remote from '../remote'
import Section from '../components/Section'
import ExperienceCard from '../components/ExperienceCard'
import AlertDialog, { defaultAlertDialogState } from '../components/AlertDialog'

import { clearCart } from '../actions/cart'
import { clearLatestOrder, getExperienceOrder } from '../actions/order'
import { redeemUserItem } from '../actions/experiences/userItem'

import './OrderSummary.scss'
import './ExperienceOrderSummary.scss'

export class ExperienceOrderSummary extends Component {
  state = {
    alertDialog: defaultAlertDialogState,
  }

  componentDidMount() {
    const { getExperienceOrder, orderId } = this.props

    getExperienceOrder(orderId)
    this.props.clearCart()
  }

  componentWillUnmount() {
    this.props.clearLatestOrder()
  }

  redeem = (userItemId) => {
    const { redeemUserItem, order } = this.props

    redeemUserItem(userItemId, order.id)
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  renderDatum = (label, value) => {
    return(
      <div className='extra-datum'>
        <span>{label}</span>
        <span>{value}</span>
      </div>
    )
  }

  renderExtraData = () => {
    const { order, userItem, t } = this.props

    if (isEmpty(order)) return(<Section><Skeleton/></Section>)

    return (
      <Section className='extra-data'>
        {this.renderDatum(t('EVENT_DATE'), userItem.eventDate)}
        {this.renderDatum(t('EMAIL_MY_RECEIPT'), order.userEmail)}
        {this.renderDatum(`${t('ORDER')} #`, order.orderNumber)}
      </Section>
    )
  }

  onAlertDialogDismiss = () => {
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  redeemConfirm = (userItemId) => {
    const alert = {
      onConfirm: () => this.redeem(userItemId),
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('YOU_ARE_ABOUT_TO_REDEEM'),
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  renderHeader = () => {
    const { item, order, userItems, remote, t } = this.props
    if (isEmpty(item)) return(<Section><Skeleton/></Section>)

    return (
      <Section className="cards">
        {userItems.map((userItem) => {
          const { expiresAt, redemptionStartsAt } = item
          const userTimeZone = TimeZone.current()

          const redeemDay = moment(redemptionStartsAt).tz(userTimeZone).format('MMMM Do, YYYY')
          const redeemTime = moment(redemptionStartsAt).tz(userTimeZone).format('h:mm a')
          const expireDay = moment(expiresAt).tz(userTimeZone).format('MMMM Do, YYYY')
          const expireTime = moment(expiresAt).tz(userTimeZone).format('h:mm a')
          const isExpired = moment().isAfter(moment(expiresAt))
          const timeFrame = redeemDay === expireDay
            ? `${t('ON')} ${redeemDay} ${t('AT')} ${redeemTime}.`
            : `${t('BETWEEN')} ${redeemDay} ${t('AT')} ${redeemTime} ${t('AND')} ${expireDay} ${t('AT')} ${expireTime}.`

          const instructionsId = humps.camelize(userItem.id)
          const instructions = order.instructions[instructionsId]
          const displayInstructions = get(instructions, 'description')
          const formattedEventDate = moment(userItem.eventDate).tz(userTimeZone).format('MMMM Do, YYYY')
          let countdownTime = get(item, 'globalItem.countdownSeconds', undefined)

          let instructionsTitle = t('HOW_DO_I_REDEEM_CAPS')
          let defaultInstructions = `${t('WHEN_YOU_ARE_READY_TO_REDEEM')} ${!countdownTime ? t('CONFIRMATION_SCREEN') : `${countdownTime} ${t('SECOND_CONFIRMATION_SCREEN')}`}.`

          if (item.redemptionType !== 'countdown_timer') {
            instructionsTitle = `${t('WHAT_HAPPENS_NEXT')}?`
            defaultInstructions = t('KEEP_A_LOOK_OUT_FOR_FURTHER_INSTRUCTIONS')
          }

          const now = moment(new Date())
          const end = moment(userItem.usedAt)
          const duration = moment.duration(now.diff(end))
          const timeSinceRedemption = duration.asSeconds()
          const showCountdown = timeSinceRedemption <= countdownTime

          countdownTime = !countdownTime && !showCountdown ? countdownTime : countdownTime - timeSinceRedemption

          let redemptionState = 'notReady'

          let header = {
            title: item.name,
            content: formattedEventDate,
          }

          let body = {
            title: instructionsTitle,
            content: isEmpty(displayInstructions) ? defaultInstructions : displayInstructions,
          }

          let footer = {
            title: '',
            content: `${t('THIS_EXPERIENCE_WILL_BE_AVAILABLE_TO_REDEEM')} ${timeFrame}`,
          }

          redemptionState = userItem.redeemable ? 'ready' : 'notReady'

          if (isExpired) {
            redemptionState = 'expired'

            body.title = ''
            body.content = `${t('THIS_EXPERIENCE_EXPIRED_ON')} ${expireDay} ${t('AT')} ${expireTime}.`
            footer.content = ''
          }

          if (userItem.usedAt !== null) {
            const usedAt = moment(userItem.usedAt).tz(userTimeZone)

            redemptionState = 'redeemed'

            body.title = ''
            body.content = `${t('THIS_EXPERIENCE_WAS_REDEEMED_ON')} ${usedAt.format('MMMM Do, YYYY')} ${t('AT')} ${usedAt.format('h:mm a')}.`
            footer.content = ''
          }

          if (remote.loading) {
            redemptionState = 'redeeming'
          }

          if (item.redemptionType !== 'countdown_timer') {
            redemptionState = 'notRedeemable'

            footer.title = t('ORDER_NUMBER')
            footer.content = order.orderNumber
          }

          return (
            <ExperienceCard
              key={userItem.id}
              header={header}
              body={body}
              footer={footer}
              didClickRedeem={() => {
                if (redemptionState === 'ready') this.redeemConfirm(userItem.id)
              }}
              didClickInfo={() => {
                this.props.history.push(`/experience_orders/${order.id}/${userItem.id}`)
              }}
              metadata={userItem.metadata}
              redemptionState={redemptionState}
              startTime={userItem.usedAt !== null && userItem.usedAt}
              countdownDuration={countdownTime}
              showCountdown={showCountdown}
            />
          )
        })}
      </Section>
    )
  }

  renderLineItems = () => {
    const { lineItem, order } = this.props

    if (isEmpty(order)) {
      return(
        <Section className='line-items'>
          <div><Skeleton/><Skeleton/></div>
        </Section>
      )
    }

    return (
      <Section className='line-items'>
        <Item
          type="cart"
          key={lineItem.id}
          name={lineItem.name}
          quantity={order.quantity}
          price={Money.formatDollars(lineItem.price)}
        />
      </Section>
    )
  }

  renderNavigation = () => {
    return <NavigationBar.Close text={this.props.t('ORDER_DETAILS')} right twoToned transparent />
  }

  render() {
    const { alertDialog } = this.state
    const { order, t } = this.props
    const orderNumber = order.orderNumber || t('LOADING')

    const userTimeZone = TimeZone.current()
    const formattedOrderDate = moment(order.createdAt).tz(userTimeZone).format('MMMM Do, YYYY')
    const orderDate = `${t('PLACED_ON')} ${formattedOrderDate}`

    return (
      <div className="order-summary experiences">
        <Helmet>
          <title>{Title.generate(`${t('RECEIPT')} ${orderNumber}`)}</title>
        </Helmet>

        {this.renderNavigation()}
        <p className="placed-on-date">{orderDate}</p>
        {this.renderHeader()}
        {this.renderLineItems()}
        <OrderTotalSection
          finalTotal={order.total}
          itemQuantityLabel={t('SUBTOTAL')}
          tax={0}
          loading={isEmpty(order)}
        />
        {this.renderExtraData()}
        <Route path={`/experience_orders/:orderId/:userItemId`} component={ExperienceMetadata} />
        {alertDialog.show && <AlertDialog {...alertDialog} />}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const orderId = get(ownProps, 'match.params.orderId', '')
  const order = get(state, `order.${orderId}`, {})

  const getRemote = makeGetRemote()
  const remote = getRemote(state, Remote.endpoints.redeemUserItem)

  // Can only buy one event item per order for now
  const item = head(get(order, 'items')) || {}
  const userItem = head(get(order, 'userItems')) || {}

  const lineItem = head(get(order, 'lineItems')) || {}
  const userItems = get(order, 'userItems') || []

  return {
    item,
    lineItem,
    order,
    orderId,
    remote,
    userItems,
    userItem,
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    clearCart: () => dispatch(clearCart()),
    clearLatestOrder: () => dispatch(clearLatestOrder()),
    getExperienceOrder: (id) => dispatch(getExperienceOrder(id)),
    redeemUserItem: (userItemId, orderId) => dispatch(redeemUserItem(userItemId, orderId)),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExperienceOrderSummary))
