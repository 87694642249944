import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { get, isEmpty } from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import Analytics from '../reporting/analytics'
import Money from '../utils/money'
import Remote from '../remote'
import Title from '../utils/titleGenerator'

import NavigationBar from '../components/NavigationBar'
import Item from '../components/Item'
import Button from '../components/Button'
import Section from  '../components/Section'
import QuantitySelector from '../components/QuantitySelector'

import { addItemToCart } from '../actions/cart'
import { loadMenu } from '../actions/menu'
import { loadStands } from '../actions/stand'

import { makeGetMenu } from '../selectors/menu'
import { makeGetExperienceItem } from '../selectors/item'
import { makeGetCartItem } from '../selectors/cart'
import { makeGetRemote } from '../selectors/remote'
import { makeGetEvent } from '../selectors/event'

import './ExperienceModal.scss'

export class ExperienceModal extends Component {
  state = {
    quantity: 1
  }

  componentDidMount() {
    const { item, loadMenu, loadStands, menu, menuId } = this.props

    if (isEmpty(menu)) {
      loadMenu(menuId)
      loadStands()
    }

    if (!isEmpty(item)) Analytics.generateItemViewedEvent(item)
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props

    if (isEmpty(prevProps.item) && !isEmpty(item)) Analytics.generateItemViewedEvent(item)
  }

  closeModal = () => {
    const { menuRoute } = this.props
    this.props.history.push(menuRoute)
  }

  addToCart = () => {
    const { quantity } = this.state
    const { item, cartItemQuantity, addItemToCart, event } = this.props

    let variant = item.variants.find(variant => variant.eventUuid === event.id)
    variant.name = item.name
    variant.eventDate = moment(event.date).format('MMMM Do, YYYY')

    addItemToCart(item.uuid, quantity + cartItemQuantity, 'Experience', variant)
    if (item.metadata.length > 0) {
      this.props.history.push(`/experiences/checkout/${item.uuid}`)
    } else {
      this.props.history.push('/cart')
    }
  }

  handleAddToCart = () => {
    const { loading, event } = this.props

    if (loading || isEmpty(event)) return
    this.addToCart()
  }

  decrementCount = () => {
    const { quantity } = this.state
    const newQuantity = quantity - 1

    if (newQuantity < 1) return

    this.setState({ quantity: newQuantity })
  }

  incrementCount = () => {
    const { quantity } = this.state
    const newQuantity = quantity + 1

    this.setState({ quantity: newQuantity })
  }

  disableIncrement = () => {
    return false
  }

  renderItemDetail = () => {
    const { loading, item } = this.props
    if (loading) return <Item type="detail" loading />
    const name = get(item, 'name', '')
    const description = get(item, 'marketingDescription', '')
    const quantityAvailable = get(item, 'onHandQuantity', 1000)
    const price = parseFloat(get(item, 'price', 0), 10).toFixed(2)
    const priceWithCurrency = Money.convertCurrency(price)

    return (
      <Item
        type="detail"
        name={name}
        description={description}
        price={priceWithCurrency}
        quantityAvailable={quantityAvailable <= 10 ? quantityAvailable : null}
      />
    )
  }

  renderEventPicker = () => {
    const { event, availableEventsRoute, t } = this.props
    let eventDate = t('EVENT_DATE')
    let eventDetails = ''

    if (!isEmpty(event)) {
      eventDate = moment(event.date).format('MMMM Do, YYYY')
      eventDetails = `${event.time}, ${event.name}`
    }

    return (
      <Section className="event-date">
        <Link to={availableEventsRoute} className={classNames('event-picker', { active: get(event, 'date', false ) })}>
          <span className="date">{eventDate}</span>
          <span className="event-details">{eventDetails}</span>
        </Link>
      </Section>
    )
  }

  getButtonText = () => {
    const { maxQuantity, canOrderItem, t } = this.props
    const unavailable = maxQuantity === 0

    if (!canOrderItem) return t('YOU_CAN_ONLY_ORDER_FOR_ONE_EVENT')
    if (unavailable) return t('MAX_QUANTITY_REACHED')
    return t('ORDER_NOW')
  }

  render() {
    const { quantity } = this.state
    const { menu, item, loading, maxQuantity, event, canOrderItem } = this.props

    const menuName = get(menu, 'name', "")
    const itemName = get(item, 'name', "")

    const unavailable = !canOrderItem || maxQuantity === 0
    let buttonText = this.getButtonText()

    return (
      <div className="experience-detail">
        <Helmet>
          <title>{Title.generate(menuName, itemName)}</title>
        </Helmet>
        <div
          className='item-image'
          style={{ backgroundImage: `url(${get(item, 'images.detail', '')})` }}
        >
          <NavigationBar.Close text="" right transparent twoToned onClick={this.closeModal} />
        </div>
        <div className={classNames('item-header')}>
          {this.renderItemDetail()}
          {this.renderEventPicker()}
        </div>
        <div className="item-footer">
          <QuantitySelector
            onIncrement={this.incrementCount}
            onDecrement={this.decrementCount}
            min={1}
            max={maxQuantity}
            value={quantity}
            loading={isEmpty(event) || loading}
          />
          <div className="verify-age">
            <Button.Brand disabled={isEmpty(event) || unavailable} onClick={this.handleAddToCart}>{buttonText}</Button.Brand>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const standId = get(ownProps, 'match.params.standId', '')
  const menuId = get(ownProps, 'match.params.menuId', '')
  const itemId = get(ownProps, 'match.params.itemId', '')

  const getRemote = makeGetRemote()
  const getMenu = makeGetMenu()
  const getExperienceItem = makeGetExperienceItem()
  const getCartItem = makeGetCartItem()
  const getEvent = makeGetEvent()

  const remote = getRemote(state, Remote.endpoints.getMenu)
  const menu = getMenu(state, menuId)
  const item = getExperienceItem(state, itemId)
  const cartItem = getCartItem(state, itemId)
  const selectedEvent = get(state, 'filter.eventFilter', undefined)
  const event = getEvent(state, selectedEvent)

  const menuRoute = `/${standId}/experiences/${menuId}`
  const availableEventsRoute = `/${standId}/experiences/${menuId}/item/${itemId}/available-events`

  if (isEmpty(item)) return {
    item: {},
    cartItemQuantity: 0,
    loading: remote.loading,
    event,
    menuRoute,
    availableEventsRoute,
  }

  const onHandQuantity = item.onHandQuantity
  const purchaseLimit = item.purchaseLimit
  const cartItemQuantity = cartItem.quantity || 0
  let maxQuantity = purchaseLimit === null ? onHandQuantity : purchaseLimit
  let canOrderItem = true

  if (purchaseLimit > onHandQuantity) {
    maxQuantity = onHandQuantity
  }

  if (!isEmpty(cartItem)) {
    if (selectedEvent !== undefined) {
      canOrderItem = item.id === cartItem.id && cartItem.variant.eventUuid === selectedEvent
    }

    maxQuantity = maxQuantity - cartItem.quantity
  }

  return {
    menu,
    menuId,
    item: {
      ...item,
    },
    maxQuantity,
    menuRoute,
    availableEventsRoute,
    cartItemQuantity,
    isInCart: !isEmpty(cartItem),
    loading: remote.loading,
    event,
    canOrderItem,
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    addItemToCart(itemId, quantity, productType, variant) {
      dispatch(addItemToCart(itemId, quantity, [], true, productType, variant))
    },
    loadMenu: (menuId) => dispatch(loadMenu(menuId)),
    loadStands: () => dispatch(loadStands()),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExperienceModal))
