import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { get, isEmpty, keys } from 'lodash'
import classNames from 'classnames'
import moment from 'moment'

import AlertDialog, { defaultAlertDialogState } from '../components/AlertDialog'
import Button from '../components/Button'
import Item from '../components/Item'
import MenuHeader from '../components/MenuHeader'
import NavigationBar from '../components/NavigationBar'
import Section from '../components/Section'

import { setActiveMenu } from '../actions/cart'
import { loadMenu } from '../actions/menu'
import { loadStands } from '../actions/stand'
import { clearLatestOrder, clearOrderError } from '../actions/order'

import { makeGetEvent } from '../selectors/event'
import { makeGetStand } from '../selectors/stand'
import { makeGetMenu, makeGetFilteredExperiences } from '../selectors/menu'
import { makeGetCartProperties } from '../selectors/cart'
import { makeGetRemote } from '../selectors/remote'
import { withTranslation } from 'react-i18next'

import Money from '../utils/money'
import Remote from '../remote'

import './Experiences.scss'

export class Experiences extends Component {
  state = {
    alertDialog: defaultAlertDialogState,
  }

  componentDidMount() {
    const { loadMenu, loadStands, menuId, standId, setActiveMenu, clearLatestOrder, clearOrderError } = this.props

    loadMenu(menuId)
    loadStands()
    setActiveMenu(menuId, standId)
    clearLatestOrder()
    clearOrderError()
  }

  onAlertDialogDismiss = () => {
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  goBack = () => this.props.history.push('/')

  goBackConfirm = () => {
    if (this.props.isCartEmpty) return this.goBack()

    const alert = {
      onConfirm: this.goBack,
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('REMOVE_ALL_ITEMS'),
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  renderItems = () => {
    const { currentUrl, loading, menuItems } = this.props

    return (
      <Section className="experience-items">
        {!loading && !isEmpty(menuItems) && keys(menuItems).map((itemId) => {
          const item = menuItems[itemId]

          const quantityAvailable = get(item, 'onHandQuantity', 1000)
          const price = parseFloat(item.price, 10).toFixed(2)
          const priceWithCurrency = Money.convertCurrency(price)

          return (
            <Link to={`${currentUrl}/item/${item.uuid}`} key={item.uuid}>
              <Item
                type="cell"
                name={get(item, 'name', '')}
                image={get(item, 'images.detail', '')}
                price={priceWithCurrency}
                quantityAvailable={quantityAvailable <= 10 ? quantityAvailable : null}
                available={item.available}
              />
            </Link>
          )
        })}

        {!loading && isEmpty(menuItems) && (
          <div className="no-items">
            <h2>{this.props.t('NOTHING_FOR_SALE')}...</h2>
            <p>{this.props.t('PLEASE_TRY_ANOTHER_EVENT')}.</p>
          </div>
        )}

        {loading && (
          <Section className='loading'>
            <Item type="cell" loading />
            <Item type="cell" loading />
          </Section>
        )}
      </Section>
    )
  }

  renderEventPicker = () => {
    const { eventsUrl, event, loading, menu } = this.props
    if (loading) return null

    let eventDate = this.props.t('EVENT_DATE')
    let eventDetails = ''
    const description = get(menu, 'longDescription')

    if (!isEmpty(event)) {
      eventDate = moment(event.date).format('MMMM Do, YYYY')
      eventDetails = `${event.time}, ${event.name}`
    }

    return (
      <Section className="event-date">
        <Link to={eventsUrl} className={classNames('event-picker', { active: get(event, 'date', false )})}>
          <span className="date">{eventDate}</span>
          <span className="event-details">{eventDetails}</span>
        </Link>
        {description && <p className="description">{description}</p>}
      </Section>
    )
  }

  renderViewCart = () => {
    const { quantity, subtotal } = get(this.props, 'cartProperties', {})

    const cartButton = (
      <div className='bottom-button-wrapper'>
        <Link to="/cart">
          <Button.ViewCart quantity={quantity} total={Money.centsToDollars(subtotal)} />
        </Link>
      </div>
    )

    const classNameMethod = quantity > 0 ? 'add' : 'remove'
    const component = quantity > 0 ? cartButton : null

    document.body.classList[classNameMethod]('cart-active')
    return component
  }

  render() {
    const { alertDialog } = this.state
    const { loading, menu, stand } = this.props

    const menuHeader = (
      <MenuHeader
        name={get(menu, 'name', '')}
        image={get(stand, 'images.detail', '')}
        loading={loading}
      />
    )

    return (
      <div className="experiences">
        <NavigationBar.Back text={this.props.t('BACK_LOWERCASE')} onClick={this.goBackConfirm} />
        {menuHeader}
        {this.renderEventPicker()}
        {this.renderItems()}
        {alertDialog.show && <AlertDialog {...this.state.alertDialog} />}
        {this.renderViewCart()}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const menuId = get(ownProps, 'match.params.menuId', '')
  const standId = get(ownProps, 'match.params.standId', '')
  const currentUrl = get(ownProps, 'match.url', '')
  const eventsUrl = `/${standId}/${menuId}/events`
  const eventFilter = get(state, 'filter.eventFilter', undefined)
  const getEvent = makeGetEvent()
  const event = getEvent(state, eventFilter)

  const getStand = makeGetStand()
  const getMenu = makeGetMenu()
  const getFilteredExperiences = makeGetFilteredExperiences()
  const getCartProperties = makeGetCartProperties()
  const getRemote = makeGetRemote()

  const remote = getRemote(state, Remote.endpoints.getMenu)
  const menu = getMenu(state, menuId)
  const stand = getStand(state, standId)
  const filteredExperiences = getFilteredExperiences(state, menuId)
  const isCartEmpty = keys(get(state, 'cart.item', {})).length === 0
  const cartProperties = getCartProperties(state)

  return {
    cartProperties,
    currentUrl,
    eventsUrl,
    event,
    isCartEmpty,
    loading: remote.loading,
    menu,
    menuId,
    menuItems: filteredExperiences,
    stand,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearLatestOrder: () => dispatch(clearLatestOrder()),
    clearOrderError: () => dispatch(clearOrderError()),
    loadMenu: (menuId) => dispatch(loadMenu(menuId)),
    loadStands: () => dispatch(loadStands()),
    setActiveMenu: (menuId, standId) => dispatch(setActiveMenu(menuId, standId)),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Experiences))
