export const CLEAR_LATEST_ORDER = 'CLEAR_LATEST_ORDER'
export const CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR'

export const CREATE_ORDER_REQUESTED = 'CREATE_ORDER_REQUESTED'
export const CREATE_ORDER_SUCCEEDED = 'CREATE_ORDER_SUCCEEDED'
export const CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED'

export const CREATE_EXPERIENCE_ORDER_REQUESTED = 'CREATE_EXPERIENCE_ORDER_REQUESTED'
export const CREATE_EXPERIENCE_ORDER_SUCCEEDED = 'CREATE_EXPERIENCE_ORDER_SUCCEEDED'
export const CREATE_EXPERIENCE_ORDER_FAILED = 'CREATE_EXPERIENCE_ORDER_FAILED'

export const UPDATE_ORDER_REQUESTED = 'UPDATE_ORDER_REQUESTED'
export const UPDATE_ORDER_SUCCEEDED = 'UPDATE_ORDER_SUCCEEDED'
export const UPDATE_ORDER_FAILED = 'UPDATE_ORDER_FAILED'

export const GET_EXPERIENCE_ORDER_REQUESTED = 'GET_EXPERIENCE_ORDER_REQUESTED'
export const GET_EXPERIENCE_ORDER_SUCCEEDED = 'GET_EXPERIENCE_ORDER_SUCCEEDED'
export const GET_EXPERIENCE_ORDER_FAILED = 'GET_EXPERIENCE_ORDER_FAILED'

export const GET_ORDER_REQUESTED = 'GET_ORDER_REQUESTED'
export const GET_ORDER_SUCCEEDED = 'GET_ORDER_SUCCEEDED'
export const GET_ORDER_FAILED = 'GET_ORDER_FAILED'

export const UPDATE_ORDER_METADATA_REQUESTED = 'UPDATE_ORDER_METADATA_REQUESTED'
export const UPDATE_ORDER_METADATA_SUCCEEDED = 'UPDATE_ORDER_METADATA_SUCCEEDED'
export const UPDATE_ORDER_METADATA_FAILED = 'UPDATE_ORDER_METADATA_FAILED'

export const clearLatestOrder = () => ({
  type: CLEAR_LATEST_ORDER,
})

export const clearOrderError = () => ({
  type: CLEAR_ORDER_ERROR,
})

export const createOrder = (orderParams) => ({
  type: CREATE_ORDER_REQUESTED,
  orderParams,
})

export const createOrderSucceeded = (payload) => ({
  type: CREATE_ORDER_SUCCEEDED,
  payload,
})

export const createOrderFailed = (payload) => ({
  type: CREATE_ORDER_FAILED,
  payload,
})

export const createExperienceOrder = (orderParams) => ({
  type: CREATE_EXPERIENCE_ORDER_REQUESTED,
  orderParams,
})

export const createExperienceOrderSucceeded = (payload) => ({
  type: CREATE_EXPERIENCE_ORDER_SUCCEEDED,
  payload,
})

export const createExperienceOrderFailed = (payload) => ({
  type: CREATE_EXPERIENCE_ORDER_FAILED,
  payload,
})

export const updateOrder = (id, orderParams) => ({
  type: UPDATE_ORDER_REQUESTED,
  id,
  orderParams,
})

export const updateOrderSucceeded = (payload) => ({
  type: UPDATE_ORDER_SUCCEEDED,
  payload,
})

export const updateOrderFailed = (orderId, payload) => ({
  type: UPDATE_ORDER_FAILED,
  orderId,
  payload,
})

export const getOrder = (id) => ({
  type: GET_ORDER_REQUESTED,
  id,
})

export const getOrderSucceeded = (payload) => ({
  type: GET_ORDER_SUCCEEDED,
  payload,
})

export const getOrderFailed = (orderId, payload) => ({
  type: GET_ORDER_FAILED,
  orderId,
  payload,
})

export const getExperienceOrder = (id) => ({
  type: GET_EXPERIENCE_ORDER_REQUESTED,
  id,
})

export const getExperienceOrderSucceeded = (payload) => ({
  type: GET_EXPERIENCE_ORDER_SUCCEEDED,
  payload,
})

export const getExperienceOrderFailed = (orderId, payload) => ({
  type: GET_EXPERIENCE_ORDER_FAILED,
  orderId,
  payload,
})

export const updateOrderMetadata = (userItemId, metadata) => ({
  type: UPDATE_ORDER_METADATA_REQUESTED,
  userItemId,
  metadata,
})

export const updateOrderMetadataSucceeded = (payload) => ({
  type: UPDATE_ORDER_METADATA_SUCCEEDED,
  payload,
})

export const updateOrderMetadataFailed = (payload) => ({
  type: UPDATE_ORDER_METADATA_FAILED,
  payload,
})
