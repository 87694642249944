import { createSelector } from 'reselect'
import { makeGetExperienceItem } from './item'
import { getCartItems } from './cart'
import { get, keys, last } from 'lodash'

export const makeGetMetadataScreens = () => createSelector(
  [makeGetExperienceItem(), getCartItems],
  (experienceItem, cartItems) => {
    const metadataLength = get(experienceItem, 'metadata.length', 1)
    const cartItem = cartItems[experienceItem.uuid]

    const data = get(cartItem, 'metadata', {})
    const entered = keys(data).filter((key) => data[key].length === metadataLength)
    const lastEntered = parseInt( last(entered) || 0 )
    const startingItemNumber = lastEntered + 1

    let result = { startingItemNumber: startingItemNumber, ...cartItem.metadata }

    for (let i = startingItemNumber; i <= cartItem.quantity; i++) {
      const itemScreens = get(experienceItem, 'metadata', []).map((datum, i) => {
        return {
          description: datum.description,
          fieldKey: datum.id,
          fieldLabel: datum.name,
          fieldType: datum.dataType,
          maxCharacterCount: 250,
          metadataLength,
          restriction: datum.restriction
        }
      })

      result[i] = itemScreens
    }

    return result
  }
)
