import React from 'react'
import PropTypes from 'prop-types'
import qrImage from 'qr-image'
import Skeleton from 'react-loading-skeleton'
import { withTranslation } from 'react-i18next'

export class QRCode extends React.Component {
  static defaultProps = {
    text: '',
    imageExtension: 'png',
  }

  imageSource = () => {
    const { text, imageExtension } = this.props
    const buffer = qrImage.imageSync(text, { type: imageExtension, margin: 1 })

    return `data:image/png;base64,${buffer.toString('base64')}`
  }

  render() {
    const { loading, t } = this.props

    return (
      <div className='qr-code'>
        {loading ? <Skeleton width='215px' height='215px' /> : <img src={this.imageSource()} alt={t('QR_CODE')} />}
      </div>
    )
  }
}

QRCode.propTypes = {
  text: PropTypes.string.isRequired,
  imageExtension: PropTypes.string,
}

export default withTranslation()(QRCode)