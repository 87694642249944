import React from 'react'
import braintree from "braintree-web"
import humps from 'humps'
import includes from 'lodash/includes'
import { withTranslation } from 'react-i18next'

import CreditCardFormField from './CreditCardFormField'

import {
  allFieldOptions,
  braintreeStyles,
  fieldDisplayLocale,
  optionalFieldNames,
  requiredFieldNames,
} from './BraintreeConfig'

import './CreditCard.scss'

export class CreditCard extends React.Component {
  constructor(props) {
    super(props)

    this.hostedFieldsInstance = undefined
  }

  state = {
    braintreeLoadError: null,
    fieldsToShow: null,
    focusedField: null,
    hostedFieldsInitiated: false,
    showValidations: false,
    validations: {
      cvv: false,
      expirationDate: false,
      number: false,
      postalCode: false,
    }
  }

  componentDidUpdate (prevProps) {
    const { braintreeClient, timeToTokenize } = this.props
    const { hostedFieldsInitiated } = this.state

    if (braintreeClient && !hostedFieldsInitiated) {
      this.setState({ hostedFieldsInitiated: true })
      braintree.hostedFields.create({
        client: braintreeClient,
        styles: braintreeStyles,
        fields: this.hostedFieldOptions(),
      }, this.braintreeHostedFieldsCreated)
    }

    if (timeToTokenize && !prevProps.timeToTokenize) this.tokenize()
  }

  allFieldsValid = () => {
    const { validations } = this.state

    return this.fieldsToShow().every(fieldName => validations[fieldName])
  }

  braintreeHostedFieldsCreated = (err, braintreeHostedFields) => {
    if (err) {
      this.setState({ braintreeLoadError: { detail: err.message } })
      return
    }

    this.hostedFieldsInstance = braintreeHostedFields
    braintreeHostedFields.on('blur', this.onBlur)
    braintreeHostedFields.on('focus', this.onFocus)
    braintreeHostedFields.on('validityChange', this.onValidityChange)
  }

  errorTextFor = (fieldName) => {
    if (!this.hostedFieldsInstance) return null

    const braintreeField = this.hostedFieldsInstance.getState().fields[fieldName]
    const { t } = this.props
    const fields = t('creditCard')
    const displayConfig = fieldDisplayLocale(fieldName, fields)
    const displayName = displayConfig.displayText

    if (braintreeField.isEmpty) return `${displayName} ${this.props.t('IS_REQUIRED')}`
    if (!braintreeField.isValid) return `${this.props.t('INVALID_VALUE_FOR')} ${displayName}`
  }

  fieldsToShow = () => {
    if (this.state.fieldsToShow) return this.state.fieldsToShow

    const { braintreeClient } = this.props
    const challenges = braintreeClient.getConfiguration().gatewayConfiguration.challenges
    let fields = requiredFieldNames.slice()

    optionalFieldNames.forEach((name) => {
      const underscored = humps.decamelize(name)

      // they come back underscored from braintree; adding a fallback in case it ever changes
      if (includes(challenges, underscored) || includes(challenges, name)) {
        fields.push(name)
      }
    })

    this.setState({ fieldsToShow: fields })
    return fields
  }

  helpTextFor = (fieldName) => {
    if (!this.hostedFieldsInstance) return null

    const braintreeField = this.hostedFieldsInstance.getState().fields[fieldName]

    return braintreeField.isEmpty ? `*${this.props.t('REQUIRED')}` : ""
  }

  hostedFieldOptions = () => {
    const fields = this.fieldsToShow()
    let fieldOptions = {}

    fields.forEach((field) => fieldOptions[field] = allFieldOptions[field])

    return fieldOptions
  }

  isInvalid = (fieldName) => {
    return this.state.showValidations && !this.state.validations[fieldName]
  }

  isNotEmpty = (fieldName) => {
    if (!this.hostedFieldsInstance) return false
    return !this.hostedFieldsInstance.getState().fields[fieldName].isEmpty
  }

  onBlur = (event) => {
    const { focusedField } = this.state
    const field = event.fields[event.emittedBy]
    const fieldName = humps.camelize(field.container.id)

    if (focusedField === fieldName) this.setState({ focusedField: null })
  }

  onFocus = (event) => {
    const { isSelected, onPaymentSelected } = this.props
    const field = event.fields[event.emittedBy]
    const fieldName = humps.camelize(field.container.id)

    if (!isSelected) { onPaymentSelected("creditCard") }
    this.setState({ focusedField: fieldName })
  }

  onValidityChange = (event) => {
    const field = event.fields[event.emittedBy]
    const fieldName = humps.camelize(field.container.id)
    const isValid = field.isValid
    const newValidations = { ...this.state.validations }

    newValidations[fieldName] = isValid

    this.setState({ validations: newValidations })
    if (this.allFieldsValid()) this.props.onPaymentValid()
  }

  renderField = (fieldName) => {
    const { t } = this.props
    const fields = t('creditCard')
    const displayConfig = fieldDisplayLocale(fieldName, fields)
    const underscored = humps.decamelize(fieldName)

    return (
      <CreditCardFormField
        displayText={displayConfig.displayText}
        errorText={this.errorTextFor(fieldName)}
        fieldID={underscored}
        focused={this.state.focusedField === fieldName}
        helpText={this.helpTextFor(fieldName)}
        isInvalid={this.isInvalid(fieldName)}
        key={underscored}
        notEmpty={this.isNotEmpty(fieldName)}
      />
    )
  }

  tokenize = () => {
    const {
      onBraintreeTokenized,
      onBraintreeTokenizedFailed,
      onValidationFailed,
    } = this.props

    if (!this.allFieldsValid()) {
      this.setState({ showValidations: true })
      return onValidationFailed()
    }

    this.hostedFieldsInstance.tokenize().then((response) => {
      onBraintreeTokenized(response)
    }).catch((err) => {
      onBraintreeTokenizedFailed(err.message)
    })
  }

  render() {
    const fields = this.state.fieldsToShow || []

    return (
      <form id="braintree_form" className="form-container">
        {fields.map(this.renderField)}
      </form>
    )
  }
}

export default withTranslation()(CreditCard)
