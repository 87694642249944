import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { get, groupBy, head, isEmpty, isEqual, keys, max, min } from 'lodash'
import { disableBodyScroll } from 'body-scroll-lock'

import { itemHasVariants, variantsAreSamePrice, variantsForItem } from '../utils/menuVariantHelper'
import Money from '../utils/money'
import Remote from '../remote'

import ItemModal from './ItemModal'
import DeliveryModal from './DeliveryModal'
import ScrollToTop from './ScrollToTop'

import AlertDialog, { defaultAlertDialogState } from '../components/AlertDialog'
import Button from '../components/Button'
import Item from '../components/Item'
import MenuHeader from '../components/MenuHeader'
import NavigationBar from '../components/NavigationBar'
import Scrollable from '../components/Scrollable'
import Section from '../components/Section'

import { loadStands } from '../actions/stand'
import { loadMenu } from '../actions/menu'
import { addItemToCart, clearCart, setActiveMenu } from '../actions/cart'
import { clearLatestOrder, clearOrderError } from '../actions/order'

import { makeGetMenu, makeGetMenuItems, makeGetMenuCategories } from '../selectors/menu'
import { makeGetStand, makeGetStandMenus, makeGetStandServices } from '../selectors/stand'
import { makeGetCartProperties } from '../selectors/cart'
import { makeGetRemote } from '../selectors/remote'
import { getUserLocation } from '../selectors/user'
import { withTranslation } from 'react-i18next'

import './Menu.scss'
import Title from '../utils/titleGenerator'

export class Menu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      categorySelectorVisible: false,
      activeCategoryName: '1',
      lastSelectorElementMarginRight: undefined,
      lastSectionElementMarginBottom: undefined,
      alertDialog: defaultAlertDialogState,
    }

    this.categoriesRef = React.createRef()
    this.categorySelectorRef = React.createRef()
  }

  handleSelect = (categoryName) => {
    const index = this.props.categories.indexOf(categoryName);
    this.setState({
      activeCategoryName: categoryName,
    }, () => {
      this.categorySelectorRef.current.scrollToIndex({ index })
      this.categoriesRef.current.scrollToIndex({ index })
    })
  }

  handleCategoryChange = (categoryName, index) => {
    this.setState({
      activeCategoryName: categoryName,
    }, () => {
      this.categorySelectorRef.current && this.categorySelectorRef.current.scrollToIndex({ index })
    })
  }

  componentDidMount() {
    const { loadMenu, loadStands, menuId, setActiveMenu, standId, clearLatestOrder, clearOrderError } = this.props

    loadMenu(menuId)
    loadStands()
    setActiveMenu(menuId, standId)
    clearLatestOrder()
    clearOrderError()
    this.addMargin()
  }

  componentDidUpdate(prevProps) {
    const hasNewCategories = !isEqual([...prevProps.categories].sort(), [...this.props.categories].sort())
    if (hasNewCategories) {
      this.addMargin()
      this.setState({ activeCategoryName: head(this.props.categories) })
    }

    const { loadMenu, menuId, stand, setActiveMenu } = this.props
    if (prevProps.menuId !== menuId || prevProps.stand.uuid !== stand.uuid) {
      setActiveMenu(menuId, stand.uuid)
      loadMenu(menuId)
    }
  }

  variantDisplayPrice = (item) => {
    const variants = variantsForItem(item)
    if (variantsAreSamePrice(item)) return Money.centsToDollars(variants[0].priceInCents)

    const prices = variants.map(variant => variant.priceInCents)
    const low = Money.centsToDollars( min(prices) )
    const high = Money.centsToDollars( max(prices) )

    return `${low} - ${high}`
  }

  renderItemsInCategory = (categoryItems) => {
    const { currentUrl, menu, userLocation, t } = this.props

    if (!categoryItems) {
      return <div>{t('NO_ITEMS_AVAILABLE_IN_THIS_CATEGORY')}!</div>
    }

    const serviceType = get(menu, 'usageType', undefined)

    return categoryItems.map((item) => {
      const isVariant = itemHasVariants(item)

      let displayName = get(item, 'name', '')
      if (isVariant) displayName = get(item, 'variantGroupDisplayName', displayName)

      let displayPrice = Money.centsToDollars(item.defaultPriceInCents)
      if (isVariant) displayPrice = this.variantDisplayPrice(item)

      const itemCell = (
        <Item
          type="cell"
          name={displayName}
          description={item.marketingDescription || item.description}
          image={get(item, 'images.detail')}
          price={displayPrice}
          available={menu.isAvailable}
        />
      )
      if (!menu.isAvailable) {
        return itemCell
      }

      const route = {
        pathname: `${currentUrl}/item/${item.uuid}`,
      }

      if (serviceType === 'delivery' && !userLocation.confirmed) {
        route.pathname = `${currentUrl}/delivery-location`
        route.state = {
          redirect: `${currentUrl}/item/${item.uuid}`,
        }
      }

      return (
        <Link
          to={route}
          key={item.uuid}
          onClick={(e) => { disableBodyScroll(e.target) }}
        >
          {itemCell}
        </Link>
      )
    })
  }

  onAlertDialogDismiss = () => {
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  goBack = () => {
    this.props.clearCart()
    this.props.history.push('/')
  }

  goBackConfirm = () => {
    if (this.props.isCartEmpty) { return this.goBack() }

    const alert = {
      onConfirm: this.goBack,
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('REMOVE_ALL_ITEMS'),
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  changeMenu = (service) => {
    const { stand, clearCart, setActiveMenu } = this.props

    clearCart()
    setActiveMenu(service.id, stand.uuid)
    this.props.history.replace(`/${stand.uuid}/menu/${service.id}`)
    this.onAlertDialogDismiss()
  }

  changeMenuConfirm = (service) => {
    if (this.props.isCartEmpty) { return this.changeMenu(service) }

    const alert = {
      onConfirm: () => this.changeMenu(service),
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('REMOVE_ALL_ITEMS'),
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  addMargin = () => {
    const lastSelectorElement = document.querySelector('.menu-categories .category:last-child')
    const lastSectionElement = document.querySelector('.menu-items .section:last-child')

    if (!lastSelectorElement || !lastSectionElement) return

    const selectorWidth = lastSelectorElement.clientWidth
    const sectionHeight = lastSectionElement.clientHeight
    const selectorMargin = window.innerWidth - selectorWidth
    const sectionMargin = window.innerHeight - sectionHeight

    this.setState({
      lastSelectorElementMarginRight: selectorMargin,
      lastSectionElementMarginBottom: sectionMargin,
    })
  }

  renderViewCart = () => {
    const { quantity, subtotal } = get(this.props, 'cartProperties', {})

    const cartButton = (
      <div className='bottom-button-wrapper'>
        <Link to="/cart">
          <Button.ViewCart quantity={quantity} total={Money.centsToDollars(subtotal)} />
        </Link>
      </div>
    )

    const classNameMethod = quantity > 0 ? 'add' : 'remove'
    const component = quantity > 0 ? cartButton : null

    document.body.classList[classNameMethod]('cart-active')
    return component
  }

  render() {
    const { alertDialog } = this.state
    const { stand, menu, groupedItems, categories, currentUrl, services, loading, t } = this.props

    let menuHeader = <MenuHeader loading />
    if (!loading && !isEmpty(menu) && !isEmpty(stand)) {
      menuHeader = (
        <MenuHeader
          section={stand.section}
          name={stand.name}
          image={get(stand, 'images.detail', get(menu, 'images.detail'))}
          description={menu.longDescription}
          services={services.length > 1 ? services : []}
          selectedService={menu.uuid}
          onServiceChange={this.changeMenuConfirm}
        />
      )
    }

    return (
      <div>
        <ScrollToTop />
        <Helmet>
          <title>{Title.generate(get(menu, 'name', ''))}</title>
        </Helmet>
        {get(menu, 'serviceType', '') !== 'QuickPay' && <NavigationBar.Back text={t('BACK_LOWERCASE')} onClick={this.goBackConfirm} />}
        {menuHeader}
        {loading && <div className='menu-items'>
          <Section loading>
            <Item type="cell" loading />
            <Item type="cell" loading />
            <Item type="cell" loading />
          </Section>
        </div>}
        {!loading && <Scrollable
          ref={this.categoriesRef}
          data={categories.map((cat) => cat.name)}
          globalOffset={-8}
          containerProps={{ className: 'menu-items' }}
          onCurrentItemDidChange={this.handleCategoryChange}
          renderItem={(categoryName, index) => {
            const { lastSectionElementMarginBottom } = this.state
            const categoryItems = groupedItems[categoryName]

            if (!categoryItems) return <div key={index}></div>

            const isLastItem = index === categories.length - 1
            let styles = {}

            if (isLastItem && lastSectionElementMarginBottom > 0) {
              styles = {
                marginBottom: lastSectionElementMarginBottom
              }
            }

            return (
              <Section name={categoryName} key={index} style={styles}>
                {this.renderItemsInCategory(categoryItems, currentUrl)}
              </Section>
            )
          }}
        />}
        {alertDialog.show && <AlertDialog {...this.state.alertDialog} />}
        <Route path={`/:standId/menu/:menuId/item/:itemId`} component={ItemModal} />
        <Route path={`/:standId/menu/:menuId/delivery-location`} component={DeliveryModal} />
        {!(get(menu, 'isAvailable', true)) && <Button.Brand className="unavailable-alert">{t('LOCATION_NOT_CURRENTLY_ACCEPTING_ORDERS')}</Button.Brand>}
        {this.renderViewCart()}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const menuId = get(ownProps, 'match.params.menuId', '')
  const standId = get(ownProps, 'match.params.standId', '')

  const getStand = makeGetStand()
  const getStandMenus = makeGetStandMenus()
  const getMenu = makeGetMenu()
  const getMenuItems = makeGetMenuItems()
  const getMenuCategories = makeGetMenuCategories()
  const getStandServices = makeGetStandServices()
  const getCartProperties = makeGetCartProperties()
  const getRemote = makeGetRemote()

  const remote = getRemote(state, Remote.endpoints.getMenu)
  const menu = getMenu(state, menuId)
  const stand = getStand(state, standId)
  const standMenus = getStandMenus(state, standId)
  const menuItems = getMenuItems(state, menuId)
  const groupedItems = groupBy(menuItems, (item) => item.category)
  const categories = getMenuCategories(state, menuId).filter(category => groupedItems[category.name])
  const currentUrl = get(ownProps, 'match.url', '')
  const isCartEmpty = keys(get(state, 'cart.item', {})).length === 0
  const standServices = getStandServices(state, standId)
  const cartProperties = getCartProperties(state)
  const userLocation = getUserLocation(state)

  const services = standServices.map(service => {
    const menu = standMenus.find(menu => menu.usageType === service)

    return {
      id: menu.uuid,
      label: service,
    }
  })

  return {
    menu,
    menuId,
    stand,
    standId,
    groupedItems,
    categories,
    currentUrl,
    isCartEmpty,
    services,
    cartProperties,
    userLocation,
    loading: remote.loading,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadStands: () => dispatch(loadStands()),
    loadMenu: (menuId) => dispatch(loadMenu(menuId)),
    addItemToCart: (itemId, quantity) => dispatch(addItemToCart(itemId, quantity)),
    setActiveMenu: (menuId, standId) => dispatch(setActiveMenu(menuId, standId)),
    clearCart: () => dispatch(clearCart()),
    clearLatestOrder: () => dispatch(clearLatestOrder()),
    clearOrderError: () => dispatch(clearOrderError()),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Menu))
