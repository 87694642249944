import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import './NavigationBar.scss'

const NAVIGATION_BAR_TYPE = {
  back: "back",
  close: "close",
}

const NavigationBar = ({
  type = NAVIGATION_BAR_TYPE.back,
  right = false,
  transparent = false,
  twoToned = false,
  linkTo = "/",
  text,
  onClick
}) => {
  const containerClassNames = classNames('navigation-bar', `${type}`, { right, transparent })
  const buttonClassNames = classNames('navigation-bar', `${type}`, { transparent, 'two-toned': twoToned })
  let action = <Link to={linkTo} className={buttonClassNames}>{text}</Link>

  if (onClick !== undefined) {
    action = <button onClick={onClick} className={buttonClassNames}>{text}</button>
  }

  return (
    <div className={containerClassNames}>
      {action}
    </div>
  )
}

NavigationBar.Close = (props) => (
  <NavigationBar {...props} type={NAVIGATION_BAR_TYPE.close} />
)

NavigationBar.Back = (props) => (
  <NavigationBar {...props} type={NAVIGATION_BAR_TYPE.back} />
)

export default NavigationBar
