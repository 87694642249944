import get from 'lodash/get'
import injectPredicate from '../utils/injectPredicate'

const instance = process.env.REACT_APP_INSTANCE_NAME
const environment = process.env.REACT_APP_APP_ENV
const domain = !instance || !environment ? 'unknown-instance' : `${instance}-${environment}`

class OrderNextLog {
  static networkRequestFailed(err, method, path) {
    const methodString = method || 'unknown'
    const pathString = path || 'unknown'
    const errorTitle = `NetworkError: ${domain} ${methodString.toUpperCase()} ${pathString}`

    const respStatus = get(err, 'response.status')

    const payload = {
      url: get(err, 'response.config.url', 'unknown'),
      method: get(err, 'response.config.method', 'unknown'),
      params: get(err, 'response.config.params', 'unknown'),
      payload: get(err, 'response.config.data', 'unknown'),
      status: respStatus || 'unknown',
      errors: get(err, 'response.data.errors', 'unknown'),
    }

    let infoLevel = true
    if (respStatus && parseInt(respStatus) >= 500) { infoLevel = false }
    infoLevel ? window.Rollbar.info(errorTitle, payload) : window.Rollbar.error(errorTitle, payload)
  }
}

export default injectPredicate(OrderNextLog, () => window.Rollbar)
