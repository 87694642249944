function scrollTo (scrollTo = 0, scrollDuration = 800, targetNode, direction = 'vertical') {
	const anchorHeightAdjust = 30;
	if (scrollTo > anchorHeightAdjust) {
		scrollTo = scrollTo - anchorHeightAdjust;
	}

  let targetOffset = targetNode !== undefined ? targetNode.scrollTop : window.pageYOffset

  if (direction === 'horizontal') {
    targetOffset = targetNode !== undefined ? targetNode.scrollLeft : window.pageXOffset
  }

  let cosParameter = (targetOffset - scrollTo) / 2

	let scrollCount = 0
	let oldTimestamp = window.performance.now();

	function step(newTimestamp) {
		let tsDiff = newTimestamp - oldTimestamp;

		if (tsDiff > 100) {
			tsDiff = 30;
		}

		scrollCount += Math.PI / (scrollDuration / tsDiff);

		if (scrollCount >= Math.PI) return;

    const moveStep = Math.round(scrollTo + cosParameter + cosParameter * Math.cos(scrollCount));  
    const params = direction === 'vertical' ? [0, moveStep] : [moveStep, 0];

    if (targetNode !== undefined) {
      targetNode.scrollTo(...params);
    } else {
      window.scrollTo(...params);
    }

		oldTimestamp = newTimestamp;
		window.requestAnimationFrame(step);
	}

	window.requestAnimationFrame(step);
};

//
// Performance.now() polyfill from:
// https://gist.github.com/paulirish/5438650
//

(function () {	
	if ("performance" in window === false) {
		window.performance = {};
	}

	Date.now = (Date.now || function () {
		return new Date().getTime();
	});

	if ("now" in window.performance === false) {

		var nowOffset = Date.now();

		if (performance.timing && performance.timing.navigationStart) {
			nowOffset = performance.timing.navigationStart
		}

		window.performance.now = function now() {
			return Date.now() - nowOffset;
		}
	}

})();

export default scrollTo
