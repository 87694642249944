import { createSelector } from 'reselect'
import { get } from 'lodash'

import { makeGetItem } from './item'

export const getEvents = state => state.event

const getEvent = (state, id) => state.event[id] || {}

export const makeGetEvent = () => (
  createSelector([getEvent], (event) => event)
)

export const makeGetExperienceEvents = () => createSelector(
  [getEvents, makeGetItem()],
  (events, item) => {
    // saleId is present when there's a sale for the "All group"
    // will be null if the variant only has season ticket holder or other exclusive group sales
    const variantsWithSales = get(item, 'variants', []).filter((variant) => variant.saleId)
    const experienceEvents = variantsWithSales.map((variant) => events[variant.eventUuid])

    return experienceEvents.reduce((accumulator, event) => {
      return {
        ...accumulator,
        [event.id]: event,
      }
    }, {})
  }
)
