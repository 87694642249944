import {
  CLEAR_LATEST_ORDER,
  CLEAR_ORDER_ERROR,
  CREATE_EXPERIENCE_ORDER_SUCCEEDED,
  CREATE_EXPERIENCE_ORDER_FAILED,
  CREATE_ORDER_SUCCEEDED,
  CREATE_ORDER_FAILED,
  GET_EXPERIENCE_ORDER_REQUESTED,
  GET_EXPERIENCE_ORDER_SUCCEEDED,
  GET_EXPERIENCE_ORDER_FAILED,
  GET_ORDER_REQUESTED,
  GET_ORDER_SUCCEEDED,
  GET_ORDER_FAILED,
} from '../actions/order'

import humps from 'humps'
import moment from 'moment-timezone'
import omit from 'lodash/omit'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_LATEST_ORDER:
      return {
        ...state,
        latest: {},
      }

    case CLEAR_ORDER_ERROR:
      const newState = omit(state, ['error'])
      return {
        ...newState,
      }

    case CREATE_ORDER_SUCCEEDED:
      return {
        ...state,
        ...action.payload.order,
        orderLineItem: action.payload.orderLineItem,
        latest: action.payload.order,
      }

    case CREATE_ORDER_FAILED:
      return {
        ...state,
        error: action.payload,
      }

    case CREATE_EXPERIENCE_ORDER_SUCCEEDED:
      const data = humps.camelizeKeys(action.payload)

      return {
        ...state,
        ...data,
        latest: data,
      }

    case CREATE_EXPERIENCE_ORDER_FAILED:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EXPERIENCE_ORDER_REQUESTED:
      return {
        ...state,
        isFetching: true,
      }

    case GET_EXPERIENCE_ORDER_SUCCEEDED:
      const order = humps.camelizeKeys(action.payload)

      return {
        ...state,
        ...state.order,
        [order.id]: order,
        isFetching: false,
      }

    case GET_EXPERIENCE_ORDER_FAILED:
      return {
        ...state,
        [action.orderId.id]: {error: action.payload},
        isFetching: false,
      }

    case GET_ORDER_REQUESTED:
      return {
        ...state,
        isFetching: true,
        lastFetched: moment.now(),
      }

    case GET_ORDER_SUCCEEDED:
      return {
        ...state,
        ...action.payload.order,
        orderLineItem: action.payload.orderLineItem,
        isFetching: false,
      }

    case GET_ORDER_FAILED:
      return {
        ...state,
        [action.orderId.id]: {error: action.payload},
        isFetching: false,
      }

    default:
      return state
  }
}

export default reducer
