import React, { Component } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { get, isArray } from 'lodash'

import Section from  '../components/Section'
import { withTranslation } from 'react-i18next'

import './EventList.scss'

export class EventList extends Component {
  onEventSelected = (event) => {
    const { onEventSelected } = this.props

    onEventSelected(event)
  }

  renderEvent = (event) => {
    const { selected } = this.props
    const className = classNames('event', { selected: selected === event.id })

    return (
      <div key={event.id} className={className} onClick={() => this.onEventSelected(event.id)}>
        <span className="name">{get(event, 'name', '')}</span>
        <span className="date">{get(event, 'time', '')}</span>
      </div>
    )
  }

  renderEventGroup = (eventGroup) => {
    const day = moment(eventGroup.date).format('DD')
    const dayOfWeek = moment(eventGroup.date).format('ddd')

    return (
      <div className="body" key={eventGroup.id}>
        <div className="date-column">
          <div className="date">
            <span className="day">{day}</span>
            <span className="day-of-week">{dayOfWeek}</span>
          </div>
        </div>
        <div className="event-column">
          {eventGroup.events.map(this.renderEvent)}
        </div>
      </div>
    )
  }

  renderEventSection = (eventSection) => {
    const month = moment(eventSection.date).format('MMMM')

    return (
      <div className="event-group" key={eventSection.id}>
        <div className="event-header">
          <div className="date">{this.props.t('DATE')}</div>
          <div className="month">{`${this.props.t('EVENTS_IN')} ${month}`}</div>
        </div>
        {eventSection.days.map(this.renderEventGroup)}
      </div>
    )
  }

  render() {
    const { data } = this.props

    if (!isArray(data)) return null

    return (
      <Section className="event-list">
        {data.map(this.renderEventSection)}
      </Section>
    )
  }
}

EventList.defaultProps = {
  data: [],
  onEventSelected: () => {},
}

export default withTranslation()(EventList)
