import React from "react"
import { withTranslation } from 'react-i18next'

import Money from "../../../utils/money"

import './ApplePay.scss'

export class ApplePay extends React.Component {

  handleApplePay = (event) => {
    event.preventDefault()
    this.props.onPaymentSelected("applePay")

    // it is not "recommended" to pass through currencyCode, though they dont explain why:
    // https://developers.braintreepayments.com/guides/apple-pay/client-side/javascript/v3#create-an-applepaysession
    // We need to do it though - braintree client is created with a merchant id...
    // the merchant has many merchant accounts and currency is tied to the merchant accounts
    const currencyCode = this.props.t('CURRENCY')

    const orderTotal = Money.centsToFloat(this.props.orderTotalInCents)
    const paymentRequest = this.props.applePayClient.createPaymentRequest({
      currencyCode: currencyCode,
      total: {
        label: 'OrderNext',
        amount: orderTotal || '-1.00', // negative will throw an applepay error
      }
    })

    const session = new window.ApplePaySession(3, paymentRequest)

    // The .well-known/apple-developer-merchantid-domain-association file
    // must be the same as the one set up in Braintree for this *.ordernext.com domain
    session.onvalidatemerchant = (event) => {
      this.props.applePayClient.performValidation({
        validationURL: event.validationURL,
        displayName: this.props.t('ORDER_NEXT')
      }).then(merchantSession => {
        session.completeMerchantValidation(merchantSession)
      }).catch(err => {
        this.props.onBraintreeTokenizedFailed(this.props.t('APPLE_PAY_FAILED_TO_LOAD'))
        session && session.abort && session.abort()
      })
    }

    // If merchant is validated, apple will ask the user to use touch ID to confirm,
    // then send event info here
    session.onpaymentauthorized = (event) => {
      this.props.applePayClient.tokenize({
        token: event.payment.token
      }).then(payload => {
        this.props.onBraintreeTokenized(payload)
        session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
      }).catch(err => {
        const msg = this.props.t('COULD_NOT_COMPLETE_PURCHASE_CARD_ON_FILE')
        this.props.onBraintreeTokenizedFailed(msg)
        session.completePayment(window.ApplePaySession.STATUS_FAILURE)
      })
    }

    session.begin()
  }

  render() {
    return (
      <div className="apple-pay-container">
        <div className="apple-pay-button-with-text" onClick={this.handleApplePay}>
          <span className="text">Buy with</span>
          <span className="logo"></span>
        </div>
        <div className="banner"><span>OR</span></div>
      </div>
    )
  }
}

export default withTranslation()(ApplePay)