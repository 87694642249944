import React from "react";
import Analytics from "../reporting/analytics";
import "./EmailFormField.scss";
import { withTranslation } from 'react-i18next'

export class EmailFormField extends React.Component {
  handleEmailChange = event => {
    const email = event.target.value;
    const regex = /.+@.+\.+.+/i;
    const emailValid = regex.test(email);

    this.props.onEmailValid(email, emailValid)

    if (emailValid) {
      // Enhanced Ecommerce Funnel Part 3.4, entering email address
      Analytics.emailAddressEntered(email);
    }
  };

  render() {
    const { emailClass, disabled, email, focusRef, emailInvalid, helpText, t } = this.props;

    return (
      <form className="form-container">
        <div className="email-form form-row">
          <input
            autoComplete="on"
            className={emailClass}
            disabled={disabled}
            name="email"
            id="email"
            onChange={this.handleEmailChange}
            type="email"
            value={email}
            ref={focusRef}
          />
          <label className={emailClass} htmlFor="email">
            {t('EMAIL_RECEIPT')}
          </label>
          {emailInvalid && <div className='error-help-text'>{t('EMAIL_IS_REQUIRED')}</div>}
          {!emailInvalid && helpText && <div className='help-text'>{helpText}</div>}
        </div>
      </form>
    );
  }
}

export default withTranslation()(EmailFormField)