import { call, put, takeLatest, all } from 'redux-saga/effects'

import Remote from '../remote'
import { setLoading, setFailed, setSucceeded } from '../actions/remote'
import { loadStandsSucceeded, loadStandsFailed, STANDS_REQUESTED } from '../actions/stand'

export function* fetchStands() {
  const endpoint = Remote.endpoints.getStands
  yield put(setLoading(endpoint))

  try {
    const result = yield call(Remote.getStands)

    yield all([
      put(loadStandsSucceeded(result)),
      put(setSucceeded(endpoint, true)),
    ])
  } catch (err) {
    yield all([
      put(loadStandsFailed(err)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchFetchStands() {
  yield takeLatest(STANDS_REQUESTED, fetchStands)
}
