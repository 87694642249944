import { CREATE_ORDER_SUCCEEDED, CREATE_EXPERIENCE_ORDER_SUCCEEDED } from "../actions/order"
import { ORDER_TOTAL_SUCCEEDED, EXPERIENCE_ORDER_TOTAL_SUCCEEDED } from "../actions/orderTotal"
import { ADD_ITEM_TO_CART } from "../actions/cart"
import { makeGetCartMenuItems, getCartItems } from '../selectors/cart'
import Analytics from "../reporting/analytics"
import ExperienceAnalytics from "../reporting/experiences/analytics"
import get from 'lodash/get'
import head from 'lodash/head'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'

const analyticsMiddleware = store => next => action => {
  // Enhanced Ecommerce Funnel Part 2, adding items to cart
  if (action.type === ADD_ITEM_TO_CART) {
    if (get(action, 'payload.productType') === "Experience") {
      const item = action.payload.variant
      const quantity = action.payload.quantity

      ExperienceAnalytics.generateAddToCartEvent(item, quantity)
    } else {
      const itemId = get(action, 'payload.id')
      const item = get(store.getState(), `menuItem["${itemId}"]`)
      const quantity = get(action, 'payload.quantity')

      if (!item) {
        let result = next(action)
        return result
      }

      Analytics.generateAddToCartEvent(item, quantity)
    }

  }

  // Enhanced Ecommerce Funnel Part 3.1, viewing the cart for checkout
  if (action.type === ORDER_TOTAL_SUCCEEDED) {
    const getCartMenuItems = makeGetCartMenuItems()
    const items = getCartMenuItems(store.getState())

    if (isEmpty(items)) {
      let result = next(action)
      return result
    }

    Analytics.generateOrderCheckoutEvent(items)
  }

  // Enhanced Ecommerce Funnel Part 3.3, completing the purchase
  if (action.type === CREATE_ORDER_SUCCEEDED) {
    const getCartMenuItems = makeGetCartMenuItems()
    const cartItems = getCartMenuItems(store.getState())
    const order = head(values(action.payload.order))
    let lineItems = action.payload.orderLineItem

    values(lineItems).forEach((lineItem) => {
      const item = get(cartItems, lineItem.attributes.menuItemUuid)
      if (!item) {
        // Remove all line items that are not real items (like service charges)
        delete lineItems[lineItem.id]
        return
      }

      lineItem.attributes.category = item.category
      lineItem.variantName = item.variantName
      lineItem.variantGroupDisplayName = item.variantGroupDisplayName
    })

    if (!order) {
      let result = next(action)
      return result
    }

    Analytics.generateOrderSuccessEvent(order, lineItems)
  }

  // Experience Orders
  // Enhanced Ecommerce Funnel Part 3.1, viewing the cart for checkout
  if (action.type === EXPERIENCE_ORDER_TOTAL_SUCCEEDED) {
    const items = getCartItems(store.getState())

    if (isEmpty(items)) {
      let result = next(action)
      return result
    }
    ExperienceAnalytics.generateOrderCheckoutEvent(items)
  }

  // Enhanced Ecommerce Funnel Part 3.3, completing the purchase
  if (action.type === CREATE_EXPERIENCE_ORDER_SUCCEEDED) {
    const items = getCartItems(store.getState())
    const order = action.payload

    if (isEmpty(items) || isEmpty(order)) {
      let result = next(action)
      return result
    }

    ExperienceAnalytics.generateOrderSuccessEvent(order, items)
  }

  let result = next(action)
  return result
}

export default analyticsMiddleware
