export default class DataMapper {

  static objectsById = (objects = []) => {
    return objects.reduce((result, object) => {
      return {
        ...result,
        [object.uuid || object.id]: object
      }
    }, {})
  }

}
