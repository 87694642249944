export const MENU_REQUESTED = 'MENU_REQUESTED'
export const MENU_SUCCEEDED = 'MENU_SUCCEEDED'
export const MENU_FAILED = 'MENU_FAILED'

export const loadMenu = (menuId) => ({
  type: MENU_REQUESTED,
  menuId,
})

export const loadMenuSucceeded = (payload) => ({
  type: MENU_SUCCEEDED,
  payload,
})

export const loadMenuFailed = (payload) => ({
  type: MENU_FAILED,
  payload,
})
