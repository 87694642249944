import axios from 'axios'
import normalize from 'json-api-normalizer'
import humps from 'humps'
import OrderNextLog from '../reporting/OrderNextLog'
import { values, isEmpty } from 'lodash'

import JSONAPI from '../utils/jsonapi'

import { BASE_URL, ORG_NAME, VENUE_UUID } from '../constants'

const instance = axios.create({
  baseURL: BASE_URL,
})

function baseRequest({ method = 'get', path = '', body = {}, ...fields }, normalizeData) {
  const options = {
    method: method,
    url: path,
    data: body,
    ...fields,
    params: { venue_uuid: VENUE_UUID, organization_name: ORG_NAME, ...fields.params },
  }

  return new Promise((resolve, reject) => {
    instance(`/${path}`, options).then((response) => {
      if (!normalizeData) {
        resolve(response.data)
      } else {
        let copiedResponse = { ...response }

        if (path === 'mobile_web/v2/stands') {
          const events = copiedResponse.data.meta.events
          const jsonapiEvents = JSONAPI.event(values(events))

          copiedResponse.data.included.push(...values(jsonapiEvents.event))

          if (!isEmpty(events)) delete copiedResponse.data.meta.events
        }

        const parsedData = { ...normalize(copiedResponse.data), meta: humps.camelizeKeys(copiedResponse.data.meta) }

        resolve(parsedData)
      }
    }).catch((err) => {
      if ("response" in err && err.response && "data" in err.response) {
        reject(err.response.data.errors.detail)
      } else {
        reject(err.toString())
      }

      OrderNextLog.networkRequestFailed(err, method, path)
    })
  })
}

const get = (parameters, normalizeData = true) => baseRequest({ method: 'get', ...parameters }, normalizeData)
const post = (parameters, normalizeData = true) => baseRequest({ method: 'post', ...parameters }, normalizeData)
const patch = (parameters, normalizeData = true) => baseRequest({ method: 'patch', ...parameters }, normalizeData)

class Remote {
  static getStands = () => get({ path: 'api/v2/stands' }, false)
  static getMenu = (params) => get({ path: `api/v2/menu/${params.menuId}` }, false)

  static createOrder = (params) => post({
    path: 'mobile_web/v2/orders',
    body: params
  })

  static createExperienceOrder = (params) => post({
    path: 'mobile_web/v2/orders',
    body: params
  }, false)

  static updateOrder = (params) => patch({
    path: `v4/orders/${params.id}`,
    body: params.orderParams
  })

  static getOrder = (params) => get({
    path: `mobile_web/v2/orders/${params.id}`
  })

  static getExperienceOrder = (params) => get({
    path: `mobile_web/v2/orders/${params.id}`,
    params: { product_type: "Experience" },
  }, false)

  static orderTotal = (params) => post({
    path: 'mobile_web/v2/orders/total',
    body: params
  })

  static experienceOrderTotal = (params) => post({
    path: 'mobile_web/v2/orders/total',
    body: params
  }, false)

  static getSectionsForStand = (params) => get({
    path: `api/v1/stands/${params.standId}/sections`
  }, false)

  static getRowsForSection = (params) => get({
    path: `api/v1/sections/${params.section}/rows`,
    params: {
      aisle_ids: params.aisleIds
    }
  }, false)

  static getSeatsForRow = (params) => get({
    path: `api/v1/sections/${params.section}/rows/${params.row}/seats`,
    params: {
      aisle_ids: params.aisleIds
    }
  }, false)

  static getMenusForSeatLocation = (params) => get({
    path: 'mobile_web/v1/seats/stand_menus',
    params: {
      order_location_uuid: params.orderLocationUuid,
    }
  }, false)

  static redeemUserItem = (params) => {
    return post({
      path: `firesale/admin_api/v1/user_items/${params.itemId}/redeem`
    }, false)
  }

  static updateOrderMetadata = (params) => {
    return patch({
      path: `/firesale/api/v1/user_items/${params.userItemId}`,
      body: {
        metadata: params.metadata,
      }
    })
  }
}

const propertyNames = Object.getOwnPropertyNames(Remote)
const filteredPropertyNames = propertyNames.filter(key => !['length', 'name', 'prototype'].includes(key))
const endpoints = filteredPropertyNames.reduce((accumulator, key) => {
  return {
    ...accumulator,
    [key]: key,
  }
}, {})

Remote.endpoints = endpoints

export default Remote
