import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import Remote from '../remote'

import TimeZone from '../utils/timezone'
import OrderStates from '../utils/orderStates'
import Title from '../utils/titleGenerator'

import Button from '../components/Button'
import EmailFormField from '../components/EmailFormField'
import ErrorMessage from '../components/ErrorMessage'
import Item from '../components/Item'
import NavigationBar from '../components/NavigationBar'
import OrderTotalSection from '../components/cart/OrderTotalSection'
import QRCode from '../components/QRCode'
import ReceiptCounter from '../components/ReceiptCounter'
import Section from '../components/Section'
import ServiceTypesText from '../components/ServiceTypes'

import { clearCart } from '../actions/cart'
import { clearLatestOrder, getOrder, updateOrder } from '../actions/order'
import { loadStands } from '../actions/stand'
import { updateUserLocationConfirmed, updateUserAgeVerification } from '../actions/user'

import { makeGetOrder, makeGetOrderRequestStatus } from '../selectors/order'
import { getUserLocation } from '../selectors/user'
import { makeGetMenu } from '../selectors/menu'
import { makeGetOrderLineItems } from '../selectors/order'
import { makeGetRemote } from '../selectors/remote'
import { withTranslation } from 'react-i18next'

import './OrderSummary.scss'

import Money from '../utils/money'

export class OrderSummary extends Component {
  constructor(props) {
    super(props)

    this.focusRef = React.createRef()
  }

  state = {
    isPolling: false,
    poller: null,
    counter: '',
    active: false,
    email: '',
    emailValid: false,
    showSendButton: false,
    sendButtonDisabled: false,
    submittingEmail: false,
  }

  componentDidMount() {
    const { order, orderId, loadStands, updateUserLocationConfirmed, updateUserAgeVerification } = this.props

    loadStands()
    updateUserLocationConfirmed()
    updateUserAgeVerification()

    if (order.id !== orderId) this.maybeGetOrder()
    this.handlePageRefresh()
    this.props.clearCart()
  }

  componentDidUpdate(prevProps, prevState) {
    this.togglePolling()
  }

  componentWillUnmount() {
    clearInterval(this.state.poller)
    this.props.clearLatestOrder()
  }

  allowOrderRequest = () => {
    const { isFetching, lastFetched, minimumWait } = this.props.orderRequestStatus
    return !isFetching && lastFetched + minimumWait <= moment.now()
  }

  bannerChangeHandler = () => {
    const { active } = this.state
    this.setState({ active: !active })
  }

  dismissKeyboard = () => {
    this.focusRef.current.focus()
    this.focusRef.current.blur()
  }

  // for when someone refreshes the page while a poller was runnning
  handlePageRefresh = () => {
    const { isFetching, lastFetched, shouldPoll } = this.props.orderRequestStatus
    const { getOrder, orderId, order } = this.props
    const orderComplete = OrderStates.isCompleted(get(order, 'attributes.state'))

    if (isFetching) getOrder(orderId) // refreshed while we were fetching the order
    if (!this.state.isPolling && shouldPoll) this.startPolling() // refreshed while polling

    // allow refresh after 30s to see final state, unless it's already complete
    if (lastFetched + 30000 < moment.now() && !orderComplete) {
      getOrder(orderId)
    }
  }

  itemDisplay = (item, quantity, modifiers = [], index = -1) => {
    const { quickpay } = this.props
    const hasMods = !modifiers.every(isEmpty)
    const itemPrice = get(item, 'attributes.totalAmountInCents', 0)
    let displayPrice = hasMods
      ? itemPrice / get(item, 'attributes.quantity', 1)
      : itemPrice

    let key = item.id
    if (hasMods) {
      key += `-${index}`
      modifiers.map((modifier) => displayPrice += Money.dollarsToCents(modifier.price))
    }

    return (
      <Item
        type="cart"
        key={key}
        name={get(item, 'attributes.name')}
        description={get(item, 'attributes.marketingDescription') || get(item, 'attributes.description')}
        modifiers={modifiers}
        quantity={quantity}
        price={quickpay ? null : Money.centsToDollars(displayPrice)}
        quickpay={quickpay}
      />
    )
  }

  itemDisplayHandler = (item) => {
    const modifiers = get(item, 'attributes.modifiers', [])

    if (modifiers.every(isEmpty)) {
      return this.itemDisplay(item, get(item, 'attributes.quantity', 1))
    }

    return modifiers.map((modGroup, index) => {
      return this.itemDisplay(item, 1, modGroup, index)
    })
  }

  // throttle requests if we made one recently
  maybeGetOrder = () => {
    const { getOrder, orderId } = this.props
    if (this.allowOrderRequest()) getOrder(orderId)
  }

  sendEmailReceipt = () => {
    setTimeout(() => {
      this.dismissKeyboard()
    }, 100)

    const { orderId, updateOrder } = this.props
    this.setState({ submittingEmail: true, sendButtonDisabled: true })
    updateOrder(orderId, { email: this.state.email })
  }

  sendButtonText = () => {
    const { remote: { loading, failed }, t } = this.props
    const { submittingEmail: submittingEmailState, email } = this.state

    if (loading) return t('SENDING')

    const submittingEmail = !loading && !failed && submittingEmailState
    const submittingEmailFailed = failed && submittingEmailState

    if (submittingEmailFailed) {
      setTimeout(() => {
        this.setState({ submittingEmail: false, sendButtonDisabled: false })
      }, 2000)
    }

    if (submittingEmail && !isEmpty(email)) {
      setTimeout(() => {
        this.setState({ submittingEmail: false, sendButtonDisabled: false })
      }, 2000)
    }

    if (submittingEmail) return t('EMAIL_SENT')
    if (submittingEmailFailed) return t('FAILED_TRY_AGAIN')

    return 'SEND'
  }

  renderOrderDetails = () => {
    const { order, t, orderRequestStatus: { isFetching } } = this.props
    const orderNumber = get(order, 'attributes.orderNumber')
    const vnKioskOrderNumber = get(order, 'attributes.userAttributes.vnKioskOrderNumber')

    return (
      <div>
        {this.renderQRCode()}
        <div className='order-details'>
          {this.renderOrderTime()}
          <span className='order-number'>{isFetching ? <Skeleton width='150px' /> : `Order # ${orderNumber}`}</span>
          { vnKioskOrderNumber && (
            <div>
              <br/><span className='order-number'>{isFetching ? <Skeleton width='150px' /> : `${t('KIOSK_ORDER')} # ${vnKioskOrderNumber}`}</span>
            </div>
          )}
          {this.renderInstructions()}
        </div>
      </div>
    )
  }

  renderOrderTime = () => {
    const { order, orderRequestStatus: { isFetching } } = this.props
    const orderTime = get(order, 'attributes.createdAt', '')
    const currentTimeZone = TimeZone.current()

    if (isFetching) return <span className='order-time'><Skeleton width='130px' /></span>
    if (!orderTime) return null

    return <span className='order-time'>{ moment(orderTime).tz(currentTimeZone).format('MM/DD/YY HH:mm z') }</span>
  }

  renderInstructions = () => {
    const { order, usesQrCode, t, orderRequestStatus: { isFetching } } = this.props

    switch (get(order, 'attributes.usageType')) {
      case 'pickup':
        const standName = get(order, 'attributes.standName')
        let message = ''

        if (usesQrCode) {
          message = t('SCAN_THIS_CODE')
        } else {
          message = t('PRESENT_THIS_PAGE')
        }

        return (
          <span className='order-instructions'>
            {isFetching ? <Skeleton width='100%' /> : message}
            <span className='stand-name'>{isFetching ? null : standName}</span>
          </span>
        )
      case 'delivery':
        const { userLocation } = this.props
        const userName = get(order, 'attributes.userName', null)

        return (
          <ServiceTypesText
            serviceType="delivery"
            view="cart"
            loading={isFetching}
            userLocation={userLocation}
            userName={userName}
          />
        )
      default:
    }

  }

  renderLineItems = () => {
    const { quickpay, lineItems, t, orderRequestStatus: { isFetching } } = this.props

    return (
      <Section className='line-items'>
        {!quickpay && <h2>{t('ORDER_DETAILS')}</h2>}
        {isFetching && (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
        {!isFetching && keys(lineItems).map((itemId) => {
          return this.itemDisplayHandler(lineItems[itemId])
        })}
      </Section>
    )
  }

  renderMessaging = () => {
    const { order, orderRequestStatus, quickpay } = this.props
    const { active } = this.state
    const { isFetching, shouldPoll } = orderRequestStatus
    const startTime = moment(get(order, 'attributes.paymentAuthorizedAt', '')) //will need as prop
    const orderState = get(order, 'attributes.state')
    const orderComplete = OrderStates.isCompleted(orderState)
    const error = order.error

    if (quickpay) {
      return(
        <ReceiptCounter
          active={active}
          bannerChangeHandler={this.bannerChangeHandler}
          className='quickpay-header'
          order={order}
          startTime={startTime}
        />
      )
    }

    return (
      <Section className='messaging'>
        <h1>{isFetching ? <Skeleton width='80%' /> : get(order, 'attributes.stateDisplayName')}</h1>
        <p className='secondary-header'>{isFetching ? <Skeleton count={2} /> : get(order, 'attributes.stateDescription')}</p>
        {shouldPoll && this.renderPollingMessage()}
        {!shouldPoll && !orderComplete && !error && this.renderOrderDetails()}
        {error && <ErrorMessage type="getOrder" error={error} style={{marginTop: '24px'}} />}
        {orderComplete && this.renderOrderComplete()}
      </Section>
    )
  }

  renderNavigation = () => {
    const { quickpay, t } = this.props

    if (quickpay) {
      return <NavigationBar.Close right text={t('SHOW_THIS_SCREEN_TO_RECEIVE_YOUR_ORDER')} />
    }

    return <NavigationBar.Close />
  }

  renderPollingMessage = () => {
    const { usesQrCode, t } = this.props
    return(
      <div>
        <p>{t('HANG_TIGHT')}.</p>
        {usesQrCode && <p>{t('ONCE_IT_DOES')}.</p>}
      </div>
    )
  }

  renderOrderComplete = () => {
    return(
      <div>
        <p>{this.props.t('WE_HOPE_YOU_ENJOYED_YOUR_ORDER')}</p>
      </div>
    )
  }

  renderOrderTime = () => {
    const { order, orderRequestStatus: { isFetching } } = this.props
    const orderTime = get(order, 'attributes.createdAt', '')
    const currentTimeZone = TimeZone.current()

    if (isFetching) return <span className='order-time'><Skeleton width='130px' /></span>
    if (!orderTime) return null

    return <span className='order-time'>{ moment(orderTime).tz(currentTimeZone).format('MM/DD/YY HH:mm z') }</span>
  }

  renderQRCode = () => {
    const { order, usesQrCode, orderRequestStatus: { isFetching } } = this.props
    const orderNumber = get(order, 'attributes.orderNumber')

    if (!orderNumber || !usesQrCode) return null

    return <QRCode text={orderNumber} loading={isFetching} />
  }

  renderEmailField = () => {
    const { order } = this.props
    const orderNumber = get(order, 'attributes.orderNumber')
    if (!orderNumber) return null

    const { remote: { loading, failed }, t } = this.props
    const { submittingEmail: submittingEmailState, email, sendButtonDisabled, emailValid } = this.state
    const emailInvalid = !emailValid
    const emailClass = classNames({ 'not-empty': email.length > 0 })
    const submittingEmail = !loading && !failed && submittingEmailState

    return(
      <Section className='receipt-forms'>
        <EmailFormField
          onEmailValid={(email, emailValid) => {
            const showSendButton = emailValid;

            this.setState({
              emailValid,
              email,
              showSendButton,
              sendButtonText: t('SEND'),
              sendButtonDisabled: false,
              submittingEmail: false
            })
          }}
          email={email}
          emailClass={emailClass}
          disabled={submittingEmail}
          focusRef={this.focusRef}
        />
        {(
          <Button.Brand
            disabled={sendButtonDisabled || emailInvalid}
            onClick={this.sendEmailReceipt}
          >
            {this.sendButtonText()}
          </Button.Brand>
        )}
      </Section>
    )
  }

  startPolling = () => {
    const { minimumWait } = this.props.orderRequestStatus
    this.setState({
      isPolling: true,
      poller: setInterval(this.maybeGetOrder, minimumWait),
    })
  }

  stopPolling = () => {
    clearInterval(this.state.poller)
    this.setState({ isPolling: false, poller: null })
  }

  togglePolling = () => {
    const { shouldPoll } = this.props.orderRequestStatus
    if (shouldPoll && !this.state.isPolling) this.startPolling()
    if (!shouldPoll && this.state.isPolling) this.stopPolling()
  }

  render() {
    const { order, orderRequestStatus: { isFetching }, quickpay, t } = this.props
    const orderNumber = get(order, 'attributes.orderNumber')

    return (
      <div className={classNames('order-summary', { loading: isFetching, quickpay })}>
        <Helmet>
          <title>{Title.generate(get(order, 'attributes.standName'), `${t('RECEIPT')} ${orderNumber}`)}</title>
        </Helmet>
        {this.renderNavigation()}
        {this.renderMessaging()}
        {this.renderLineItems()}
        <OrderTotalSection
          deliveryFee={get(order, 'attributes.deliveryFee', 0)}
          finalTotal={get(order, 'attributes.total', 0)}
          itemQuantityLabel={t('SUBTOTAL')}
          serviceCharges={get(order, 'attributes.serviceCharge', 0)}
          tax={get(order, 'attributes.tax', 0)}
          tip={this.props.tipAmount}
          loading={isFetching}
        />
        {this.renderEmailField()}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const orderId = get(ownProps, 'match.params.orderId', '')
  const getOrder = makeGetOrder()
  const order = getOrder(state, orderId)

  const getOrderRequestStatus = makeGetOrderRequestStatus()
  const orderRequestStatus = getOrderRequestStatus(state, orderId)

  const getOrderLineItems = makeGetOrderLineItems()
  const lineItems = getOrderLineItems(state, orderId)

  const menuId = get(order, 'attributes.standMenuUuid')
  const getMenu = makeGetMenu()
  const menu = getMenu(state, menuId)

  const tipAmount = get(order, 'attributes.tip')

  let userLocation = getUserLocation(state)
  if (isEmpty(userLocation)) userLocation = get(order, 'attributes.orderLocation', {})

  const usesQrCode = get(menu, 'usesQrCode', false)
  const quickpay = get(menu, 'serviceType', '') === 'QuickPay'

  const getRemote = makeGetRemote()
  const remote = getRemote(state, Remote.endpoints.updateOrder)

  return {
    lineItems,
    menu,
    order,
    orderId,
    orderRequestStatus,
    userLocation,
    usesQrCode,
    quickpay,
    remote,
    tipAmount: tipAmount,
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    clearCart: () => dispatch(clearCart()),
    clearLatestOrder: () => dispatch(clearLatestOrder()),
    getOrder: (id) => dispatch(getOrder(id)),
    loadStands: () => dispatch(loadStands()),
    updateOrder: (id, params) => dispatch(updateOrder(id, { user_email: params.email })),
    updateUserLocationConfirmed: () => dispatch(updateUserLocationConfirmed(false)),
    updateUserAgeVerification: () => dispatch(updateUserAgeVerification(false)),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderSummary))
