import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { get, keys, flatten, isEmpty, sortBy } from 'lodash'
import classNames from 'classnames'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'

import { itemHasVariants, variantsAreSamePrice, variantsForItem } from '../utils/menuVariantHelper'
import Analytics from "../reporting/analytics"
import Title from '../utils/titleGenerator'
import Money from '../utils/money'
import Remote from '../remote'

import NavigationBar from '../components/NavigationBar'
import Item from '../components/Item'
import Button from '../components/Button'
import Modal from '../components/Modal'
import ModifierGroup from '../components/ModifierGroup'
import QuantitySelector from  '../components/QuantitySelector'

import { addItemToCart } from '../actions/cart'
import { loadMenu } from '../actions/menu'
import { loadStands } from '../actions/stand'
import { updateUserAgeVerification } from '../actions/user'

import { makeGetStand } from '../selectors/stand'
import { makeGetMenu, makeGetServiceFeeItems } from '../selectors/menu'
import { makeGetItem } from '../selectors/item'
import { makeGetCartItem, makeGetAlcoholicItemQuantityInCart } from '../selectors/cart'
import { makeGetRemote } from '../selectors/remote'
import { getUserLocation } from '../selectors/user'
import { withTranslation } from 'react-i18next'

import './ItemModal.scss'

export class ItemModal extends Component {
  constructor(props) {
    super(props)

    this.modalRef = React.createRef()
    this.modifierGroupRefs = []
  }

  state = {
    quantity: 1,
    hasOverflow: false,
    isModalLoadedPoller: null,
  }

  componentDidMount() {
    const { item, loadMenu, loadStands, menu, menuId, seatNotProvided } = this.props

    if (isEmpty(menu)) {
      loadMenu(menuId)
      loadStands()
    }

    if (!isEmpty(item)) Analytics.generateItemViewedEvent(item)

    if (seatNotProvided) this.redirectToSeatSelection()
    this.handleHasOverflow()
  }

  componentDidUpdate(prevProps) {
    const { item, loadMenu, menuId, modifierGroups, seatNotProvided } = this.props
    const seatNotProvidedChanged = prevProps.seatNotProvided !== seatNotProvided

    if (isEmpty(prevProps.item) && !isEmpty(item)){
      loadMenu(menuId)
      Analytics.generateItemViewedEvent(item)
    }

    if (seatNotProvidedChanged && seatNotProvided) this.redirectToSeatSelection()

    if (prevProps.modifierGroups !== modifierGroups) { this.handleHasOverflow() }
  }

  pollForModal = (modalElement) => {
    // modal doesn't exist; havent started polling yet
    if (!modalElement && !this.state.isModalLoadedPoller) {
      this.setState({
        isModalLoadedPoller: setInterval(this.handleHasOverflow, 300),
      })
    }

    // modal now exists, clear poller
    if (modalElement && this.state.isModalLoadedPoller) {
      clearInterval(this.state.isModalLoadedPoller)
      this.setState({ isModalLoadedPoller: null })
    }
  }

  handleHasOverflow = () => {
    const modalElement = document.querySelector('.modal')
    let hasOverflow = false

    this.pollForModal(modalElement)

    if (modalElement !== null) hasOverflow = window.innerHeight < modalElement.offsetHeight
    if (hasOverflow) {
      clearAllBodyScrollLocks()
    }

    this.setState({ hasOverflow })
  }

  formIsValid() {
    const validations = this.modifierGroupRefs.map(node => node.isValid())
    const isValid = validations.every(valid => valid !== false)

    return isValid
  }

  formValues() {
    const formValues = this.modifierGroupRefs.map(node => node.values())
    return flatten(formValues)
  }

  redirectToSeatSelection = () => {
    const { history, location, deliveryLocationRoute, item } = this.props
    const currentUrl = location.pathname

    history.replace(deliveryLocationRoute, {
      redirect: `${currentUrl}/item/${item.id}`,
    })
  }

  closeModal = () => {
    const closeModal = get(this, 'modalRef.current.closeModal', () => {})

    closeModal()
  }

  addDeliveryFee = () => {
    const { addItemToCart, serviceFeeItems } = this.props
    const deliveryFeeID = keys(serviceFeeItems).find((itemId) => {
      return get(serviceFeeItems[itemId], 'specialType') === 'delivery_fee'
    })

    if (deliveryFeeID) { addItemToCart(deliveryFeeID, 1) }
  }

  addToCart = (orderNow = false) => {
    const { item, cartItemQuantity, addItemToCart, isInCart, isCartEmpty } = this.props
    const { quantity } = this.state

    if (!this.formIsValid()) return

    const modifiers = this.formValues()

    if (isCartEmpty) this.addDeliveryFee()

    if (item.isAlcohol) {
      const newQuantity = isInCart ? quantity + cartItemQuantity : quantity
      addItemToCart(item.uuid, newQuantity, modifiers, orderNow)
    } else {
      addItemToCart(item.uuid, quantity + cartItemQuantity, modifiers, orderNow)
    }

    this.closeModal()

    if (orderNow) this.props.history.replace('/cart')
  }

  handleAddToCart = (orderNow = false) => {
    const { loading, ageNeedsVerification, verifyUserAge } = this.props

    if (loading) return

    if (!ageNeedsVerification) {
      this.addToCart(orderNow)
    } else {
      verifyUserAge()
    }
  }

  decrementCount = () => {
    const { quantity } = this.state
    const newQuantity = quantity - 1

    if (newQuantity < 1) return

    this.setState({ quantity: newQuantity })
  }

  incrementCount = () => {
    const { quantity } = this.state
    const { maxQuantity, item } = this.props
    const newQuantity = quantity + 1
    const hasLimit = get(item, 'orderMaxAmount', null) !== null

    if (hasLimit && newQuantity > maxQuantity) return

    this.setState({ quantity: newQuantity })
  }

  renderQuantityMessage = () => {
    const { menu, item, ageNeedsVerification, t } = this.props

    // This will otherwise crash on page refresh, if the item details modal is open
    if (!menu) return null

    if (isEmpty(menu) && isEmpty(item)) return null

    if (!item.isAlcohol && item.orderMaxAmount === null) return null

    let message = `${t('ONLY_CAPS_START')} ${item.orderMaxAmount} ${t('OF_THIS_ITEM_PER_ORDER_ALLOWED')}.`

    if (item.isAlcohol) {
      message = menu.alcoholLimitMessage
    }

    return <p className={classNames('quantity-message', { confirmed: !ageNeedsVerification }, { unconfirmed: ageNeedsVerification })}>{message}</p>
  }

  renderItemDetail = () => {
    const { item, ageNeedsVerification, merchandise, loading } = this.props
    if (loading) return <Item type="detail" loading />
    if (isEmpty(item)) return null

    const isVariant = itemHasVariants(item)

    let price = Money.centsToDollars(item.defaultPriceInCents)
    if (isVariant && !variantsAreSamePrice(item)) price = null

    let displayName = get(item, 'name', '')
    if (merchandise && isVariant) displayName = get(item, 'variantGroupDisplayName', displayName)

    return (
      <Item
        type="detail"
        className={classNames({ confirmed: !ageNeedsVerification }, { unconfirmed: ageNeedsVerification })}
        name={displayName}
        description={item.marketingDescription || item.description}
        price={price}
      />
    )
  }

  renderItemModifiers = () => {
    const { hasModifiers, modifierGroups, loading } = this.props

    if (!hasModifiers) return null

    const sortedGroups = sortBy(modifierGroups, (group) => get(group, 'modifierPropertiesContainer.sortOrder', 100))

    return (
      <div className="modifiers">
        {sortedGroups.map((group, index) => {
          const modifierProperty = group.modifierPropertiesContainer || {}
          const modifierPropertyDefaults = modifierProperty.defaults
          const items = modifierProperty.items.map((item) => ({
            id: item.id,
            name: item.name,
            price: item.defaultPriceInCents > 0 ? Money.centsToDollars(item.defaultPriceInCents) : null,
          }))

          if (loading) {
            return <ModifierGroup key={index} loading />
          }

          return (
            <ModifierGroup
              ref={node => this.modifierGroupRefs[index] = node}
              key={index}
              name={group.name}
              items={items}
              min={group.min}
              max={group.max}
              defaults={modifierPropertyDefaults}
            />
          )
        })}
      </div>
    )
  }

  renderVariants = () => {
    const { item, loading, t } = this.props
    if (!itemHasVariants(item)) return null

    const variants = variantsForItem(item)
    const showPrice = !variantsAreSamePrice(item)

    const items = variants.map((variant) => ({
      id: variant.menuItemUuid,
      name: variant.name,
      price: showPrice && variant.priceInCents > 0 ? Money.centsToDollars(variant.priceInCents) : null,
    }))

    return (
      <div className="modifiers">
        {loading && <ModifierGroup loading />}
        {!loading && <ModifierGroup
          ref={node => this.modifierGroupRefs[0] = node}
          key={item.id}
          name={get(item, 'variantSelectionText', t('SELECT_ONE'))}
          items={items}
          min={1}
          max={1}
        />}
      </div>
    )
  }

  renderModifiersOrVariants = () => {
    const { merchandise } = this.props

    return merchandise ? this.renderVariants() : this.renderItemModifiers()
  }

  renderMerchItemImage() {
    const { item, merchandise, hasMerchImage } = this.props
    if (!merchandise) return null
    if (merchandise && !hasMerchImage) return null

    return (
      <div
        className='merch-item-image'
        style={{ backgroundImage: `url(${item.images.detail})` }}
      >
        <NavigationBar.Close text="" right transparent twoToned onClick={this.closeModal} />
      </div>
    )
  }

  render() {
    const { quantity, hasOverflow } = this.state
    const { alcoholWarningMessage, maxQuantity, menu, item, ageNeedsVerification, menuRoute, hasModifiers, merchandise, hasMerchImage, loading, t } = this.props

    const defaultAlcoholWarning = alcoholWarningMessage ? alcoholWarningMessage : `${t('MUST_BE_21')}.`

    const unavailable = maxQuantity === 0
    const buttonText = unavailable ? t('MAX_QUANTITY_REACHED') : t('ORDER_NOW')
    const menuName = get(menu, 'name', '')
    const itemName = get(item, 'name', '')

    return (
      <Modal
        ref={this.modalRef}
        onCloseModal={() => this.props.history.replace(menuRoute)}
        className={classNames('item-modal', { unconfirmed: ageNeedsVerification, 'has-overflow': hasOverflow, merchandise: merchandise && hasMerchImage })}
      >
        <Helmet>
          <title>{Title.generate(menuName, itemName)}</title>
        </Helmet>
        {this.renderMerchItemImage()}
        <div className={classNames('item-header', { 'has-overflow': hasOverflow })}>
          {!hasMerchImage && <NavigationBar.Close text="" onClick={this.closeModal} />}
          {this.renderItemDetail()}
        </div>
        {this.renderModifiersOrVariants()}
        <QuantitySelector
          className={classNames({ 'has-modifiers': hasModifiers }, { 'has-overflow': hasOverflow })}
          onDecrement={this.decrementCount}
          onIncrement={this.incrementCount}
          min={1}
          max={maxQuantity === 0 || maxQuantity ? maxQuantity : 100}
          value={quantity}
          loading={loading}
        />
        {ageNeedsVerification ? <p className="age-requirement-message">{defaultAlcoholWarning}</p> : null}
        <div className="item-footer">
          <div className="verify-age">
            {ageNeedsVerification && <Button
              className={classNames({ confirmed: !ageNeedsVerification })}
              onClick={this.closeModal}
            >
              Cancel
            </Button>}
            {!unavailable && !ageNeedsVerification && <Button className="add-to-cart" disabled={unavailable} onClick={() => this.handleAddToCart()}>{t('ADD_TO_CART')}</Button>}
            <Button.Brand disabled={unavailable} onClick={() => this.handleAddToCart(true)}>{!ageNeedsVerification ? buttonText : t('CONFIRM')}</Button.Brand>
          </div>
        </div>
        {this.renderQuantityMessage()}
        <p className="page-bottom-spacer" />
      </Modal>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const standId = get(ownProps, 'match.params.standId', '')
  const menuId = get(ownProps, 'match.params.menuId', '')
  const itemId = get(ownProps, 'match.params.itemId', '')

  const getRemote = makeGetRemote()
  const getStand = makeGetStand()
  const getMenu = makeGetMenu()
  const getItem = makeGetItem()
  const getCartItem = makeGetCartItem()
  const getServiceFeeItems = makeGetServiceFeeItems()

  const remote = getRemote(state, Remote.endpoints.getMenu)
  const stand = getStand(state, standId)
  const menu = getMenu(state, menuId)
  const item = getItem(state, itemId)
  const cartItem = getCartItem(state, itemId)
  const serviceFeeItems = getServiceFeeItems(state, menuId)
  const modifierGroups = get(item, 'modifierGroups', [])
  const getAlcoholicItemQuantityInCart = makeGetAlcoholicItemQuantityInCart()
  const alcoholicItemQuantity = getAlcoholicItemQuantityInCart(state)
  const ageNeedsVerification = get(item, 'isAlcohol', false) && !get(state, 'user.location.ageVerified', false)
  const hasModifiers = !isEmpty(modifierGroups)

  const isCartEmpty = keys(get(state, 'cart.item', {})).length === 0
  const productType = get(stand, 'productType', '')
  const detailImage = get(item, 'images.detail', null)
  const merchandise = productType === 'Merchandise'
  const hasMerchImage = merchandise && !isEmpty(detailImage)

  const isDelivery = get(menu, 'usageType') === 'delivery'
  const userLocationConfirmed = getUserLocation(state).confirmed
  const seatNotProvided = isDelivery && !userLocationConfirmed
  const deliveryLocationRoute = `/${standId}/menu/${menuId}/delivery-location`
  const menuRoute = `/${standId}/menu/${menuId}`

  let alcoholWarningMessage = stand.alcoholWarningMessage

  if (isEmpty(item)) return {
    item: {},
    cartItemQuantity: 0,
    loading: remote.loading,
    menuId: menuId,
  }

  let maxQuantity = item.isAlcohol ? menu.alcoholLimit : item.orderMaxAmount
  let cartItemQuantity = item.isAlcohol ? alcoholicItemQuantity : 0

  if (!isEmpty(cartItem)) {
    maxQuantity = item.orderMaxAmount && (item.orderMaxAmount - cartItem.quantity)
    cartItemQuantity = cartItem.quantity
  }

  if (item.isAlcohol) {
    maxQuantity = menu.alcoholLimit - alcoholicItemQuantity
  }

  return {
    menu,
    menuId,
    item: {
      ...item,
    },
    maxQuantity,
    cartItemQuantity,
    isCartEmpty,
    isInCart: !isEmpty(cartItem),
    ageNeedsVerification,
    seatNotProvided,
    deliveryLocationRoute,
    menuRoute,
    modifierGroups,
    hasModifiers,
    merchandise,
    hasMerchImage,
    serviceFeeItems,
    loading: remote.loading,
    alcoholWarningMessage,
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    addItemToCart(itemId, quantity, modifiers, orderNow) {
      dispatch(addItemToCart(itemId, quantity, modifiers, orderNow))
    },
    loadMenu: (menuId) => dispatch(loadMenu(menuId)),
    loadStands: () => dispatch(loadStands()),
    verifyUserAge: () => dispatch(updateUserAgeVerification(true))
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ItemModal))
