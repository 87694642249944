import { createSelector } from 'reselect'
import { getItems } from './item'
import { flatten, get, groupBy, keys } from 'lodash'

export const getCartItems = state => get(state, 'cart.item', {})

const getCartItem = (state, id) => get(state, 'cart.item', {})[id] || {}

export const makeGetCartItem = () => (
  createSelector([getCartItem], (item) => item)
)

const makeVariantCartItems = (cartItem, menuItem, variants) => {
  const combined = flatten(get(cartItem, 'modifiers', []))
  const grouped = groupBy(combined, 'menuItemUuid')
  let newCartItems = {}

  keys(grouped).map((key) => {
    const itemInCart = grouped[key][0]
    const quantity = grouped[key].length
    const variant = variants.find(v => v.menuItemUuid === itemInCart.menuItemUuid)
    const variantName = { name: menuItem.variantGroupDisplayName }
    const variantPrice = { defaultPriceInCents: variant.priceInCents }

    const attrs = {
      ...menuItem,
      ...variantName,
      ...variantPrice,
      id: itemInCart.menuItemUuid,
      isVariant: true,
      modifiers: [],
      quantity: quantity,
      variantName: variant.name,
      variantParentId: menuItem.id,
    }

    return newCartItems[key] = attrs
  })

  return newCartItems
}

const makeExperienceItem = (cartItem, menuItem) => {
  const eventItem = cartItem.variant
  const id = eventItem.menuItemUuid
  const name = { name: get(menuItem, 'name', '') }
  const price = { defaultPriceInCents: eventItem.price * 100 }

  const attrs = {
    ...menuItem,
    ...name,
    ...price,
    id: id,
    isExperience: true,
    merchantId: eventItem.merchantId,
    metadata: cartItem.metadata,
    modifiers: [],
    quantity: cartItem.quantity,
    saleId: eventItem.saleId,
  }

  return { [id]: attrs }
}

const getCartMenuItems = createSelector(
  [getCartItems, getItems],
  (cartItems, menuItems) => {
    const cartItemIds = keys(cartItems)

    const items = cartItemIds.reduce((accumulator, id) => {
      const cartItem = cartItems[id]
      const menuItem = menuItems[id]
      const variants = get(menuItem, 'variants', [])

      if (cartItem.productType === "Experience") return makeExperienceItem(cartItem, menuItem)

      let variantItems = null
      let modifiers = []

      if (variants.length > 1) {
        variantItems = makeVariantCartItems(cartItem, menuItem, variants)
      } else {
        let allItemModifiers = {}

        get(menuItem, 'modifierGroups', []).forEach((group) => {
          const container = group.modifierPropertiesContainer
          container.items.forEach((item) => {
            allItemModifiers[item.id] = item
          })
        })

        modifiers = get(cartItem, 'modifiers', []).map((modifierGroup) => {
          return modifierGroup.map(modifier => allItemModifiers[modifier.menuItemUuid])
        })
      }

      const itemsToAdd = variantItems || { [id]: { ...menuItems[id], ...cartItems[id], modifiers } }
      return {
        ...accumulator, ...itemsToAdd
      }
    }, {})

    return items
  }
)

export const makeGetCartMenuItems = () => {
  return createSelector([getCartMenuItems], (menuItems) => menuItems)
}

const getCartProperties = createSelector(
  [getCartMenuItems],
  (cartMenuItems) => {
    const cartItemIds = keys(cartMenuItems)

    return cartItemIds.reduce((accumulator, id) => {
      const item = cartMenuItems[id]

      if (get(item, 'specialType') === 'delivery_fee') {
        return { subtotal: accumulator.subtotal, quantity: accumulator.quantity }
      }

      let modifierTotal = 0
      item.modifiers.map((modGroup) => {
        return modGroup.map((mod) => modifierTotal += mod.defaultPriceInCents)
      })

      return {
        subtotal: accumulator.subtotal + modifierTotal + item.quantity * item.defaultPriceInCents,
        quantity: accumulator.quantity + item.quantity,
      }
    }, {subtotal: 0, quantity: 0})
  }
)

export const makeGetCartProperties = () => {
  return createSelector([getCartProperties], (cartMenuItems) => cartMenuItems)
}

const getAlcoholicItemQuantityInCart = createSelector(
  [getCartMenuItems],
  (items) => {
    const quantity = keys(items).reduce((accumulator, itemId) => {
      const item = items[itemId]
      const isAlcohol = item.isAlcohol && item.quantity > 0

      return isAlcohol ? accumulator + item.quantity : accumulator
    }, 0)

    return quantity
  }
)

export const makeGetAlcoholicItemQuantityInCart = () => {
  return createSelector([getAlcoholicItemQuantityInCart], (quantity) => quantity)
}

const getDeliveryFee = createSelector(
  [getCartMenuItems],
  (items) => {
    const deliveryFeeId = keys(items).find((itemId) => {
      return get(items[itemId], 'specialType') === 'delivery_fee'
    })

    return items[deliveryFeeId]
  }
)

export const makeGetDeliveryFee = () => {
  return createSelector([getDeliveryFee], (item) => item)
}
