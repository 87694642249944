export const STANDS_REQUESTED = 'STANDS_REQUESTED'
export const STANDS_SUCCEEDED = 'STANDS_SUCCEEDED'
export const STANDS_FAILED = 'STANDS_FAILED'

export const loadStands = () => ({
  type: STANDS_REQUESTED,
})

export const loadStandsSucceeded = (payload) => ({
  type: STANDS_SUCCEEDED,
  payload,
})

export const loadStandsFailed = (payload) => ({
  type: STANDS_FAILED,
  payload,
})
