import { STANDS_SUCCEEDED } from '../actions/stand'
import humps from 'humps'

const defaultState = { rvcs: [] }

function reducer(state = defaultState, action) {
  switch (action.type) {
    case STANDS_SUCCEEDED:
      return {
        rvcs: humps.camelizeKeys(action.payload.stands)
      }

    default:
      return state
  }
}

export default reducer
