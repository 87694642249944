import humps from  'humps'
import { orderTotalMapper } from './orderTotal'

export function orderMapper({ items, menuId, paymentMethodNonce, payments, name, orderLocation, orderLocationUuid, tipAmountInCents }) {
  const orderObject = {
    ...orderTotalMapper(items, menuId),
    firstName: name,
    seat: orderLocation,
    orderLocationUuid,
    paymentMethodNonce,
    payments,
    tip_amount_in_cents: tipAmountInCents,
    webOrder: true,
  }

  return humps.decamelizeKeys(orderObject)
}

export default orderMapper
