import { STANDS_SUCCEEDED } from '../actions/stand'
import { get } from 'lodash'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case STANDS_SUCCEEDED:
      const locale = get(action, 'payload.stands[0].locale', 'en-US')

      return {
        locale
      }

    default:
      return state
  }
}

export default reducer
