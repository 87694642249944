import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Route, Link } from 'react-router-dom'
import { get, keys, isEmpty, isEqual, head, values } from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import { createExperienceOrder, createOrder, clearOrderError } from '../actions/order'
import { clearOrderTotal, experienceOrderTotal, orderTotal } from '../actions/orderTotal'
import { clearCart, clearOrderNow, removeItemFromCart, updateGratuity } from '../actions/cart'

import { makeGetStand } from '../selectors/stand'
import { getUserLocation } from '../selectors/user'
import { makeGetRemote } from '../selectors/remote'
import { makeGetMenu } from '../selectors/menu'
import { makeGetEvent } from '../selectors/event'

import AlertDialog, { defaultAlertDialogState } from '../components/AlertDialog'
import Button from '../components/Button'
import DeliveryModal from './DeliveryModal'
import ErrorMessage from '../components/ErrorMessage'
import Item from '../components/Item'
import NavigationBar from '../components/NavigationBar'
import OrderForm from '../components/cart/OrderForm'
import OrderTotalSection from '../components/cart/OrderTotalSection'
import Section from '../components/Section'
import ServiceTypesText from '../components/ServiceTypes'
import PrivacyPolicy from '../components/PrivacyPolicy'
import OrderGratuitySection from '../components/cart/OrderGratuitySection'

import { makeGetCartMenuItems, makeGetCartProperties, makeGetDeliveryFee } from '../selectors/cart'

import Money from '../utils/money'
import Remote from '../remote'

import './Cart.scss'
import Title from '../utils/titleGenerator'

export class Cart extends Component {
  state = {
    alertDialog: defaultAlertDialogState,
  }

  componentDidMount() {
    const { clearOrderError, clearOrderTotal, isExperience } = this.props

    clearOrderError()
    clearOrderTotal()

    if (!isEmpty(this.props.stand) || isExperience) {
      const { getExperienceOrderTotal, getOrderTotal } = this.props
      isExperience ? getExperienceOrderTotal() : getOrderTotal()
    }
  }

  componentWillUnmount() {
    this.props.clearOrderTotal()
    this.props.clearOrderError()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.stand, this.props.stand)) {
      const { isExperience, getExperienceOrderTotal, getOrderTotal } = this.props
      isExperience ? getExperienceOrderTotal() : getOrderTotal()
    }

    if (!isEqual(prevProps.userLocation, this.props.userLocation)) {
      this.props.getOrderTotal()
    }
  }

  clearCartConfirm = () => {
    const alert = {
      onConfirm: this.clearCart,
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('REMOVE_ALL_ITEMS'),
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  showCustomTipDialog = () => {
    const alert = {
      onConfirm: (inputValue) => {

        if (inputValue === "" || isNaN(inputValue)){
          return
        }

        const floatValue = parseFloat(inputValue)

        if (floatValue <= 0) {
          return
        }

        const newGratuity = Money.dollarsToCents(inputValue)

        if (newGratuity) {
          this.props.updateGratuity(Money.dollarsToCents(inputValue), 'CUSTOM')
        }
        this.onAlertDialogDismiss()

      },
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('OK'),
      textMain: this.props.t('CUSTOM_TIP_AMOUNT'),
      show: true,
      input: {
        format: (val) => {
          return Money.floatToDollars(val)
        },
        validation: (val) => {
          return /^([\d]*)([.]?)([\d]{0,2})?$/.test(val)
        },
        onFocus: (val) => {
          if (val === '0.00') {
            return ''
          }
          return val
        },
        type: 'text',
        label: this.props.t('AMOUNT'),
        startAdornment: this.props.t('CURRENCY_SYMBOL'),
      },
    }

    this.setState({ alertDialog: alert })
  }

  clearCart = () => {
    this.props.clearCart()
    this.props.updateGratuity(0)
    this.clearTotalAndGoBack()
  }

  clearTotalAndGoBack = () => {
    this.props.history.goBack()
    this.props.clearOrderTotal()
    this.props.clearOrderNow()
  }

  itemDisplay = (item, quantity, modifiers, index = -1) => {
    const { itemQuantity } = this.props
    let key = item.id
    let price = item.defaultPriceInCents * quantity
    if (index >= 0) {
      key += `-${index}`
      modifiers.map((mod) => price += mod.defaultPriceInCents)
    }

    let variantModifiers = null
    if (item.isVariant) variantModifiers = [{ name: item.variantName }]

    return (
      <Item 
        type="cart"
        key={key}
        name={item.name}
        description={item.marketingDescription || item.description}
        modifiers={variantModifiers || modifiers}
        quantity={quantity}
        price={Money.centsToDollars(price)}
        onRemove={itemQuantity !== 1 ? () => this.removeItemConfirm(item, index) : null}
      />
    )
  }

  itemDisplayHandler = (item) => {
    if (item.modifiers.every(isEmpty)) {
      return this.itemDisplay(item, item.quantity)
    }

    return item.modifiers.map((modifiers, index) => {
      return this.itemDisplay(item, 1, modifiers, index)
    })
  }

  onBraintreeSuccess = (response, userAttributes) => {
    const { isExperience } = this.props
    const nonce = response.nonce
    const paymentType = response.type === 'CreditCard' ? 'credit_card' : 'apple_pay'

    isExperience
      ? this.placeExperienceOrder(nonce, paymentType, userAttributes)
      : this.placeOrder(nonce, paymentType, userAttributes)
  }

  onBraintreeFailure = (error) => {
    console.log(error)
  }

  onAlertDialogDismiss = () => {
    this.setState({ alertDialog: defaultAlertDialogState })
  }

  orderLocationHash = () => {
    const { userLocation } = this.props
    const { section, row, seat } = userLocation

    if (userLocation.confirmed) {
      return { section: section, row: row, seat: seat }
    }
  }

  placeExperienceOrder = (nonce, paymentType, userAttributes) => {
    const { createExperienceOrder } = this.props

    const orderParams = {
      email: userAttributes.email,
      paymentMethodNonce: nonce,
      payments: [{ paymentType, amountInCents: this.totalInCents() }],
    }

    createExperienceOrder(orderParams)
  }

  placeOrder = (nonce, paymentType, userAttributes) => {
    const { createOrder, tipAmount } = this.props
    const orderParams = {
      email: userAttributes.email,
      name: userAttributes.name,
      orderLocation: this.orderLocationHash(),
      orderLocationUuid: this.props.userLocation.orderLocationUuid,
      paymentMethodNonce: nonce,
      tipAmountInCents: tipAmount,
      payments: [{ paymentType, amountInCents: this.totalInCents() }],
    }

    createOrder(orderParams)
  }

  removeItem = (item, modifiersIndex) => {
    const itemId = item.isVariant ? item.variantParentId : item.id
    const variantId = item.isVariant ? item.id : null

    this.props.clearOrderTotal()
    this.props.removeItemFromCart(itemId, modifiersIndex, variantId)
    this.onAlertDialogDismiss()
  }

  removeItemConfirm = (item, modifiersIndex) => {
    const name = get(item, 'name')
    let removeText = item.quantity > 1 ? `${item.quantity} ${name}s` : `1 ${name}`
    if (modifiersIndex >= 0) removeText = `1 ${name}`

    const alert = {
      onConfirm: () => this.removeItem(item, modifiersIndex),
      onDismiss: this.onAlertDialogDismiss,
      textConfirm: this.props.t('REMOVE'),
      textMain: `${this.props.t('THIS_WILL_REMOVE')} ${removeText} ${this.props.t('FROM_YOUR_CART')}.`,
      show: true,
    }

    this.setState({ alertDialog: alert })
  }

  serviceTypeMessage = () => {
    const { userLocation, isExperience } = this.props
    if (isExperience) return

    if (userLocation.confirmed) {
      const route = {
        pathname: '/cart/delivery-location',
        state: { redirect: '/cart' },
      }

      return (
        <>
          <ServiceTypesText serviceType="delivery" view="cart" userLocation={userLocation}/>
          <Link to={route} replace className="change-seat">Change Seat</Link>
        </>
      )
    } else {
      const { stand } = this.props
      const standName = get(stand, 'name', '')

      return <ServiceTypesText serviceType="pickup" view="cart" standName={standName}/>
    }
  }

  deliveryFee = () => get(this.props.deliveryFee, 'defaultPriceInCents', 0)
  serviceCharges = () => get(this.props.orderTotal, 'latest.attributes.serviceChargeInCents', 0)
  taxInCents = () => get(this.props.orderTotal, 'latest.attributes.taxAmountInCents', 0)
  tipSuggestions = () => get(this.props.orderTotal, 'latest.attributes.tipSuggestions', [])

  totalInCents = () => {
    const { isExperience, orderTotal, tipAmount } = this.props
    const totalKey = `latest.${isExperience ? '' : 'attributes.'}totalAmountInCents`

    var total = get(orderTotal, totalKey, 0)

    if (tipAmount) {
      total += tipAmount
    }

    return total
  }

  renderEventDetails = () => {
    const { event } = this.props
    let eventDate = this.props.t('EVENT_DATE')
    let eventDetails = ''

    if (!isEmpty(event)) {
      eventDate = moment(event.date).format('MMMM Do, YYYY')
      eventDetails = `${event.time}, ${event.name}`
    }

    return (
      <Section className="event-date">
        <span className="label">{this.props.t('EVENT')}</span>
        <div className={classNames('event-picker', { active: get(event, 'date', false ) })}>
          <span className="date">{eventDate}</span>
          <span className="event-details">{eventDetails}</span>
        </div>
      </Section>
    )
  }

  render() {
    const { isExperience, isFree, menuId, standId, successfulOrderId, t } = this.props

    if (successfulOrderId) {
      const path = isExperience ? 'experience_orders' : 'orders'
      return <Redirect to={`/${path}/${successfulOrderId}`}/>
    }

    const { alertDialog } = this.state
    const { braintreeToken, deliveryFee, items, itemQuantity, order, orderTotal, loading, menu, showApplePay } = this.props
    const itemQuantityLabel = `${itemQuantity} ${itemQuantity > 1 ? t('ITEMS') : t('ITEM')}`

    const displayOrderGratuity = () => {
      if (isExperience || isFree) return null

      if (this.tipSuggestions().length > 0) {
        return (
          <OrderGratuitySection
            suggestions={this.tipSuggestions()}
            tipAmount={this.props.tipAmount}
            tipButtonId={this.props.tipButtonId}
            updateGratuity={this.props.updateGratuity}
            showCustomTipDialog={this.showCustomTipDialog}
          />
        )
      }
    }

    return (
      <div className={classNames('cart', { loading })}>
        <Helmet>
          <title>{Title.generate(t('CART'))}</title>
        </Helmet>
        <NavigationBar.Back text={isExperience ? this.props.t('EXPERIENCE') : t('MENU')} onClick={this.clearTotalAndGoBack} />
        <Section className="items">
          {loading && isEmpty(items) && (
            <>
              <Item type="cart" loading />
              <Item type="cart" loading />
              <Item type="cart" loading />
            </>
          )}
          {keys(items).map((itemId) => {
            if (itemId === get(deliveryFee, 'id')) return null

            return this.itemDisplayHandler(items[itemId])
          })}
        </Section>

        {displayOrderGratuity()}

        {/* <Section name="people also bought" className="item-recommendation">
          <Item type="recommended" item={recommendedItem} />
        </Section> */}
        <OrderTotalSection
          deliveryFee={this.deliveryFee()}
          error={orderTotal.error}
          finalTotal={this.totalInCents()}
          itemQuantityLabel={itemQuantityLabel}
          loading={loading}
          serviceCharges={this.serviceCharges()}
          tax={this.taxInCents()}
          tip={this.props.tipAmount}
          isExperience={isExperience}
        />
        <Section className="actions">
          <Button onClick={this.clearCartConfirm}>{t('CLEAR_CART')}</Button>
          <Button onClick={this.clearTotalAndGoBack}>{t('ADD_ITEMS')}</Button>
        </Section>
        <Section className="service-type-text">{this.serviceTypeMessage()}</Section>
        {isExperience && this.renderEventDetails()}
        {!order.error && (
          <OrderForm
            braintreeToken={braintreeToken}
            isExperience={isExperience}
            isFree={isFree}
            menu={menu}
            onSuccess={this.onBraintreeSuccess}
            orderTotalInCents={this.totalInCents()}
            showApplePay={showApplePay}
            usageType={get(menu, 'usageType')}
          />
        )}
        {order.error && (
          <Section>
            <ErrorMessage type="createOrder" error={order.error} />
          </Section>
        )}
        {alertDialog.show && <AlertDialog {...this.state.alertDialog} />}
        <Route path="/cart/delivery-location" render={(routeProps) => <DeliveryModal {...routeProps} menuId={menuId} standId={standId} onCloseModal={() => this.props.history.replace('/cart')} />} />
        <PrivacyPolicy />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const menuId = get(state, 'cart.menuId', '')
  const standId = get(state, 'cart.standId', '')

  const getMenu = makeGetMenu()
  const getRemote = makeGetRemote()
  const getStand = makeGetStand()
  const getCartMenuItems = makeGetCartMenuItems()
  const getCartProperties = makeGetCartProperties()
  const getDeliveryFee = makeGetDeliveryFee()
  const getEvent = makeGetEvent()

  const menu = getMenu(state, menuId)
  const remote = getRemote(state, Remote.endpoints.orderTotal)
  const stand = getStand(state, standId)

  const cartItems = getCartMenuItems(state)
  const event = getEvent(state, get(head(values(state.cart.item)), 'variant.eventUuid', ''))
  const cartProperties = getCartProperties(state)
  const deliveryFee = getDeliveryFee(state)
  const successfulOrder = get(state, 'order.latest', {})
  const userLocation = getUserLocation(state)
  const braintreeToken = get(state, 'meta.braintreeToken', '')
  const showApplePay = get(state, 'meta.showApplePay', false)

  // It's possible to get here without having standId updated in cart.
  // If so, let's check the first cart item instead
  const isExperience = (
    get(stand, 'productType') === 'Experience' ||
    ( head(values(cartItems)) || {} )['isExperience']
  )
  const successfulOrderId = isExperience ? successfulOrder.id : keys(successfulOrder)[0]
  const isFree = isExperience
    ? get(state, 'orderTotal.latest.totalAmountInCents') === 0
    : get(state, 'orderTotal.latest.attributes.totalAmountInCents') === 0


  return {
    braintreeToken,
    deliveryFee,
    event,
    failed: remote.failed,
    isExperience,
    isFree,
    itemQuantity: cartProperties.quantity,
    items: cartItems,
    loading: remote.loading,
    menu,
    menuId: menuId,
    order: state.order,
    orderTotal: state.orderTotal,
    recommendedItem: null,
    showApplePay,
    stand,
    standId,
    successfulOrderId: successfulOrderId,
    userLocation: userLocation,
    tipAmount: state.cart.tipAmount,
    tipButtonId: state.cart.tipButtonId,
  }
}

function mapDispatchToProps(dispatch, newProps) {
  return {
    clearCart: () => dispatch(clearCart()),
    clearOrderNow: () => dispatch(clearOrderNow()),
    clearOrderError: () => dispatch(clearOrderError()),
    clearOrderTotal: () => dispatch(clearOrderTotal()),
    createOrder: (params) => dispatch(createOrder(params)),
    createExperienceOrder: (params) => dispatch(createExperienceOrder(params)),
    getOrderTotal: () => dispatch(orderTotal()),
    getExperienceOrderTotal: () => dispatch(experienceOrderTotal()),
    removeItemFromCart: (itemId, modifiersIndex, variantId) => {
      dispatch(removeItemFromCart(itemId, modifiersIndex, variantId))
    },
    updateGratuity: (tipAmount, tipButtonId) => dispatch(updateGratuity(tipAmount, tipButtonId)),
  }
}

// export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Cart))
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Cart))