import React from 'react'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'

import './OrderSummaryItem.scss'

const OrderSummaryItem = ({ label, value, loading, ...props }) => {
  const className = classNames('order-summary-item', props.className)

  return (
    <div {...props} className={className}>
      <span className="label">{label}</span>
      <span className="value">{loading ? <Skeleton width='50px' /> : value}</span>
    </div>
  )
}

export default OrderSummaryItem
