import { all, call, put, takeLatest } from 'redux-saga/effects'

import Remote from '../../remote'
import { setLoading, setFailed, setSucceeded } from '../../actions/remote'
import { getExperienceOrder } from '../../actions/order'

import {
  REDEEM_USER_ITEM_REQUESTED,
  REDEEM_USER_ITEM_SUCCEEDED,
  redeemUserItemSucceeded,
  redeemUserItemFailed,
} from '../../actions/experiences/userItem'

export function* redeemUserItem(params) {
  const endpoint = Remote.endpoints.redeemUserItem
  yield put(setLoading(endpoint))

  try {
    const result = yield call(Remote.redeemUserItem, params)

    yield all([
      put(redeemUserItemSucceeded(result, params.itemId, params.orderId)),
      put(setSucceeded(endpoint)),
    ])
  } catch (err) {
    yield all([
      put(redeemUserItemFailed(err, params.itemId)),
      put(setFailed(endpoint, err)),
    ])
  }
}

export function* watchRedeemUserItem() {
  yield takeLatest(REDEEM_USER_ITEM_REQUESTED, redeemUserItem)
}

export function* redeemUserItemAndGetOrder(params) {
  yield put(getExperienceOrder(params.orderId))
}

export function* watchRedeemUserItemAndGetOrder() {
  yield takeLatest(REDEEM_USER_ITEM_SUCCEEDED, redeemUserItemAndGetOrder)
}
