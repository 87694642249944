import humps from  'humps'
import { keys, values } from 'lodash'

export function experienceOrderTotalMapper(items, email = '') {
  let lineItems = []
  let orderParams = { userEmail: email, merchantId: '' }

  values(items).forEach((item) => {
    if (keys(item.metadata) && keys(item.metadata).length > 1) {
      keys(item.metadata).forEach((key) => {
        let lineItem = { saleId: item.saleId }
        lineItem.itemId = item.id
        lineItem.quantity = 1
        lineItem.metadata = item.metadata[key]

        lineItems.push(lineItem)
      })
    } else {
      let lineItem = { saleId: item.saleId }
      lineItem.itemId = item.id
      lineItem.quantity = item.quantity
      if (item.metadata) lineItem.metadata = item.metadata[1]

      lineItems.push(lineItem)
    }

    // Can't do more than one merchant id per order
    orderParams.merchantId = item.merchantId
  })

  const orderTotalObject = {
    lineItems: lineItems,
    order: orderParams,
    productType: "Experience",
  }

  return humps.decamelizeKeys(orderTotalObject)
}

export default experienceOrderTotalMapper
