export const EXPERIENCE_ORDER_TOTAL_REQUESTED = 'EXPERIENCE_ORDER_TOTAL_REQUESTED'
export const EXPERIENCE_ORDER_TOTAL_SUCCEEDED = 'EXPERIENCE_ORDER_TOTAL_SUCCEEDED'
export const EXPERIENCE_ORDER_TOTAL_FAILED = 'EXPERIENCE_ORDER_TOTAL_FAILED'
export const ORDER_TOTAL_REQUESTED = 'ORDER_TOTAL_REQUESTED'
export const ORDER_TOTAL_SUCCEEDED = 'ORDER_TOTAL_SUCCEEDED'
export const ORDER_TOTAL_FAILED = 'ORDER_TOTAL_FAILED'
export const CLEAR_ORDER_TOTAL = 'CLEAR_ORDER_TOTAL'

export const clearOrderTotal = () => ({
  type: CLEAR_ORDER_TOTAL,
})

export const experienceOrderTotal = () => ({
  type: EXPERIENCE_ORDER_TOTAL_REQUESTED,
})

export const experienceOrderTotalSucceeded = (payload) => ({
  type: EXPERIENCE_ORDER_TOTAL_SUCCEEDED,
  payload,
})

export const experienceOrderTotalFailed = (payload) => ({
  type: EXPERIENCE_ORDER_TOTAL_FAILED,
  payload,
})

export const orderTotal = () => ({
  type: ORDER_TOTAL_REQUESTED,
})

export const orderTotalSucceeded = (payload) => ({
  type: ORDER_TOTAL_SUCCEEDED,
  orderTotal: {...payload.orderTotal},
})

export const orderTotalFailed = (payload) => ({
  type: ORDER_TOTAL_FAILED,
  payload,
})
