import { ADD_ITEM_TO_CART, SET_ACTIVE_MENU, CLEAR_CART, REMOVE_ITEM_FROM_CART, CLEAR_ORDER_NOW, UPDATE_METADATA, UPDATE_TIP_AMOUNT } from '../actions/cart'
import compact from 'lodash/compact'
import fill from 'lodash/fill'
import filter from 'lodash/filter'
import get from 'lodash/get'
import omit from 'lodash/omit'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'

const defaultState = {}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      const { quantity, modifiers } = action.payload
      const currentItem = get(state, `item.${action.payload.id}`, {})
      const currentQuantity = get(currentItem, 'quantity', 0)
      const currentModifiers = get(currentItem, 'modifiers', [])
      const quantityToAdd = quantity - currentQuantity
      const mergedModifiers =  fill(Array(quantityToAdd), isEmpty(modifiers) ? [] : modifiers)
      const metadata = get(currentItem, 'metadata', {})

      return {
        ...state,
        orderNow: action.payload.orderNow,
        item: {
          ...state.item,
          [action.payload.id]: {
            ...action.payload,
            modifiers: [...currentModifiers, ...mergedModifiers],
            metadata: { ...metadata },
          },
        },
      }

    case SET_ACTIVE_MENU:
      return {
        ...state,
        ...action.payload,
      }

    case CLEAR_CART:
      return {
        menuId: state.menuId,
        standId: state.standId,
        orderNow: false,
      }

    case CLEAR_ORDER_NOW:
      return {
        ...state,
        orderNow: false,
      }

    case REMOVE_ITEM_FROM_CART:
      const itemKey = `item.${action.payload.id}`
      const modifiersKey = itemKey + '.modifiers'
      const { modifiersIndex, variantId } = action.payload
      let newState = {...state}

      // no modifiers, not a variant
      if (modifiersIndex < 0 && !variantId) newState = omit(newState, [itemKey])

      // remove modifiers that match the variant ID
      if (variantId) {
        let modifiers = get(newState, modifiersKey, [])
        modifiers = filter(modifiers, function(mod) {
          return mod.find(m => m.menuItemUuid !== variantId)
        })

        const newItem = {
          ...newState[itemKey],
          id: action.payload.id,
          modifiers: modifiers,
          quantity: modifiers.length,
        }

        // omitting and re-setting to ensure the cart selector is called afterwards
        newState = omit(newState, [itemKey])
        newState = set(newState, itemKey, newItem)
      }

      // removing a single nested modifier
      if (modifiersIndex >= 0) {
        newState = omit(newState, [modifiersKey + `[${modifiersIndex}]`])
        const modifiers = compact(get(newState, modifiersKey))
        newState = set(newState, modifiersKey, modifiers)

        const quantityKey = itemKey + '.quantity'
        const newQuantity = get(newState, quantityKey) - 1
        set(newState, quantityKey, newQuantity)
      }

      /**
       * When removing an item with modifiers and their is only 1 of that item
       * in the cart, it shows as quantity `0` in the state. This is a fail safe
       * in the event an items quantity is ever `0` to just remove it.
       */
      if (get(newState, `${itemKey}.quantity`, 1) < 1) {
        newState = omit(newState, [itemKey])
      }

      return {
        ...newState,
      }

    case UPDATE_METADATA:
      const itemId = action.payload.itemId
      const newMetadata = action.payload.metadata

      let newerState = { ...state }
      let item = newerState.item[itemId]

      item.metadata = { ...item.metadata, ...newMetadata }

      // omitting and re-setting to ensure the cart selector is refreshed afterwards
      newerState = omit(newerState, `item.${itemId}`)
      newerState = set(newerState, `item.${itemId}`, item)

      return {
        ...newerState,
      }

    case UPDATE_TIP_AMOUNT:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default reducer
